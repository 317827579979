import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import CTA from './CTA';
import dot from '../../assets/icons/dot.svg';
import locationPin from '../../assets/icons/location.svg';
import FormatedDate from '../../utils/FormatedDate';
import DictionaryText from './DictionaryText';

const SideRelateNews = ({ fields }) => {
  const results = fields?.RelatedNews;
  return(
    <>
      <div className="container mb-20">
          <RichText field={fields?.Title} className="leading-7 text-black-300 font-semibold text-3xl mb-4" />
          {results?.map((item, index) => (
            <div key={index} className="mt-2">
              <RichText field={item?.fields?.Headline} className="mt-2 text-sm leading-7 text-black-300 font-semibold" />
              <div className="flex gap-2 items-center mt-2">
                {item?.fields?.Date?.value &&
                  <div className="flex gap-2 items-center">
                    <img src={dot} className=" rounded-full w-[8.98px] h-[8.83px]" />
                    <div className="text-base text-black-300 font-light">
                      <FormatedDate date={item?.fields?.Date?.value} />
                    </div>
                  </div>
                }
                { item?.fields?.Date?.value && item?.fields?.Location?.value && <span className="mdd:inline hidden">|</span>}
                {item?.fields?.Location?.value &&
                  <div className="flex gap-2 items-center">
                    <img src={locationPin} className=" rounded-full h-[18px]" />
                    <div className="text-base text-black-300 font-medium">
                        {item?.fields?.Location?.value}
                    </div>
                  </div>
                }
              </div>
              <div className="flex mt-2 uppercase gap-6">
                {item?.url && (
                  <div className="text-black-300 duration-200 hover:text-red-500 text-sm underline cursor-pointer font-light">
                    <a href={item?.url}> <DictionaryText dictionaryKey="read-more" /> </a>
                  </div>
                )}
                </div>
            </div>
          ))}
        </div>
    </>
  )
};

export default SideRelateNews;
