import React, { useRef, useEffect, useState } from 'react';
import { RichText, Link, Image } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useMediaQuery } from 'react-responsive'
import Slider from 'react-slick';

import arrowLeftDark from '../../../../assets/icons/arrow-left-dark.svg';
import arrowRightDark from '../../../../assets/icons/arrow-right-dark.svg';
import arrowLeftDisable from '../../../../assets/icons/arrow-left-disable.svg';
import arrowRightDisable from '../../../../assets/icons/arrow-right-disable.svg';
import ImageCard from '../../../Common/ImageCard';
import CTA from '../../../Common/CTA';

const FourColumnCarousel = ({ fields, params, sitecoreContext }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const sliderRef = useRef(null);
  const [activeItem, setActiveItem] = useState(0);
  const [page, setPage] = useState(1);

  const results = fields?.SelectedItems;
  const handleSlideChange = (current, next) => {
    setActiveItem(next);
  };
  const handlingChangeWhenSwipe4 = (dir) => {
      if(dir === 'right'){
        toPrev()
      }else{
        toNext()
      }
  };
  const handlingChangeWhenSwipe3 = (dir) => {
    if(dir === 'right'){
      toPrevThree()
    }else{
      toNextThree()
    }
}

  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    slidesToShow: 4,
    speed: 250,
    slidesToScroll: 4,
    arrows: false,
    rtl: sitecoreContext?.language === 'ar' ? true : false,
    beforeChange: handleSlideChange,
    onSwipe: handlingChangeWhenSwipe4,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          dots: false,
          onSwipe: handlingChangeWhenSwipe4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
          onSwipe: handlingChangeWhenSwipe3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          touchMove: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 4,
          initialSlide: 1,
          dots: false,
          onSwipe: handlingChangeWhenSwipe4,
        },
      },
    ],
  };

  useEffect(() => {
    if (sitecoreContext?.language === 'ar') {
      sliderRef && sliderRef.current.slickGoTo(results?.length);
      setPage(1);
    }
  }, [])

  const toNext = () => {
    if (sitecoreContext?.language === 'ar') {
      if (page === 1) return;
      sliderRef && sliderRef.current.slickNext();
      setPage(page - 1);
    } else {
      if (page < Math.ceil(results?.length / 4)) {
        sliderRef && sliderRef.current.slickNext();
        setPage(page + 1);
      }
    }

  };

  const toPrev = () => {
    if (sitecoreContext?.language === 'ar') {
      if (page < Math.ceil(results?.length / 4)) {
        sliderRef && sliderRef.current.slickPrev();
        setPage(page + 1);
      }
    } else {
      if (page === 1) return;
      sliderRef && sliderRef.current.slickPrev();
      setPage(page - 1);
    }
  };

  const toNextThree = () => {
    if (sitecoreContext?.language === 'ar') {
      if (page === 1) return;
      sliderRef && sliderRef.current.slickNext();
      setPage(page - 1);
    } else {
      if (page < Math.ceil(results?.length / 3)) {
        sliderRef && sliderRef.current.slickNext();
        setPage(page + 1);
      }
    }


  };

  const toPrevThree = () => {
    if (sitecoreContext?.language === 'ar') {
      if (page < Math.ceil(results?.length / 3)) {
        sliderRef && sliderRef.current.slickPrev();
        setPage(page + 1);
      }
    } else {
      if (page === 1) return;
      sliderRef && sliderRef.current.slickPrev();
      setPage(page - 1);
    }

  };

  return (
    <div className="md:py-24 py-16 overflow-x-clip bg-white">
      <div className="container">
        <div className="relative">
          <div className="w-full flex justify-between gap-4 md:flex-row flex-col">
            <div className="flex items-center gap-8 flex-wrap">
              <RichText field={fields?.Title} className="text-black-300 text-3xl font-semibold" />
            </div>
            <div className="flex items-center gap-14 shrink-0">
              <div className="md:flex hidden items-center gap-4">
                <div className="flex items-center gap-4">
                  <img src={sitecoreContext?.language === 'ar' ? (page === Math.ceil(fields?.SelectedItems?.length / 4) ? arrowLeftDisable : arrowLeftDark ) : (page === 1 ? arrowLeftDisable: arrowLeftDark)} alt="arrow-left" onClick={toPrev} className="xl:block md:hidden hidden cursor-pointer rtl:order-1" />
                  <img src={sitecoreContext?.language === 'ar' ? ((page === 1 ? arrowRightDisable : arrowRightDark)) : (page < Math.ceil(fields?.SelectedItems?.length / 4) ? arrowRightDark : arrowRightDisable )} alt="arrow-right" onClick={toNext} className="xl:block md:hidden hidden cursor-pointer" />
                  <img src={sitecoreContext?.language === 'ar' ? (page === Math.ceil(fields?.SelectedItems?.length / 3) ? arrowLeftDisable : arrowLeftDark ) : (page === 1 ? arrowLeftDisable: arrowLeftDark)} alt="arrow-left" onClick={toPrevThree} className="hidden xl:hidden md:block cursor-pointer rtl:order-1" />
                  <img src={sitecoreContext?.language === 'ar' ? ((page === 1 ? arrowRightDisable : arrowRightDark)) : (page < Math.ceil(fields?.SelectedItems?.length / 3) ? arrowRightDark : arrowRightDisable )} alt="arrow-right" onClick={toNextThree} className="hidden xl:hidden md:block cursor-pointer" />
                </div>
                <div>
                  <div className="xl:block md:hidden hidden text-lg font-bold text-black-300">
                    {sitecoreContext?.language === 'ar' ? (Math.ceil(fields?.SelectedItems?.length / 4) + '/' + page) : (page + '/' + Math.ceil(fields?.SelectedItems?.length / 4))}
                  </div>
                  <div className="hidden xl:hidden md:block text-lg font-bold text-black-300">
                    {sitecoreContext?.language === 'ar' ? (Math.ceil(fields?.SelectedItems?.length / 3) + '/' + page) : (page + '/' + Math.ceil(fields?.SelectedItems?.length / 3))}
                  </div>
                </div>
              </div>
              <div className="w-fit">{fields?.Link?.value?.href && <CTA field={fields?.Link?.value} />}</div>
            </div>
          </div>
          <div className="mt-10 -ml-6">
            <Slider {...settings} ref={sliderRef}>
              {fields?.SelectedItems?.map((item, index) => (
                <a href={item.url} className="pl-6 mb-6 group" key={index} dir={sitecoreContext?.language === 'ar' ? 'rtl' : 'ltr'}>
                  <div className="flex md:flex-col flex-row gap-4">
                    <div className="md:h-[300px] shrink-0 overflow-clip h-[150px] md:w-auto w-[150px]">
                      <Image
                        field={isMobile ? item?.fields?.MobileImage?.value?.src ? item?.fields?.MobileImage : item?.fields?.Image : item?.fields?.Image}
                        className="group-hover:scale-105 transition-all duration-500 shrink-0 w-full h-full object-cover object-center"
                      />
                    </div>
                    <div>
                      <RichText field={item?.fields?.Title} className="text-black-300 leading-[28px] mb-[14px] text-lg font-bold" />
                      <Link field={item?.fields?.Link} className="text-black-300 underline group-hover:text-red-500 duration-300 text-base py-2 leading-[24px]" />
                    </div>
                  </div>
                </a>
              ))}
            </Slider>
          </div>
          <div className="md:hidden flex mt-5 items-center gap-4">
            <div className="flex items-center gap-4">
              <img src={sitecoreContext?.language === 'ar' ? (page === Math.ceil(fields?.SelectedItems?.length / 4) ? arrowLeftDisable : arrowLeftDark ) : (page === 1 ? arrowLeftDisable: arrowLeftDark)} alt="arrow-left" onClick={toPrev} className="cursor-pointer rtl:order-1" />
              <img src={sitecoreContext?.language === 'ar' ? ((page === 1 ? arrowRightDisable : arrowRightDark)) : (page < Math.ceil(fields?.SelectedItems?.length / 4) ? arrowRightDark : arrowRightDisable)} alt="arrow-right" onClick={toNext} className="cursor-pointer" />
            </div>
            <div className="text-lg font-bold text-black-300">{sitecoreContext?.language === 'ar' ? (Math.ceil(fields?.SelectedItems?.length / 4) + '/' + page) : (activeItem + 1 + '/' + fields?.SelectedItems?.length / 4)} </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(FourColumnCarousel);
