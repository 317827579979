import React from 'react';
import { RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import { useMediaQuery } from 'react-responsive'

const MainContentOnly = ({ fields }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div className="container">
      <div className="flex items-start md:flex-row flex-col gap-16 md:py-16 py-14 ">
        {/* <div className="md:w-1/3 w-full md:block hidden"></div> */}
        <div className="md:w-2/3 w-full leading-[32px]">
          {fields?.Image?.value?.src && <Image field={isMobile ? fields?.MobileImage?.value?.src ? fields?.MobileImage : fields?.Image : fields?.Image} className="w-full mb-6" />}
          {fields?.ImageBadge?.value?.src && <Image field={fields?.ImageBadge} className="mb-14" />}
          <RichText field={fields?.Header} className="text-black-300 text-lg font-bold" />
          <RichText field={fields?.Description} className="text-black-300 text-lg mt-6" />
        </div>
      </div>
    </div>
  );
};

export default MainContentOnly;
