import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { RichText, Link, EditFrame } from '@sitecore-jss/sitecore-jss-react';
import GetEditFrame from '../../../Common/EditFrame';
import CTA from '../../../Common/CTA';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';


const FullBleedBannerV1 = ({ fields, bannerFields }) => {
  const data = fields?.SelectedItems[0];

  const isMobile = useMediaQuery({ maxWidth: 768 });

  let datasource = data?.id;

  let editFrameButtons = GetEditFrame(bannerFields, datasource);

  const [hasTitle, setHasTitle] = useState(false);
  useEffect(() => {
    document.querySelector('h1') ? setHasTitle(true) : setHasTitle(false)
  }, []);

  return (
    <EditFrame {...editFrameButtons}>
      <div
        className="relative bg-no-repeat bg-cover banner"
        style={{
          backgroundImage: `url(${isMobile ? data?.fields?.MobileImage?.value?.src || data?.fields?.Image?.value?.src : data?.fields?.Image?.value?.src})`,
          backgroundPosition: data?.fields?.CSSBackgroundImagePosition?.fields?.Value?.value || 'center',
        }}
      >
        <div className="absolute bg-gradient-to-b from-white from-0% to-100% to-[rgba(255, 255, 255, 0)] w-full h-full top-0 left-0 z-0"></div>
        <div className="container">
          <div className={'md:py-32 py-20 text-black-300 flex md:flex-row flex-col items-start md:gap-16 gap-6 h-fit m-auto w-full relative z-20'}>
            <div className="md:w-1/4 w-full"></div>
            <div className="md:w-3/4 w-full text-base">
              {props.sitecoreContext.pageEditing? (
                <RichText field={data?.fields?.Title} className="text-[22px] font-semibold leading-[40px]" />
              ) : (
                hasTitle ? (data?.fields?.Title?.value &&
                  <h2 className="text-[22px] font-semibold leading-[40px]">{data?.fields?.Title?.value}</h2>
                ) : (data?.fields?.Title?.value &&
                  <h1 className="text-[22px] font-semibold leading-[40px]">{data?.fields?.Title?.value}</h1>
                )
              )}
              <RichText field={data?.fields?.Description} className="text-[22px] md:leading-9 leading-[30px] mt-6" />
              <div className="flex mt-8 md:items-center items-start md:flex-row flex-col md:gap-8 gap-4">
                {data?.fields?.Link?.value?.href && (
                  <div className="w-fit">
                    <CTA field={data?.fields?.Link?.value} />
                  </div>
                )}
                {data?.fields?.CTALink?.value?.href && (
                  <div className="w-fit">
                    <CTA field={data?.fields?.CTALink?.value} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </EditFrame>
  );
};

export default withSitecoreContext(FullBleedBannerV1);
