import React from 'react';
import { RichText, Image , withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import dot from '../../../../assets/icons/dot.svg';
import locationPin from '../../../../assets/icons/location.svg';
import FormatedDate from '../../../../utils/FormatedDate';

const WithImages = ({ fields , sitecoreContext }) => {
  const results = fields?.RelatedNews;
  return(
    <>
        <div className="container mb-20">
          {/* <RichText field={fields?.Title} className="uppercase mt-2 leading-7 text-black-300 font-bold text-base" /> */}
          <h3 className="uppercase mt-2 leading-7 text-black-300 font-bold text-base"> {fields?.title?.value} </h3>
          {sitecoreContext.pageEditing ? (
            <RichText field={fields?.Title} className="uppercase mt-2 leading-7 text-black-300 font-bold text-base" />
          ) : (
            <h3 className="uppercase mt-2 leading-7 text-black-300 font-bold text-base"> {fields?.title?.value} </h3>
          )}

          <div className="grid md:grid-cols-4 grid-cols-1 gap-6 mt-6">
            {results?.map((item, index) => (
              <div key={index} >
                <Image field={item?.fields?.Image?.src}/>
                <div className="flex gap-2 items-center mt-2">
                  <img src={dot} className=" rounded-full w-[8.98px] h-[8.83px]" />
                  <div className="text-base text-black-300 font-light" dir="ltr">
                    <FormatedDate date={item?.fields?.Date?.value} />
                  </div>
                  <span>|</span>
                  <img src={locationPin} className=" rounded-full w-[18px] h-[18px]" />
                  <div className="text-base text-black-300 font-medium" dir="ltr">
                      {item?.fields?.Location?.value}
                  </div>
                </div>
                <RichText field={item?.fields?.Headline} className="mt-2 text-sm leading-7 text-black-300 font-semibold" />
              </div>
            ))}
          </div>
        </div>
    </>
  )
};

export default withSitecoreContext()(WithImages);
