import React from 'react';

const HeaderLink = (props) => {
  const { field, active, variant = 'primary' } = props;

  return (
    <a href={field?.href} className="group w-fit relative">
      {variant === 'primary' ? (
        <>
          <div className="text-black-300 group-hover:text-red-500 duration-300 group-active:text-red-500 text-xl font-bold pb-[18px] leading-none">
            {field?.text}
          </div>
          <div
            className={`absolute w-full h-[6px] rounded-r-md overflow-clip bg-red-500 rounded-l-lg group-hover:bg-red-500 bottom-0 group-active:bottom-[10px] duration-200 link-underline ${
              active ? 'bg-red-500 bottom-[10px]' : 'bottom-0'
            }`}
          >
            <div className="relative w-full h-full">
              <div
                className={`${
                  active ? '-translate-x-full' : 'translate-x-0'
                } w-full  h-full bg-black-300 group-hover:-translate-x-full group-active:-translate-x-full duration-300`}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="text-white group-hover:text-red-500 duration-300 group-active:text-red-500 text-xl font-bold pb-[18px] leading-none">
            {field?.text}
          </div>
          <div
            className={`absolute w-full h-[6px] rounded-r-md overflow-clip rounded-l-lg group-hover:bg-red-500 bottom-0 group-active:bottom-[10px] duration-300 link-underline ${
              active ? 'bg-red-500 bottom-[10px]' : 'bottom-0 bg-white'
            }`}
          >
            <div className="relative w-full h-full">
              <div
                className={`${
                  active ? '-translate-x-full' : 'translate-x-0'
                } w-full  h-full bg-white group-hover:-translate-x-full group-active:-translate-x-full duration-300`}
              />
            </div>
          </div>
        </>
      )}
    </a>
  );
};

export default HeaderLink;
