import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { RichText, Image, Link } from '@sitecore-jss/sitecore-jss-react';

import LinkButton from '../../../Common/LinkButton';

const Primary = ({ fields, params }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const dark = params?.BackgroundColor !== 'white';

  return (
    <div className={`bg-${params?.BackgroundColor}`}>
      <div className="container">
        <div className="lg:py-28 py-16 flex lg:flex-row flex-col lg:items-start items-center gap-14">
          <Image
            field={isMobile ? (fields.MobileImage?.value?.src ? fields.MobileImage : fields.Image) : fields.Image}
            className="lg:max-w-[30%] lg:w-[30%] md:max-w-md  w-full object-cover object-center aspect-square"
          />
          <div className={`grow ${dark ? 'text-white' : 'text-black-300'} text-start lg:max-w-[70%] lg:w-[70%]`}>
            <div className="relative">
              <Image field={fields?.Icon} className="mb-4" />
              <RichText field={fields?.Title} className="text-[22px] leading-[40px]" />
              <div className="flex justify-end my-4">
                <Image field={fields?.CloseQuoteIcon} className="flex justify-end" />
              </div>
            </div>
            <RichText field={fields?.Name} className="text-base font-bold leading-[28px] mt-6" />
            <RichText field={fields?.AdditionalText} className="text-base font-medium leading-[28px]" />
            <div className="w-fit mt-6 flex">{fields?.Link?.value?.href && <LinkButton variant={params?.BackgroundColor === 'white' ? 'primary' : 'secondary'} field={fields?.Link?.value} />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Primary;
