import React, { useState, useRef } from 'react';
import { RichText, Image } from '@sitecore-jss/sitecore-jss-react';

import video_play from '../../../../assets/icons/video_play.svg';
import video_pause from '../../../../assets/icons/video_pause.svg';
import ModalYouTube from '../../../Common/ModalYouTube';

const Video = ({ fields, params }) => {
  const videoRef = useRef(null);
  const [play, setPlay] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handlePlay = () => {
    setPlay(true);
    if (!fields?.IsYoutubeVideo?.value) {
      videoRef?.current?.play();
    } else {
      setShowModal(true);
    }
  };
  const handlePause = () => {
    setPlay(false);
    videoRef?.current?.pause();
  };
  const handleEnd = () => {
    setPlay(false);
  };

  return (
    <div className={`bg-${params?.BackgroundColor} w-full md:py-28 py-16`}>
      <div className="container">
        <div className="relative">
          <div className="relative w-full h-auto object-cover object-center group">
            <div className={`${fields?.image ? 'z-50' : '-z-1'} top-0 left-0 h-full w-full`}>
              {!play && (
                <>
                  <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent from-[47.4%] to-[91.53%] to-[#A99C96]"></div>
                  <div
                    className="w-full  md:h-[660px] h-[564px] object-cover object-center bg-no-repeat bg-center bg-cover"
                    style={{ backgroundImage: `url(${fields?.Image?.value?.src})` }}
                  ></div>
                </>
              )}
              <div className={`w-full h-auto  ${!play && !fields?.IsYoutubeVideo?.value ? 'hidden' : ''} `}>
                {!fields?.IsYoutubeVideo?.value ? (
                  <video controls onEnded={handleEnd} className="w-full h-full  bg-gray-300" ref={videoRef}>
                    <source src={fields?.Video?.value?.href} type="video/mp4"></source>
                  </video>
                ) : (
                  <ModalYouTube
                    open={showModal}
                    style="light"
                    onCloseModal={() => {
                      setShowModal(false);
                      setPlay(false);
                    }}
                  >
                    <iframe
                      ref={videoRef}
                      src={fields?.Video?.value?.href}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="w-full h-full "
                    ></iframe>
                  </ModalYouTube>
                )}
              </div>
              {!play && (
                <div className="text-white absolute md:px-14 px-6 md:w-2/3 w-full md:bottom-12 bottom-7">
                  <RichText field={fields?.Title} className="text-[22px] leading-[53px] font-semibold" />
                  <RichText field={fields?.Description} className="text-xl mt-2 leading-9 font-medium" />
                </div>
              )}
            </div>
            {!play && (
              <img
                src={video_play}
                onClick={handlePlay}
                alt="play"
                className={`absolute w-fit h-fit left-0 right-0 bottom-0 top-0 m-auto ${fields?.image ? 'z-50' : '-z-1'} cursor-pointer`}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video;
