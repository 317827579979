import React from 'react';
import { RichText, Image } from '@sitecore-jss/sitecore-jss-react';

const Secondary = ({ fields, params }) => {
  const bg = params?.BackgroundColor;

  return (
    <div id={params?.ComponentID} className={`bg-${bg} md:py-[120px] py-24`}>
      <div className="container text-left rtl:text-right">
        <div className="md:px-20 px-0">
          <Image field={fields?.Icon} className="mb-6" />
          <RichText field={fields?.Title} className={`${bg === 'white' ? 'text-black-300' : 'text-white'} leading-9 text-xl font-semibold`} />
          <div className="flex justify-end my-4">
            <Image field={fields?.CloseQuoteIcon} className="" />
          </div>
          <RichText field={fields?.AdditionalText} className={`${bg === 'white' ? 'text-black-300' : 'text-white'} text-base leading-[28px] font-bold mt-6`} />
          <RichText field={fields?.Name} className={`${bg === 'white' ? 'text-black-300' : 'text-white'} text-base leading-[28px] font-bold mt-6`} />
        </div>
      </div>
    </div>
  );
};

export default Secondary;
