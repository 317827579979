import React from 'react';
import { withTranslation } from 'react-i18next';

const DictionaryText = (props) => {
  const TranslatedText = (props) => {
    const { t, dictionaryKey } = props;
    return <>{t(dictionaryKey)}</>;
  };
  const Rendered = withTranslation()(TranslatedText.bind(props));
  return <Rendered {...props} />;
};

export default DictionaryText;
