import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

const Statistics = ({ fields, params }) => {
  return (
    <div className="bg-sans-300">
      <div className="container">
        <div className="md:py-20 py-16 overflow-x-clip md:flex justify-between gap-8">
          {fields.SelectedItems.map((item, index) => (
            <div className="py-5 md:w-1/4 w-1/2" key={index}>
              <div className="flex gap-2 items-center">
                <div className="bg-sans-100 rounded-full w-5 h-5"></div>
                <div className="text-6xl text-white">
                  <RichText field={item?.fields?.Title} />
                </div>
              </div>
              <RichText field={item?.fields?.Summary} className="text-white text-2xl mt-3" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Statistics;
