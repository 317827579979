import React from 'react';
import { RichText, Link, EditFrame } from '@sitecore-jss/sitecore-jss-react';
import { useMediaQuery } from 'react-responsive';

import GetEditFrame from '../../../Common/EditFrame';
import CTA from '../../../Common/CTA';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const FullBleedBannerV1 = ({ fields, bannerFields }) => {
  const data = fields?.SelectedItems[0];
  const isMobile = useMediaQuery({ maxWidth: 768 });

  let datasource = data?.id;

  let editFrameButtons = GetEditFrame(bannerFields, datasource);
  const [hasTitle, setHasTitle] = useState(false);
  useEffect(() => {
    document.querySelector('h1') ? setHasTitle(true) : setHasTitle(false);
  }, []);

  return (
    <EditFrame {...editFrameButtons}>
      <div className="container banner">
        <div className="flex md:py-24 py-16 bg-white min-h-[800px]">
          <div
            className="w-full p-6 relative rounded-2xl bg-no-repeat bg-center bg-cover"
            style={{
              backgroundImage: `url(${isMobile ? data?.fields?.MobileImage?.value?.src || data?.fields?.Image?.value?.src : data?.fields?.Image?.value?.src})`,
              backgroundPosition: data?.fields?.CSSBackgroundImagePosition?.fields?.Value?.value || 'center',
            }}
          >
            <div
              className="h-full blur-3xl flex items-center justify-center flex-col md:w-[670px] w-full mx-auto"
              style={{
                background: 'radial-gradient(50% 50.00% at 50% 50.00%, #FFF 0%, rgba(255, 255, 255, 0.72) 100%)',
              }}
            ></div>
            <div className="absolute max-w-[560px] text-center text-black-300 w-fit h-fit left-0 right-0 top-0 bottom-0 m-auto">
              {props.sitecoreContext.pageEditing ? (
                <RichText field={data?.fields?.Title} className="md:text-2xl text-xl" />
              ) : hasTitle ? (
                data?.fields?.Title?.value && <h2 className="md:text-2xl text-xl">{data?.fields?.Title?.value}</h2>
              ) : (
                data?.fields?.Title?.value && <h1 className="md:text-2xl text-xl">{data?.fields?.Title?.value}</h1>
              )}
              <RichText className="md:text-4xl text-3xl mt-6" field={data?.fields?.Description} />
              <RichText className="text-lg mt-6" field={data?.fields?.Summary} />
              <div className="w-fit mx-auto mt-6">
                <CTA field={data?.fields?.Link?.value} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </EditFrame>
  );
};

export default withSitecoreContext(FullBleedBannerV1);
