import React from 'react';
import { RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import CTA from '../../../Common/CTA';
import VideoCardCarousel from '../../../Common/VideoCardCarousel';

const MediaCarousel = ({ fields, params }) => {
  const dark = params?.BackgroundColor === 'sans-500';

  return (
    fields?.SelectedItems?.length > 0 &&
    (<div className={`md:py-24 py-16 overflow-x-clip ${`bg-${params?.BackgroundColor}`}`}>
      <VideoCardCarousel NoOverlappingText={fields?.NoOverlappingText?.value} data={fields?.SelectedItems} link={fields?.Link} theme={dark ? 'dark' : 'light'}>
        <div className="md:flex items-center gap-8 flex-wrap">
          <RichText field={fields?.Title} className={`${dark ? 'text-white' : 'text-black-300'} text-[26px] font-semibold`} />
          <div className="lg:hidden block w-fit md:mt-0 mt-4">
            {fields?.Link?.value?.href && <CTA field={fields?.Link?.value} />}
          </div>
        </div>
      </VideoCardCarousel>
    </div>)
  );
};

export default MediaCarousel;
