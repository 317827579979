import React from 'react';

const Button = (props) => {
  const { children } = props;

  return (
    <div className="w-full h-fit main-button clip-button">
      <button className="px-7 py-3.5 rounded-l rounded-r-md leading-none bg-red-500 hover:bg-red-600 duration-300 font-bold active:bg-gray-400 active:text-red-500 text-white">
        {children}
      </button>
    </div>
  );
};

export default Button;
