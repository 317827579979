import React from 'react';

const ArrowRightIcon = (props) => {
  const { className, onClick, disabled, color } = props;

  return (
    <svg width="13" height="20" onClick={disabled ? null : onClick} className={className} viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Layer_1" clipPath="url(#clip0_10325_7618)">
        <path className="duration-200" fill={disabled ? '#DDD7D3' : color} id="Vector" d="M9.76906 7.17002C8.66906 6.11002 5.95906 3.46002 3.30906 1.02002L3.05906 0.790016L2.82906 0.630016C2.45906 0.400016 2.33906 0.490016 1.98906 0.740016C1.45906 1.13002 1.52906 1.04002 1.17906 1.60002C1.03906 1.82002 0.959057 2.09002 1.01906 2.35002C1.07906 2.62002 1.13906 2.70002 1.31906 2.89002C1.51906 3.08002 3.74906 5.25002 7.63906 9.46002C7.63906 9.46002 9.58906 11.67 7.46906 12.96C3.92906 15.11 1.92906 15.69 0.389057 16.5C0.129057 16.64 0.0190574 16.82 0.0190574 16.9C-0.0109426 17.08 -0.0409426 17.44 0.0590574 17.74C0.319057 18.5 0.869057 18.85 1.38906 19.45C1.71906 19.84 1.82906 20.01 2.26906 19.76C4.50906 18.52 9.41906 15.99 10.2491 15.51C13.8391 13.47 12.0691 9.40002 9.75906 7.16002L9.76906 7.17002Z" />
      </g>
      <defs>
        <clipPath id="clip0_10325_7618">
          <rect width="12.31" height="19.39" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
};

export default ArrowRightIcon;
