import React from 'react';

import { useEffect } from 'react';

const ModalYouTube = (props) => {
  const { open, children, onCloseModal } = props;

  useEffect(() => {
    const $body = document.querySelector('body');
    if (open) {
      $body.style.overflow = 'hidden';
    } else {
      $body.style.overflow = 'auto';
    }
  }, [open]);

  return (
    <>
      {open && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-clip h-screen fixed inset-0 z-50 outline-none focus:outline-none">
            <div className={'relative mx-4 md:mx-auto w-[100vw]'}>
              {/* content */}
              <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <button
                  className="absolute right-4 top-2 z-40 p-1 ml-auto bg-transparent border-0 text-grey-900 float-right text-3xl leading-none font-normal outline-none focus:outline-none"
                  onClick={() => onCloseModal()}
                >
                  <span className="text-grey-900 h-6 w-6 text-2xl focus:outline-none">×</span>
                </button>
                <div className="relative p-8 mx-auto md:w-[90vw] w-[100vw] h-[100vh]">{children}</div>
              </div>
            </div>
          </div>
          <div className=" opacity-60 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
};

export default ModalYouTube;
