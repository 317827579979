import React from 'react';
import { RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import { useMediaQuery } from 'react-responsive'
import dot from '../../../../assets/icons/dot-brand.svg';

const BioDetails = ({ fields }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div className="container">
      <div className="flex items-start md:flex-row flex-col gap-16 md:pt-40 md:pb-24 pt-28 pb-16 ">
        <div>
          <RichText field={fields?.Header} className="text-black-300 text-6xl mt-14 font-light" />
          {/* <h1 className="text-black-300 text-6xl mt-14 font-light"> {fields?.Header?.value} </h1> */}
          <div className="flex gap-2 items-center mt-3 mb-12">
            <img src={dot} className="w-3" />
            <RichText field={fields?.Title} className="text-2xl font-medium text-[#8F8883]" />
          </div>
          {fields?.Image?.value?.src && <Image field={isMobile ? fields?.MobileImage?.value?.src ? fields?.MobileImage : fields?.Image : fields?.Image} className="w-[914px] max-x-full" />}
          {fields?.ImageBadge?.value?.src && <Image field={fields?.ImageBadge} className="mt-12" />}
          <RichText field={fields?.Description} className="text-black-300 text-lg mt-12 xl:w-[80%] w-full" />
        </div>
      </div>
    </div>
  );
};

export default BioDetails;
