import React, { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ArrowLeftIcon from '../../../Common/icons/ArrowLeftIcon';
import ArrowRightIcon from '../../../Common/icons/ArrowRightIcon';
import CTA from '../../../Common/CTA';
import ImageCard from '../../../Common/ImageCard';

const InitiativesAndPatronagesWidget = ({ fields, params, sitecoreContext }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const theme = params?.BackgroundColor === 'sans-500' ? 'dark' : 'light';
  const results = fields?.data?.GQLResults?.results?.items;

  const swiperRef = useRef(null);

  const [isEnd, setIsEnd] = useState(false);
  const [isBeginning, setIsBeginning] = useState(true);

  const [pagination, setPagination] = useState();

  const BREAK_POINTS = {
    // when window width is >= 320px
    300: {
      slidesPerView: 1.2,
      slidesPerGroup: 1,
    },
    // when window width is >= 768px
    768: {
      slidesPerView: 2.5,
      slidesPerGroup: 2,
    },
  };

  const toNext = () => {
    swiperRef.current.swiper.slideNext();
  };

  const toPrev = () => {
    swiperRef.current.swiper.slidePrev();
  };

  const onChangeSlider = (swiper) => {
    setPagination(swiper?.pagination?.el?.outerText); // pagination "[currentNumber/totalNumber]"
    setIsEnd(swiperRef.current.swiper.isEnd);
    setIsBeginning(swiperRef.current.swiper.isBeginning);
  };

  return (
    <div className={`${theme === 'dark' ? 'bg-sans-500' : 'bg-gray-300'}`}>
      <div className="relative md:py-24 py-16 overflow-x-clip">
        <div className="container w-full flex justify-between md:flex-row flex-col gap-6">
          <RichText field={fields?.data?.Component?.title} className="uppercase text-black-300 text-[26px] font-semibold" />
          <div className="flex items-center w-fit gap-14">
            <div className="mmd:flex hidden items-center gap-4">
              <div className="flex items-center gap-4">
                <ArrowLeftIcon
                  disabled={isBeginning}
                  onClick={toPrev}
                  className="rtl:rotate-180 cursor-pointer"
                  color={theme === 'light' ? '#010101' : 'white'}
                />
                <ArrowRightIcon
                  disabled={isEnd}
                  onClick={toNext}
                  className="rtl:rotate-180 cursor-pointer"
                  color={theme === 'light' ? '#010101' : 'white'}
                />
              </div>
              <div className="text-lg font-bold text-black-300">{pagination}</div>
            </div>
            {fields?.data?.Component?.link?.href && (
              <div className="w-fit">
                <CTA field={fields?.data?.Component?.link}></CTA>
              </div>
            )}
          </div>
        </div>
        <div className="mt-10 leftContainer">
          <Swiper
            ref={swiperRef}
            dir={sitecoreContext?.language === 'ar' ? 'rtl' : 'ltr'}
            spaceBetween={0}
            allowTouchMove={true}
            touchMove={true}
            pagination={{
              type: 'fraction'
            }}
            breakpoints={BREAK_POINTS}
            modules={[Navigation, Pagination]}
            onInit={(swiper) => {
              setPagination(swiper?.pagination?.el?.outerText)
            }}
            onSlideChange={onChangeSlider}
          >
            {results?.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="slide-item px-5" key={index} dir={sitecoreContext?.language === 'ar' ? 'rtl' : 'ltr'}>
                  <ImageCard image={isMobile ? item.item.mobileFeaturedImage?.value?.src ? item.item.mobileFeaturedImage : item.item.featuredImage : item.item.featuredImage} title={item.item.headline} description={item.item.teaser} link={item.item.url} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="container mmd:hidden flex mt-5 items-center gap-4 md:px-0 px-5">
          <div className="flex items-center gap-4">
            <ArrowLeftIcon
              disabled={isBeginning}
              onClick={toPrev}
              className="rtl:rotate-180 cursor-pointer"
              color={theme === 'light' ? '#010101' : 'white'}
            />
            <ArrowRightIcon
              disabled={isEnd}
              onClick={toNext}
              className="rtl:rotate-180 cursor-pointer"
              color={theme === 'light' ? '#010101' : 'white'}
            />
          </div>
          <div className="text-lg font-bold text-black-300">{pagination}</div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(InitiativesAndPatronagesWidget);
