import React, { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import { RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import Slider from 'react-slick';

import arrowLeft from '../../../../assets/icons/arrow-left-light-svg.svg';
import arrowRight from '../../../../assets/icons/arrow-right-light-svg.svg';

const FullBleedCarousel = ({ fields, params }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const settings = {
    fade: true,
    autoplay: fields?.AutoRotate?.value,
    touchMove: false,
    autoplaySpeed: 7000,
    speed: 700,
    pauseOnHover: false,
    cssEase: 'linear',
    arrows: false,
  };

  const sliderRef = useRef(null);

  const [activeItem, setActiveItem] = useState(0);

  const handleSlideChange = (current, next) => {
    setActiveItem(next);
  };

  const toNext = () => {
    sliderRef.current.slickNext();
  };

  const toPrev = () => {
    sliderRef.current.slickPrev();
  };

  const handleObject = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  return (
    <div className="relative w-full">
      <Slider {...settings} animationHandler="fade" ref={sliderRef} beforeChange={handleSlideChange}>
        {fields?.SelectedItems.map((item, index) => (
          <div key={index} className="main_block">
            <div
              className="relative h-[800px] bg-cover bg-no-repeat bg-center"
              style={{
                backgroundImage: `url(${isMobile ? (item?.fields?.MobileImage?.value?.src || item?.fields?.Image?.value?.src) : item?.fields?.Image?.value?.src})`,
              }}
            >
              <div className="absolute z-20 w-full h-full bg-[#01010166]"></div>
              <div className="container h-full">
                <div className="relative md:pb-14 pb-9 md:pt-[189px] pt-[106px] overflow-hidden h-full flex flex-col justify-between z-50 text-white">
                  <div className="max-w-[950px] w-full text-start">
                    <RichText field={item?.fields?.Title} className="md:text-[22px] text-xl font-medium" />
                    <RichText field={item?.fields?.Summary} className="md:text-[22px] text-xl font-medium" />
                    {/* <div className="flex items-center gap-4 mt-6">
                      <img src={arrowLeft} className="cursor-pointer" onClick={toPrev} />
                      <img src={arrowRight} className="cursor-pointer" onClick={toNext} />
                    </div> */}
                  </div>
                  <div className="overflow-auto scrollbar-hide">
                    <div className="flex justify-between">
                      <div>
                      </div>
                      <div className="flex items-center gap-4 mb-6">
                        <img src={arrowLeft} className="cursor-pointer" onClick={toPrev} />
                        <img src={arrowRight} className="cursor-pointer" onClick={toNext} />
                      </div>
                    </div>
                    <div className="flex border-t border-white min-w-[700px]">
                      {fields?.SelectedItems.map((item_1, index_1) => (
                        <div
                          className={`${
                            activeItem === index_1 ? 'border-t-4 border-white' : 'border-t-4 border-transparent'
                          } w-1/3 py-9 md:pr-16 pr-6 -mt-0.5 transition-all duration-300 cursor-pointer`}
                          key={index_1}
                          onClick={() => handleObject(index_1)}
                        >
                          <RichText field={item_1?.fields?.Title} className="md:text-lg text-base font-bold" />
                          <RichText field={item_1?.fields?.Summary} className="text-base" />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      {/* {fields?.SelectedItems.length > 1 && (
        <div className="absolute flex md:right-[108px] right-6 text-lg items-center gap-6 z-50 bottom-10 md:w-[288px] w-[200px]">
          <div className="text-white">{activeItem + 1}</div>
          <div className=" border-b-2 border-white h-0 grow"></div>
          <div className="text-white">{fields.SelectedItems.length}</div>
        </div>
      )} */}
    </div>
  );
};

export default FullBleedCarousel;
