import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ArrowLeftIcon from './icons/ArrowLeftIcon';
import ArrowRightIcon from './icons/ArrowRightIcon';

const CenteredCarousel = (props) => {
  const { data, defaultIndex, sitecoreContext, onClick } = props;
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [activeItem, setActiveItem] = useState();
  const [pagination, setPagination] = useState('');

  useEffect(() => {
    setActiveItem(defaultIndex)
  }, [defaultIndex])

  const swiperRef = useRef(null);
  const videoRef = useRef(null);

  const onPrev = () => {
    swiperRef.current.swiper.slidePrev();
  };

  const onNext = () => {
    swiperRef.current.swiper.slideNext();
  };

  const handleSlideChange = (swiper) => {
    setPagination(swiper?.pagination?.el?.outerText);
    setActiveItem(swiper?.realIndex);
  }

  return (
    <div className="w-full flex flex-col my-auto">
      <div className="grow overflow-clip xxl:min-h-[700px] md:min-h-[500px] min-h-[370px] carousel-modal" dir="ltr">
        <Swiper
          slidesPerView={1}
          initialSlide={defaultIndex}
          ref={swiperRef}
          dir={sitecoreContext?.language === 'ar' ? 'rtl' : 'ltr'}
          spaceBetween={24}
          loop={true}
          touchMove={true}
          modules={[Pagination]}
          className="mySwiper2"
          centeredSlides={true}
          pagination={{
            type: 'fraction'
          }}
          onInit={(swiper) => {
            setPagination(swiper?.pagination?.el?.outerText)
          }}
          onSlideChange={handleSlideChange}
        >
          {data?.map((item, index) => (
            <SwiperSlide key={index} className="xxl:min-h-[700px] md:min-h-[500px] min-h-[370px] flex">
              <div className="w-full h-full my-auto" onClick={onClick}>
                <div className="w-full h-full flex flex-col relative overflow-clip">
                  {/* {!item?.fields?.Video?.value?.href && <div style={{ backgroundImage: `url("${item?.fields?.Image?.value.src}")` }} className="item grow w-full h-full bg-cover bg-no-repeat bg-center xxl:min-h-[700px] md:min-h-[500px] min-h-[370px]" />} */}
                  {!item?.fields?.Video?.value?.href && <img src={isMobile ? item?.fields?.MobileImage?.value.src ? item?.fields?.MobileImage : item?.fields?.Image?.value.src : item?.fields?.Image?.value.src} className="item grow w-full h-full bg-cover bg-no-repeat bg-center" />}
                  {item?.fields?.Video?.value?.href && (
                    <>
                      {item?.fields?.IsYoutubeVideo?.value ?
                        <iframe
                          width="100%"
                          height="100%"
                          src={item?.fields?.Video?.value?.href}
                          frameBorder="0"
                          allowFullScreen
                          className="aspect-video"
                          onClick={(e) => e.preventDefault()}
                          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          title="Embedded youtube"
                        />
                        :
                        <video ref={videoRef} controls className={`w-full h-full xxl:max-h-[700px] md:max-h-[500px] max-h-[370px] bg-gray-300 ${item?.fields?.Video?.value?.href && item?.fields?.IsYoutubeVideo?.value ? 'hidden' : 'block'}`}>
                          <source src={item?.fields?.Video?.value?.href} type="video/mp4"></source>
                        </video>
                      }
                    </>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="modal-description mx-5" dir={sitecoreContext?.language === 'ar' ? 'rtl' : 'ltr'}>
        <div className="">
          <RichText field={data[activeItem]?.fields?.Title} className="mt-6 text-start text-white text-xl " />
          <RichText field={data[activeItem]?.fields?.Description} className="text-start text-white text-base my-4 max-h-[84px] overflow-auto scrollbar-hide" />
        </div>
        <div className="w-full mx-auto mt-5 flex items-center justify-between">
          <div className="text-white text-base font-bold select-none">
            {pagination}
          </div>
          <div className="flex text-white items-center gap-4">
            <ArrowLeftIcon
              onClick={onPrev}
              className="rtl:rotate-180 cursor-pointer"
              color="#ffffff"
            />
            <ArrowRightIcon
              onClick={onNext}
              className="rtl:rotate-180 cursor-pointer"
              color="#ffffff"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(CenteredCarousel);
