import React, { useState, useRef } from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { useMediaQuery } from 'react-responsive'

import arrowLeftDark from '../../../../assets/icons/arrow-left-dark.svg';
import arrowRightDark from '../../../../assets/icons/arrow-right-dark.svg';
import video_play from '../../../../assets/icons/video_play.svg';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs, Pagination, EffectFade } from 'swiper';
import ModalYouTube from '../../../Common/ModalYouTube';
import CarouselModal from '../../../Common/CarouselModal';
import CenteredCarousel from '../../../Common/CenteredCarousel';

const Default = ({ fields }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const sliderRef = useRef(null);
  const videoRef = useRef(null);
  const [activeItem, setActiveItem] = useState(0);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const totalSlides = fields?.SelectedItems?.length;
  const data = fields?.SelectedItems?.length > 0 ? fields?.SelectedItems : null;

  const onPrev = () => {
    sliderRef.current.swiper.slidePrev();
  };

  const onNext = () => {
    sliderRef.current.swiper.slideNext();
  };

  const handleSlideChange = (swiper) => {
    setActiveItem(swiper.realIndex);
  };

  const handleClickItem = () => {
    if (sliderRef.current && sliderRef.current) {
      setShowModal(true);
      setActiveItem(activeItem);
    }
  };

  // const handlePlay = (video) => {
  //   setPlay(true);
  //   if (video?.fields?.IsYoutubeVideo?.value) {
  //     setShowModal(true);
  //     setYoutube(video?.fields?.Video?.value?.href);
  //   } else {
  //     videoRef?.current?.play();
  //   }
  // };

  // const handleEnd = () => {
  //   setPlay(false);
  // };

  return (
    <div className="container">
      <div className={`overflow-x-clip ${data && data[0]?.fields?.Image?.value?.src ? 'md:py-24 py-16' : 'h-0'}`}>
        <Swiper
          slidesPerView={1}
          onSlideChange={handleSlideChange}
          ref={sliderRef}
          spaceBetween={24}
          loop={true}
          // onClick={handleClickItem}
          allowTouchMove={true}
          thumbs={{ swiper: thumbsSwiper }}
          style={{
            '--swiper-navigation-color': '#fff',
            '--swiper-pagination-color': '#fff',
          }}
          effect={'fade'}
          navigation={false}
          touchMove={true}
          speed={800}
          className="mySwiper2"
          simulateTouch={true}
          centeredSlides={true}
          modules={[Navigation, EffectFade, Thumbs, Pagination]}
        >
          {data?.map((item, index) => (
            <SwiperSlide key={index} className="w-full">
              {/* {!play && (
                <>
                  <img src={item?.fields?.Image?.value?.src} className="w-full h-full object-cover object-center" />
                  {item?.fields?.Video?.value?.href && (
                    <img
                      src={video_play}
                      onClick={() => handlePlay(item)}
                      alt="play"
                      className={`absolute w-fit h-fit left-0 right-0 bottom-0 top-0 m-auto ${fields?.image ? 'z-50' : '-z-1'} cursor-pointer`}
                    />
                  )}
                </>
              )}
              {play &&
                (activeItem - 1 === index ? (
                  <>
                    <video controls onEnded={handleEnd} className="w-full h-full bg-gray-300" ref={videoRef}>
                      <source src={item?.fields?.Video?.value?.href} type="video/mp4"></source>
                    </video>
                  </>
                ) : (
                  <>
                    <img src={item?.fields?.Image?.value?.src} className="w-full h-full object-cover object-center" />
                    {item?.fields?.Video?.value?.href && (
                      <img
                        src={video_play}
                        onClick={() => handlePlay(item)}
                        alt="play"
                        className={`absolute w-fit h-fit left-0 right-0 bottom-0 top-0 m-auto ${fields?.image ? 'z-50' : '-z-1'} cursor-pointer`}
                      />
                    )}
                  </>
                ))} */}
              <div className="w-full transition-all h-full object-center">
                <Image field={isMobile ? item?.fields?.MobileImage?.value?.src ? item?.fields?.MobileImage : item?.fields?.Image : item?.fields?.Image} className="w-full max-w-4xl mx-auto h-full object-cover object-center" />
                {item?.fields?.Video?.value?.href && <img onClick={handleClickItem} src={video_play} alt="video_play" className="cursor-pointer absolute w-[90px] h-[90px] left-0 right-0 bottom-0 top-0 m-auto" />}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="max-w-4xl mx-auto w-full ml-auto mr-auto mt-6 flex md:flex-row flex-col md:items-center items-start">
          {fields?.SelectedItems.map(
            (item, index) =>
              activeItem === index && (
                <div key={index} className="text-black-300 text-lg md:w-1/3 w-full shrink-0 md:pr-3">
                  {item?.fields?.Title?.value}
                </div>
              )
          )}
          {totalSlides > 1 &&
            <div className="md:w-2/3 w-full shrink-0 relative">
              <div className="flex items-center justify-end gap-4 bg-gradient-to-l from-white from-50% to-transparent w-[180px] z-50 absolute ltr:right-0 rtl:left-0 top-0 bottom-0 my-auto">
                <div className="flex items-center gap-4">
                  <img className="cursor-pointer rtl:order-1" onClick={onPrev} src={arrowLeftDark} alt="arrow-left" />
                  <img className="cursor-pointer rtl:order-0" onClick={onNext} src={arrowRightDark} alt="arrow-right" />
                </div>
                <div className="text-black-300 leading-[24px] font-bold text-lg">
                  {activeItem + 1} / {totalSlides}
                </div>
              </div>
            </div>
          }
          <CarouselModal open={showModal} onCloseModal={() => setShowModal(false)}>
            <div className="w-full bg-transparent">
              <CenteredCarousel data={fields?.SelectedItems} defaultIndex={activeItem} />
            </div>
          </CarouselModal>
        </div>
      </div>
    </div>
  );
};

export default Default;

