import React, { useState, useEffect, useRef } from 'react';
import chevronDown from '../../assets/icons/chevron-down.svg';
import DictionaryText from './DictionaryText';

const Select = (props) => {
  const { options, value, onChange, className } = props;

  const [open, setOpen] = useState(false);

  const onClick = (id) => {
    onChange(id);
    setOpen(false);
  };

  const onOpen = () => {
    setOpen((state) => !state);
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setOpen(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className={`relative w-fit ${className}`}>
      <div onClick={onOpen} className="relative w-[288px] rounded-[5px] flex items-center justify-start gap-2 bg-sans-300 ltr:pl-6 ltr:pr-14 py-3 rtl:pl-14 rtl:pr-6 ">
        <div className="text-white text-lg font-bold select-none">
          <DictionaryText dictionaryKey={value?.displayName || 'all'} />
          </div>
        <img
          src={chevronDown}
          alt="chevrondown"
          className={`absolute select-none ltr:right-6 rtl:left-6 text-strokedark transition-all ${open ? 'rotate-180' : 'rotate-0'}`}
        />
      </div>
      {open && (
        <div className="absolute z-30 py-2 w-[288px] mt-2.5 rounded-lg p-5 bg-sans-100">
          <div className="w-full select-dropdown h-full max-h-[125px] overflow-y-auto">
            {options.map((item, index) => (
              <div key={index} onClick={() => onClick(item.id)} className="text-white">
                <div className="select-none text-lg hover:font-bold font-medium leading-[28px] pb-1 transition-all duration-500 duration">
                  <DictionaryText dictionaryKey={item.displayName} />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Select;
