import React, { useState } from 'react';

const Pill = (props) => {
  const { children, label, active, onClick } = props;

  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <div
      className={`${
        active ? 'bg-red-500' : 'bg-white'
      } cursor-pointer w-fit h-[52px] rounded shadow-default px-8 text-sm py-4 flex items-center gap-2 hover:bg-red-500 group tag`}
      onClick={handleClick}
    >
      {children}
      <h2 className={`${active ? 'text-white font-bold' : 'text-black-300 font-semibold'} text-sm group-hover:text-white`}>{label}</h2>
    </div>
  );
};

export default Pill;
