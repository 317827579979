import React from 'react';
import { RichText, Link } from '@sitecore-jss/sitecore-jss-react';

import CTA from '../../../Common/CTA';
import ImageCardCarousel from '../../../Common/ImageCardCarousel';

const Default = ({ fields, params }) => {
  const dark = params?.BackgroundColor === 'sans-500';

  return (
    <div className={`md:py-24 py-16 overflow-x-clip ${dark ? 'bg-sans-500' : 'bg-gradient-to-b from-[#d8e3efab] to-white'}`}>
      <div className="container">
        <ImageCardCarousel slidesToShow={3} data={fields?.SelectedItems} theme={dark ? 'dark' : 'light'}>
          <div className="flex items-center gap-8 flex-wrap">
            <RichText field={fields?.Title} className={`${dark ? 'text-white' : 'text-black-300'} text-3xl font-medium`} />
            <div className="w-fit">
              <Link field={fields?.Link}>
                <CTA size="small">{fields?.Link?.value?.text}</CTA>
              </Link>
            </div>
          </div>
        </ImageCardCarousel>
      </div>
    </div>
  );
};

export default Default;
