import React, { useState } from 'react';
import { RichText, Image, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useMediaQuery } from 'react-responsive';
import Modal from '../../../Common/Modal';

import CTA from '../../../Common/CTA';

const TwoColumn = ({ fields, params }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { sitecoreContext } = useSitecoreContext();
  // const [showModal, setShowModal] = useState(false);
  // const [indexModal, setIndexModal] = useState(0);

  return (
    <div className="container">
      <div className="w-full md:py-24 py-16 md:pb-28 pb-16 bg-white flex md:flex-row flex-col items-center gap-8">
        {fields?.SelectedItems.map((item, index) => (
          <div className="group relative md:w-1/2 w-full md:h-auto max-h-[692px] h-[461px]" key={index}>
            <div
              className="absolute z-10 left-0 inset-0 w-full h-full top-0"
              style={{
                backgroundImage: 'linear-gradient(180deg, rgba(224, 207, 191, 0.00) 45.83%, #E0CFBF 74.48%)',
              }}
            />
            <div className="relative">
              <Image
                field={isMobile ? (item?.fields?.MobileImage?.value?.src ? item?.fields?.MobileImage : item?.fields?.Image) : item?.fields?.Image}
                className="w-full md:h-[692px] h-[461px] object-cover object-center"
              />
              <div className="text-black-300 z-20 absolute md:px-8 px-4 w-full md:bottom-10 bottom-3">
                {sitecoreContext.pageEditing ? (
                  <RichText field={item?.fields?.Title} className="text-[22px] leading-10 font-bold" />
                ) : (
                  <h3 className="text-[22px] leading-10 font-bold">{item?.fields?.Title?.value}</h3>
                )}
                <RichText field={item?.fields?.Summary} className="mt-4 text-xl leading-9" />
                <div
                  className="w-fit mt-4"
                  // onClick={() => {
                  //   setShowModal(true);
                  //   setIndexModal(index);
                  // }
                  // }
                >
                  {item?.fields?.Link?.value?.href && <CTA field={item?.fields?.Link?.value} />}
                </div>
              </div>
            </div>
            {/* {index === indexModal && (
              <Modal open={showModal} style="light" history={true} onCloseModal={() => setShowModal(false)}>
                <div className="h-full w-full flex lg:flex-row flex-col overflow-clip items-center gap-8 p-5 md:p-9 lg:p-5">
                  <div className="w-full grow h-full bg-no-repeat bg-center bg-contain" style={{backgroundImage: `url(${item?.fields?.Image?.value?.src})`}}>
                  </div>
                  <div className="lg:h-[476px] ltr:pr-8 rtl:pl-8 shrink-0 h-[250px] lg:w-1/2 w-full text-start text-white overflow-auto">
                    <RichText className="text-[22px] leading-10 font-bold" field={item?.fields?.Title} />
                    <div className="mt-3">
                      <RichText className="text-xl font-bold leading-9" field={item?.fields?.Summary} />
                      <RichText className="text-xl leading-8" field={item?.fields?.Description} />
                    </div>
                  </div>
                </div>
              </Modal>
            )} */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TwoColumn;
