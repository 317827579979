import React, { useState, useRef } from 'react';
import { useMediaQuery } from 'react-responsive'
import Slider from 'react-slick';
import { RichText, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import Accordion from '../../../Common/Accordion';

const GallerySlider = ({ fields, params, sitecoreContext }) => {
  const sliderRef = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isNormalFaq = !fields?.IncludeImages?.value;

  const [activeIndex, setActiveIndex] = useState(0);
  const [mask, setMask] = useState(false);

  const handleClick = (index) => {
    if (fields?.SelectedItems?.length - 1 === index) {
      setMask(true);
    } else {
      setMask(false);
    }
    if (index !== activeIndex) {
      setActiveIndex(index);
      if (!isNormalFaq) {
        sliderRef && sliderRef.current.slickGoTo(index);
      }
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    vertical: true,
    slidesToShow: 1,
    touchMove: false,
    speed: 250,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div id={params?.ComponentID} className={isNormalFaq ? 'container' : 'leftContainer'}>
      <div className="mdd:py-20 py-16 ltr:mdd:pr-0 ltr:pr-6 ltr:pl-0 rtl:mdd:pl-0 rtl:pl-6 rtl:pr-0 flex items-start gap-24">
        <div className={`${!isNormalFaq ? 'mdd:w-3/5 w-full' : 'w-full'}`}>
          <RichText field={fields.Title} className="text-black-300 text-[30px] font-bold leading-[30px]" />
          <div className="mt-2">
            {fields?.SelectedItems.map((item, index) => (
              <Accordion
                order={index === 0 ? 'first' : index === fields?.SelectedItems?.length - 1 ? 'last' : ''}
                isNormalFaq={isNormalFaq}
                onClick={handleClick}
                title={item?.fields?.Title}
                key={index}
                index={index}
                open={activeIndex === index}
              >
                <RichText className="text-black-300 text-base leading-[28px] font-medium" field={item?.fields?.Description} />
                <div className="mdd:hidden block mt-4">
                  <div className="w-full relative max-w-xl mx-auto">
                    <div
                      className="absolute z-10 max-w-xl mx-auto left-0 inset-0 w-full h-full top-0"
                      style={{
                        backgroundImage: 'linear-gradient(0deg, #BCAEA1 0%, rgba(188, 174, 161, 0.00) 88.17%)',
                      }}
                    />
                    <Image field={item?.fields?.Image}
                      className="relative w-full max-w-xl mx-auto bg-center bg-cover bg-no-repeat"
                      style={{ backgroundImage: `url(${isMobile ? (item?.fields?.MobileImage?.value?.src || item?.fields?.Image?.value?.src) : item?.fields?.Image?.value?.src})` }}
                    >
                    </Image>
                    <div className="text-white z-20 absolute mdd:px-8 px-4 w-full mdd:bottom-10 bottom-3 mt-5">
                      {item?.fields?.Icon.value.src && <Image field={item?.fields?.Icon} />}
                      <RichText field={item?.fields?.Summary} className="w-full md:text-2xl text-xl font-medium md:leading-[43px] leading-9" />
                    </div>
                  </div>
                </div>
              </Accordion>
            ))}
          </div>
        </div>
        {!isNormalFaq && (
          <div className="w-2/5 mdd:block hidden relative overflow-clip">
            {/* <div className="absolute h-[170px] w-full bottom-0 z-10" style={{ background: mask ? 'white' : 'linear-gradient(0deg, #FFF 17.68%, rgba(255, 255, 255, 0.00) 114.9%)' }} /> */}
            <div className="ltr:-mr-24 rtl:-ml-24">
              <Slider {...settings} ref={sliderRef}>
                {fields?.SelectedItems.map((item, index) => (
                  <div className="px-3 my-4" key={index} dir={sitecoreContext?.language === 'ar' ? 'rtl' : 'ltr'}>
                    <div className="w-full relative">
                      <div
                        className="absolute z-10 left-0 inset-0 w-full h-full top-0"
                        style={{
                          backgroundImage: 'linear-gradient(0deg, #BCAEA1 0%, rgba(188, 174, 161, 0.00) 88.17%)',
                        }}
                      />
                      <div
                        className="relative mdd:h-[700px] h-[480px] w-full bg-center bg-cover bg-no-repeat"
                        style={{ backgroundImage: `url(${isMobile ? (item?.fields?.MobileImage?.value?.src || item?.fields?.Image?.value?.src) : item?.fields?.Image?.value?.src})` }}
                      >
                        <div className="text-white z-20 absolute mdd:px-8 px-4 w-full mdd:bottom-10 bottom-3">
                          {item?.fields?.Icon.value.src && <Image field={item?.fields?.Icon} />}
                          <RichText field={item?.fields?.Summary} className="w-2/3 text-2xl mt-5" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withSitecoreContext()(GallerySlider);
