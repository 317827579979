import React from 'react';

import Default from './Default';
import Primary from './Primary';
import Centered from './Centered';
import TextBlockWithHeadingAndImage from './TextBlockWithHeadingAndImage';
import ImageInCircle from './ImageInCircle';
import MainContentOnly from './MainContentOnly';
import TwoColumn from './TwoColumn';
import BioDetails from './BioDetails';
import MainContentOnly2 from './MainContentOnly2';
import TwoColumnsWithHeadingAndImage from './TwoColumnsWithHeadingAndImage';

const ContentBlock = ({ fields, params }) => {
  const getComponent = (variant) => {
    switch (variant) {
      case 'Default':
        return <Default fields={fields} params={params} />;
      case 'Primary':
        return <Primary fields={fields} params={params} />;
      case 'Centered':
        return <Centered fields={fields} params={params} />;
      case 'TextBlockWithHeadingAndImage':
        return <TextBlockWithHeadingAndImage fields={fields} params={params} />;
      case 'ImageInCircle':
        return <ImageInCircle fields={fields} params={params} />;
      case 'MainContentOnly':
        return <MainContentOnly fields={fields} params={params} />;
      case 'TwoColumn':
        return <TwoColumn fields={fields} params={params} />;
      case 'BioDetails':
        return <BioDetails fields={fields} params={params} />;
      case 'MainContentOnly2':
        return <MainContentOnly2 fields={fields} params={params} />;
      case 'TwoColumnsWithHeadingAndImage':
        return <TwoColumnsWithHeadingAndImage fields={fields} params={params} />;
      default:
        break;
    }
  };

  return getComponent(params?.Variant);
};

export default ContentBlock;
