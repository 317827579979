import React from 'react';

const SearchIcon = (props) => {
  const { width = 32, height = 32, className } = props;

  return (
    // <svg width={width} height={height} className="group" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path
    //     d="M41.561 37.1572C41.1064 36.6489 40.6003 36.1948 40.1251 35.7082C36.5406 32.1398 34.6502 30.042 32.7908 27.9983C32.2743 27.436 31.6752 26.8196 31.0554 26.2141C33.7618 21.1967 33.8445 14.8276 31.3136 9.7345C30.136 7.24743 28.3696 5.09557 26.128 3.47357C22.6261 0.932428 18.3909 -0.0191479 14.1969 0.770227C6.24282 2.2841 0.0758294 10.167 0.809257 18.7204C1.4807 26.679 7.65802 32.7453 14.9303 34.3781C18.5458 35.1891 22.7398 35.0161 26.0867 33.2319C26.4069 33.0589 27.5432 32.3452 28.6898 31.4585C29.0927 31.9019 29.4542 32.302 29.6505 32.5291C31.324 34.4538 32.8735 36.5949 33.7928 37.5897C34.7948 38.6819 35.7865 39.774 36.7988 40.8553C36.9331 40.9743 37.0571 41.0824 37.1604 41.1797C37.4703 41.4284 37.7079 41.5474 37.8422 41.4825C38.0901 41.4068 38.5859 41.2122 38.9991 40.877C40.0321 40.0443 40.3833 39.5361 41.1891 38.4872C41.7159 37.806 41.9328 37.5573 41.5713 37.168L41.561 37.1572ZM19.6304 29.3716C18.2979 29.696 16.7897 29.9122 15.4262 29.7176C12.0999 29.2418 8.86663 26.2789 7.15185 23.3918C5.67467 20.9047 5.15817 18.2771 5.3751 15.8116C5.75731 11.443 8.8563 7.4637 12.6371 5.77681C16.5211 4.03586 21.3246 4.84686 24.7438 7.34475C25.8801 8.16656 26.9131 9.31278 27.6258 10.5563C29.9707 14.6221 30.2187 19.9531 26.9854 24.4082C25.2086 26.9494 22.5435 28.6687 19.6304 29.3824V29.3716Z"
    //     fill="currentColor"
    //     className={className}
    //   />
    // </svg>
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width} height={height} viewBox="0 0 30 31" fill="none">
      <path d="M26.0068 14.3519C26.0068 15.1567 25.9218 15.9614 25.7688 16.7662C25.6158 17.571 25.3948 18.3408 25.0887 19.0757C24.7827 19.828 24.4257 20.5278 23.9836 21.2101C23.5415 21.8924 23.0485 22.5048 22.4874 23.0821C21.9264 23.6595 21.3313 24.1669 20.6682 24.6217C20.0051 25.0766 19.3251 25.444 18.594 25.759C17.8629 26.0739 17.1148 26.3013 16.3497 26.4588C15.5846 26.6162 14.8025 26.7037 14.0034 26.7037C13.2043 26.7037 12.4392 26.6162 11.6571 26.4588C10.8921 26.3013 10.127 26.0739 9.41287 25.759C8.68179 25.444 8.00171 25.0766 7.33863 24.6217C6.67555 24.1669 6.08048 23.6595 5.51942 23.0821C4.95835 22.5048 4.46529 21.8924 4.02324 21.2101C3.58118 20.5278 3.22414 19.828 2.91811 19.0757C2.61207 18.3233 2.39105 17.5535 2.23803 16.7662C2.08501 15.9789 2 15.1741 2 14.3519C2 13.5296 2.08501 12.7423 2.23803 11.9375C2.39105 11.1502 2.61207 10.3629 2.91811 9.62806C3.22414 8.87575 3.58118 8.17593 4.02324 7.4936C4.46529 6.81128 4.95835 6.19893 5.51942 5.62158C6.08048 5.04423 6.67555 4.53685 7.33863 4.08197C8.00171 3.62709 8.68179 3.25968 9.41287 2.94476C10.144 2.62984 10.8921 2.4024 11.6571 2.24494C12.4222 2.08748 13.2043 2 14.0034 2C14.8025 2 15.5676 2.08748 16.3497 2.24494C17.1148 2.4024 17.8799 2.62984 18.594 2.94476C19.3251 3.25968 20.0051 3.62709 20.6682 4.08197C21.3313 4.53685 21.9264 5.04423 22.4874 5.62158C23.0485 6.19893 23.5415 6.81128 23.9836 7.4936C24.4257 8.17593 24.7827 8.87575 25.0887 9.62806C25.3948 10.3804 25.6158 11.1502 25.7688 11.9375C25.9218 12.7248 26.0068 13.5296 26.0068 14.3519Z" strokeWidth="3.75" strokeLinecap="round" />
      <path d="M28 29.0007L23.2018 24.0801" strokeWidth="3.75" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SearchIcon;
