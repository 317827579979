import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive'
import { Swiper, SwiperSlide } from 'swiper/react';
import { RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import { Navigation, Thumbs, Pagination, Grid } from 'swiper';
import arrowLeftDark from '../../../../assets/icons/arrow-left-dark.svg';
import arrowRightDark from '../../../../assets/icons/arrow-right-dark.svg';
import arrowRightDisable from '../../../../assets/icons/arrow-right-disable.svg';
import arrowLeftDisable from '../../../../assets/icons/arrow-left-disable.svg';
import 'swiper/css';
import 'swiper/css/grid';
import Slider from 'react-slick';

const MediaList = ({ fields, params }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const dark = params?.BackgroundColor === 'sans-500';
  const sliderRef = useRef(null);
  const [activeItem, setActiveItem] = useState(1);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const totalSlides = fields?.SelectedItems.length;

  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    slidesToShow: 3,
    speed: 250,
    rows: 3,
    swipeToSlide: true,
    adaptiveHeight: true,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (current, next) => {
      setActiveItem(next);
    },
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          rows: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          rows: 6,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 6,
          initialSlide: 1,
          dots: false,
        },
      },
    ],
  };

  const onPrev = () => {
    sliderRef.current.slickPrev();
  };

  const onNext = () => {
    sliderRef.current.slickNext();
  };

  // const onChange = (values) => {
  //   setActiveItem(values.realIndex + 1);
  // };

  // const handleClickThumb = (index) => {
  //   sliderRef.current.swiper.slideToLoop(index);
  // };

  const results = fields?.SelectedItems;

  return (
    <div className="container">
      <div className="md:pb-14 pb-24 overflow-x-clip relative">
        <Slider {...settings} ref={sliderRef}>
          {results.map((item, index) => (
            <div key={index} className="test rounded-2xl p-3">
              <img src={isMobile ? (item?.fields?.MobileImage?.value?.src || item?.fields?.Image?.value?.src) : item?.fields?.Image?.value?.src} className="w-full h-[268px] object-cover object-center rounded-2xl" />
            </div>
          ))}
        </Slider>
        {fields?.SelectedItems.length > 1 && (
          <div className="flex absolute bottom-0 md:right-0 md:left-auto left-0 pb-5 z-50 bg-white">
            <div className="flex items-center gap-4">
              <img className="cursor-pointer" onClick={onPrev} src={arrowLeftDark} alt="arrow-left" />
              <img className="cursor-pointer" onClick={onNext} src={arrowRightDark} alt="arrow-right" />
            </div>
            <div className="text-darkblue-100 hidden md:block">
              {activeItem} / {Math.ceil(results?.length / 3)}
            </div>
            <div className="text-darkblue-100 block md:hidden">
              {Math.round(activeItem + 1)} / {Math.ceil(totalSlides / 6)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaList;
