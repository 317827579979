import React, { useEffect, useState } from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../../../lib/GraphQLData';
import TimelineCard from '../../../Common/TimelineCard';
import Select from '../../../Common/Select';
import PlusIcon from '../../../Common/icons/PlusIcon';
import MinusIcon from '../../../Common/icons/MinusIcon';
import { getYear } from '../../../../utils/functions';
import Button from '../../../Common/Button';

const gqlQuery = gqlLoader('./timeline.graphql');

const Timeline = (props) => {
  const GQL = GraphQLData(gqlQuery, {
    name: 'gqlQuery',
    options: {
      variables: {
        datasource: props?.rendering?.dataSource,
        pageSize: 5,
        skip: '0',
        language: 'en',
        year: '',
      },
    },
  })(ConnectedGQLResults.bind(props));

  return <GQL {...props} />;
};

const ConnectedGQLResults = (props) => {
  const results = props?.gqlQuery?.GQLResults?.results?.items;
  const totalCount = props?.gqlQuery.GQLResults?.results?.totalCount;
  const layoutData = props?.rendering?.fields;
  const gqlQuery = props.gqlQuery;
  const { error, loading, refetch } = props.gqlQuery;

  const [buttonText, setButtonText] = useState('load-more');
  const [pageSize, setPageSize] = useState(4);
  const [value, setValue] = useState();

  useEffect(() => {
    results?.length < totalCount ? setButtonText('load-more') : setButtonText('load-less');
  }, [results]);

  const options = layoutData?.data?.years?.results?.items;

  options?.sort((a, b) => {
    const numA = parseInt(a.item.year);
    const numB = parseInt(b.item.year);

    if (numA < numB) {
      return -1;
    }
    if (numA > numB) {
      return 1;
    }
    return 0;
  });

  const formatedOptions = options.map((item) => ({
    id: item?.item?.year,
    displayName: item?.item?.year,
  }));

  const allOptions = [{ id: '', displayName: 'all' }, ...formatedOptions];

  const handleChange = (id) => {
    setValue(formatedOptions.find((item) => item.id === id));
    refetch({ pageSize: 4, skip: '0', year: id });
  };

  const onLoadMore = () => {
    if (results?.length < totalCount) {
      const newPageSize = pageSize + 4;
      setPageSize(newPageSize);

      refetch({ pageSize: newPageSize, skip: '0' });
    } else {
      setPageSize(4);
      refetch({ pageSize: 4, skip: '0' });
    }
  };

  return (
    <div className="bg-white">
      <div className="container">
        <div className="relative md:py-20 py-16">
          <div className="flex items-center flex-wrap gap-5 justify-between mb-12">
            <RichText field={layoutData?.data?.Component?.title} className="text-black-300 font-semibold text-[26px]" />
            <div className="w-[288px]">
              <Select options={allOptions} value={value} onChange={handleChange} />
            </div>
          </div>
          {results &&
            results.map((item, index) => (
              <TimelineCard
                image={item?.item?.image}
                desc={item?.item?.description}
                title={item?.item?.title?.value}
                index={index}
                year={getYear(item?.item?.date?.value)}
                key={index}
                total={totalCount}
              />
            ))}
          {totalCount > 5 && (
            <div
              className={`absolute z-[10] flex flex-col ${
                results?.length < totalCount ? 'justify-center from-white bottom-0' : 'justify-end mb-[56px] from-transparent -bottom-10'
              } w-full h-[280px] bg-gradient-to-t from-50% to-transparent`}
            >
              <div className="w-fit mx-auto" onClick={onLoadMore}>
                <Button>
                  {buttonText === 'load-more' ? <PlusIcon /> : <MinusIcon />}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// compose() (from @apollo/client/react/hoc) can be used when you need more than one GraphQL query
// for a single query, e.g. `compose(GraphQLData(q1), GraphQLData(q2))(component)`
export default Timeline;
