import React, { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { IoCloseSharp } from 'react-icons/io5';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const CarouselModal = (props) => {
  const { open, children, onCloseModal, sitecoreContext } = props;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" onClose={onCloseModal} className="relative z-50">
        <Dialog.Overlay className="z-50">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-sans-500 opacity-95" />
          </Transition.Child>

          <div className={'fixed inset-0 z-10 overflow-y-clip'}>
            <div className="flex h-full items-center justify-center text-center sm:items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-100"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-transparent w-full max-w-fit md:py-0 py-6">
                  <div className="absolute left-0 right-0 mx-auto max-w-[843px] w-full" dir={sitecoreContext?.language === 'ar' ? 'rtl' : 'ltr'}>
                    <div className="relative modal-description w-full flex justify-end ltr:-ml-5 rtl:-mr-5 llg:mt-0 -mt-5 llg:ltr:ml-[20px] llg:rtl:mr-[20px]">
                      <IoCloseSharp
                        className="text-white llg:top-0 -top-6 w-6 h-6 shrink-0 cursor-pointer z-50 absolute"
                        onClick={onCloseModal}
                      />
                    </div>
                  </div>
                  <div className="max-w-[843px] px-6 mx-auto w-full h-full">
                    <div className="relative flex-auto flex items-center">{children}</div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog.Overlay>
      </Dialog>
    </Transition.Root>
  );
};

export default withSitecoreContext()(CarouselModal);
