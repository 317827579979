import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import Media from '../../../Common/Media';
import ThumbMedia from '../../../Common/ThumbMedia';
import MediaCarousel from './MediaCarousel';
import CarouselModal from '../../../Common/CarouselModal';
import CenteredCarousel from '../../../Common/CenteredCarousel';

const MediaGrid = ({ fields, params }) => {
  const items = fields?.SelectedItems;
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const secondary = fields?.BigTileOnLeft?.value;

  const [activeIndex, setActiveIndex] = useState(0);
  const [openMediaModal, setOpenMediaModal] = useState(false);

  const onClickThumbnail = (index) => {
    setOpenMediaModal(true);
    setActiveIndex(index);
  };

  const onCloseMediaModal = () => setOpenMediaModal(false);

  return (
    items && <div className="md:px-24 px-6 md:py-24 py-16">
      <div className="flex items-center md:flex-row flex-col md:gap-6 gap-4">
        {secondary ? (
          <>
            <div className="md:mt-0 mt-6 md:w-1/2 w-full md:h-[550px] h-[420px]">
              <ThumbMedia
                NoOverlappingText={true}
                thumbnailImage={isMobile ? items[0]?.fields?.MobileImage?.value?.src ? items[0]?.fields?.MobileImage : items[0]?.fields?.Image : items[0]?.fields?.Image}
                onClickThumbnail={() => onClickThumbnail(0)}
                video={items[0]?.fields?.Video.value.href}
              />
            </div>
            <div className="md:w-1/2 w-full md:h-[550px] h-[420px] flex flex-col md:gap-6 gap-4">
              <div className="flex items-center md:gap-6 gap-4 h-1/2">
                <div className="w-1/2 h-full ">
                  <ThumbMedia
                    NoOverlappingText={true}
                    thumbnailImage={isMobile ? items[0]?.fields?.MobileImage?.value?.src ? items[0]?.fields?.MobileImage : items[1]?.fields?.Image : items[1]?.fields?.Image}
                    onClickThumbnail={() => onClickThumbnail(1)}
                    video={items[1]?.fields?.Video.value.href}
                  />
                </div>
                <div className="w-1/2 h-full ">
                  <ThumbMedia
                    NoOverlappingText={true}
                    thumbnailImage={isMobile ? items[2]?.fields?.MobileImage?.value?.src ? items[2]?.fields?.MobileImage : items[2]?.fields?.Image : items[2]?.fields?.Image}
                    onClickThumbnail={() => onClickThumbnail(2)}
                    video={items[2]?.fields?.Video.value.href}
                  />
                </div>
              </div>
              <div className=" h-1/2">
                <ThumbMedia
                  NoOverlappingText={true}
                  thumbnailImage={isMobile ? items[3]?.fields?.MobileImage?.value?.src ? items[3]?.fields?.MobileImage : items[3]?.fields?.Image : items[3]?.fields?.Image}
                  onClickThumbnail={() => onClickThumbnail(3)}
                  video={items[3]?.fields?.Video.value.href}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="md:w-1/2 w-full md:h-[550px] h-[420px] flex flex-col md:gap-6 gap-4">
              <div className="h-1/2">
                <ThumbMedia
                  NoOverlappingText={true}
                  thumbnailImage={isMobile ? items[0]?.fields?.MobileImage?.value?.src ? items[0]?.fields?.MobileImage : items[0]?.fields?.Image : items[0]?.fields?.Image}
                  onClickThumbnail={() => onClickThumbnail(0)}
                  video={items[0]?.fields?.Video.value.href}
                />
              </div>
              <div className="flex items-center md:gap-6 gap-4 h-1/2">
                <div className="w-1/2 h-full ">
                  <ThumbMedia
                    NoOverlappingText={true}
                    thumbnailImage={isMobile ? items[1]?.fields?.MobileImage?.value?.src ? items[1]?.fields?.MobileImage : items[1]?.fields?.Image : items[1]?.fields?.Image}
                    onClickThumbnail={() => onClickThumbnail(1)}
                    video={items[1]?.fields?.Video.value.href}
                  />
                </div>
                <div className="w-1/2 h-full ">
                  <ThumbMedia
                    NoOverlappingText={true}
                    thumbnailImage={isMobile ? items[2]?.fields?.MobileImage?.value?.src ? items[2]?.fields?.MobileImage : items[2]?.fields?.Image : items[2]?.fields?.Image}
                    onClickThumbnail={() => onClickThumbnail(2)}
                    video={items[2]?.fields?.Video.value.href}
                  />
                </div>
              </div>
            </div>
            <div className="md:w-1/2 w-full md:h-[550px] h-[420px]">
              <ThumbMedia
                NoOverlappingText={true}
                thumbnailImage={isMobile ? items[3]?.fields?.MobileImage?.value?.src ? items[3]?.fields?.MobileImage : items[3]?.fields?.Image : items[3]?.fields?.Image}
                onClickThumbnail={() => onClickThumbnail(3)}
                video={items[3]?.fields?.Video.value.href}
              />
            </div>
          </>
        )}
      </div>
      <RichText field={fields?.Title} className="mt-4 leading-[28px] font-medium text-black-300 text-base" />
      <CarouselModal open={openMediaModal} onCloseModal={onCloseMediaModal}>
        <div className="w-full bg-transparent">
          <CenteredCarousel data={items} defaultIndex={activeIndex} />
        </div>
      </CarouselModal>
    </div>
  );
};

export default MediaGrid;
