import React from 'react';

import HighlightCards from './HighlightCards';
import Default from './Default';
import Interactive from './Interactive';
import TwoColumn from './TwoColumn';
import ThreeColumnHover from './ThreeColumnHover';
import GallerySlider from './GallerySlider';
import FullBleedCarousel from './FullBleedCarousel';
import FourColumnCarousel from './FourColumnCarousel';
import RoundedImagesCarousel from './RoundedImagesCarousel';
import Statistics from './Statistics';
import StatisticsCarousel from './StatisticsCarousel';

const Cards = ({ fields, params }) => {
  const getComponents = (variant) => {
    switch (variant) {
      case 'Interactive':
        return <Interactive fields={fields} params={params} />;
      case 'HighlightCards':
        return <HighlightCards fields={fields} params={params} />;
      case 'Two Column':
        return <TwoColumn fields={fields} params={params} />;
      case '3ColumnHover':
        return <ThreeColumnHover fields={fields} params={params} />;
      case 'GallerySlider':
        return <GallerySlider fields={fields} params={params} />;
      case 'FullBleedCarousel':
        return <FullBleedCarousel fields={fields} params={params} />;
      case 'FourColumnCarousel':
        return <FourColumnCarousel fields={fields} params={params} />;
      case 'RoundedImagesCarousel':
        return <RoundedImagesCarousel fields={fields} params={params} />;
      case 'Statistics':
        return <Statistics fields={fields} params={params} />;
      case 'StatisticsCarousel':
        return <StatisticsCarousel fields={fields} params={params} />;
      default:
        return <Default fields={fields} params={params} />;
    }
  };

  return getComponents(params.Variant);
};

export default Cards;
