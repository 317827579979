import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

const TextBlockWithHeadingAndImage = ({ fields, params }) => {
  return (
    <div className={`text-white text-start md:py-30 py-16 bg-${params?.BackgroundColor}`}>
      <div className="container">
        <RichText field={fields?.Title} className="md:text-4xl text-3xl" />
        <RichText field={fields?.Description} className="md:text-lg mt-14 md:w-3/5 w-full" />
      </div>
    </div>
  );
};

export default TextBlockWithHeadingAndImage;
