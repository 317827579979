import React from 'react';

const PlusIcon = (props) => {
  const { className, color } = props;

  return (
    <svg width="18" height="18" className={className} viewBox="0 0 18 18" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M2 9H16" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 2V16" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
};

export default PlusIcon;
