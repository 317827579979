import React, { useEffect, useState } from 'react';
import { RichText, Image ,useSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../../../lib/GraphQLData';
import { isMobile } from 'react-device-detect';
import CTA from '../../../Common/CTA';
import Pill from '../../../Common/Pill';
import Quotes from '../../../Common/Quotes';
import Select from '../../../Common/Select';
import DictionaryText from '../../../Common/DictionaryText';
import Button from '../../../Common/Button';

const gqlQuery = gqlLoader('./quotelisting.graphql');

const QuotesListing = (props) => {
  const GQL = GraphQLData(gqlQuery, {
    name: 'gqlQuery',
    options: {
      variables: {
        datasource: props?.rendering?.dataSource,
        pageSize: isMobile ? 5 : 6,
        itemPerPage: isMobile ? 5 : 6,
        skip: '0',
        tag: '',
        language: 'en',
      },
    },
  })(ConnectedGQLResults.bind(props));

  return <GQL {...props} />;
};

const ConnectedGQLResults = (props) => {
  const [pageSize, setPageSize] = useState(isMobile ? 5 : 6);
  const [itemPerPage] = useState(isMobile ? 5 : 6);
  const [tag, setTag] = useState('All');
  const [buttonText, setButtonText] = useState('load-more');
  const [value, setValue] = useState('');

  const results = props?.gqlQuery?.GQLResults?.results?.items;
  const totalCount = props?.gqlQuery.GQLResults?.results?.totalCount;
  const tags = props?.fields?.data?.Component?.contentTags?.targetItem?.children;
  const formatedTags = tags?.map((item) => ({
    id: item.id,
    label: item.name,
    displayName: item?.display_name?.value,
  }));
  const title = props?.fields?.data?.Component?.title;
  const { error, loading, refetch } = props.gqlQuery;

  const { sitecoreContext } = useSitecoreContext();
  const components = sitecoreContext?.route?.placeholders['jss-main'];
  const isPageTitle = useState(components[0]?.fields?.data?.Component?.title === title ? true : false);

  useEffect(() => {
    results?.length < totalCount ? setButtonText('load-more') : setButtonText('load-less');
  }, [results]);

  const onLoadMore = () => {
    if (results?.length < totalCount) {
      const newPageSize = pageSize + itemPerPage;
      setPageSize(newPageSize);
      refetch({ pageSize: newPageSize, skip: '0', tag: tag === 'All' ? '' : tag });
    } else {
      refetch({ pageSize: itemPerPage, skip: '0', tag: tag === 'All' ? '' : tag });
    }
  };

  const onFilter = (label) => {
    if (label === tag) {
      const all = formatedTags.find((item) => item.label === 'All');
      setTag(all?.label);
      setValue({ id: '', label: all?.displayName });
      refetch({ pageSize: itemPerPage, skip: '0', tag: '' });
    } else {
      setTag(label);
      const selectedTag = formatedTags.find((item) => item.label === label);
      setValue(selectedTag);
      label === 'All' ? refetch({ pageSize: itemPerPage, skip: '0', tag: '' }) : refetch({ pageSize: itemPerPage, skip: '0', tag: selectedTag?.id.toLowerCase() });
    }
  };

  const onChange = (id) => {
    const newValue = formatedTags.find((item) => item.id === id);
    setValue(newValue);
    setTag(newValue.label);
    newValue.label === 'All' ? refetch({ pageSize: itemPerPage, skip: 0, tag: '' }) : refetch({ pageSize: itemPerPage, skip: 0, tag: newValue.id.toLowerCase() });
  };

  return (
    <div className="relative bg-[#F1F0EF] md:py-20 py-16">
      <div className="container">
        {/* <RichText field={title} className="text-black-300 font-medium text-[26px]" /> */}
        {/* {isPageTitle[0] ? (
              <h1 className="text-black-300 font-medium text-[26px]">{title?.value}</h1>
            ) : (
              <div className="text-black-300 font-medium text-[26px]">{title?.value}</div>
          )} */}
          {props.sitecoreContext.pageEditing ? (
            <RichText field={title} className="text-black-300 font-medium text-[26px]" />
          ) : isPageTitle[0] ? (title?.value &&
            <h1 className="text-black-300 font-medium text-[26px]">{title?.value}</h1>
          ) : (title?.value &&
            <h2 className="text-black-300 font-medium text-[26px]">{title?.value}</h2>
          )}
        <div className="mt-6 md:flex justify-betwen items-center ">
          <div className="w-full flex">
            <div className="py-4 w-full flex items-center gap-5 overflow-auto overflow-x-auto scrollbar-hide">
              {tags?.map((item, index) => (
                <Pill label={item?.display_name?.value} active={item?.name?.toLowerCase() === tag?.toLowerCase()} onClick={() => onFilter(item?.name)} key={index} >
                  <Image field={item?.name === tag ? item?.icon : item?.darkicon} className="opacity-100 inline-block group-hover:opacity-0 group-hover:hidden"/>
                  <Image field={item?.icon} className="hidden opacity-0 group-hover:opacity-100 group-hover:inline-block"/>
                </Pill>
              ))}
            </div>
          </div>
          {props?.fields?.data?.Component?.showFiltersDropdown?.value === '1' && (
            <div className="md:mt-0 mt-6">
              <Select options={formatedTags} value={value} onChange={onChange} />
            </div>
          )}
        </div>
        <div className="grid grid-cols-1 gap-6 mt-6">
          {results?.map((item, index) => (
            <Quotes
              key={index}
              OpenQuoteIcon={item?.item?.openQuoteIcon}
              CloseQuoteIcon={item?.item?.closeQuoteIcon}
              Title={item?.item?.title}
              Description={item?.item?.description}
              readmore
            />
          ))}
        </div>
        {totalCount > 6 && (
          <div className="mt-8 w-fit mx-auto" onClick={onLoadMore}>
            <Button><DictionaryText dictionaryKey={buttonText} /></Button>
          </div>
        )}
      </div>
    </div>
  );
};

// compose() (from @apollo/client/react/hoc) can be used when you need more than one GraphQL query
// for a single query, e.g. `compose(GraphQLData(q1), GraphQLData(q2))(component)`
export default QuotesListing;
