import React from 'react';
import { useMediaQuery } from 'react-responsive'
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Parser from 'html-react-parser';

const Default = ({ fields, sitecoreContext }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div className="md:bg-gradient-to-r bg-[#F1F0EF] w-full">
      <div className="container">
        <div className="py-20">
          <div className="relative bg-white p-6 flex lg:flex-row flex-col gap-14">
            <div className="lg:w-5/12 lg:ltr:pl-14 lg:ltr:pr-8 lg:rtl:pl-8 lg:rtl:pr-14 pr-0 pl-0 pt-14">
            {sitecoreContext.pageEditing ? (
              <RichText field={fields?.Title} className="text-[22px] uppercase font-semibold" />
            ) : (fields?.Title?.value && <h2 className="text-[22px] uppercase font-semibold">{Parser(fields?.Title?.value)}</h2>)}
              <RichText field={fields?.Description} className="text-xl leading-9 mt-6" />
            </div>
            <div className="lg:w-7/12">
              <img alt="content" className="w-full object-cover object-center bg-center bg-cover bg-no-repeat lg:aspect-[4/3] h-full" src={isMobile ? (fields?.MobileImage?.value.src || fields?.Image?.value.src) : fields?.Image?.value.src}></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(Default);

