import React from 'react';

const AccessIcon = (props) => {
  const { width = 22, height = 26, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path
          id="Vector_2"
          d="M13.6027 3.1155C13.6027 -0.651994 8.2805 -0.607231 8.23514 3.1155C8.4317 6.62188 13.4515 6.64426 13.6027 3.1155Z"
          fill="currentColor"
          className={className}
        />
        <path
          id="Vector_3"
          d="M21.4498 6.99494C21.276 6.88303 21.0416 6.90541 20.8451 6.89795C20.3612 6.89795 13.4137 7.44256 11.4935 7.40526C10.5334 7.39034 2.82984 6.92033 1.71097 6.92033C1.5749 6.92033 1.45394 6.93525 1.3481 6.94271C1.0457 6.96509 0.856706 7.00986 0.818907 7.077C0.728188 7.18891 0.56187 7.42018 0.50895 7.66637C0.380432 8.28558 0.463591 8.56907 0.57699 9.18082C0.652588 9.58369 0.660149 9.73289 1.06838 9.74035C1.58246 9.74035 2.09653 9.72543 2.6106 9.71797C4.77273 9.67321 6.07303 9.64338 7.54721 9.66576C8.4544 9.71052 8.80971 9.95671 8.90799 11.3966C8.98359 12.5604 8.99115 13.7167 8.71143 14.8582C8.38636 16.2085 7.80424 17.4842 7.24481 18.7525C6.9273 19.4836 6.59466 20.2073 6.26203 20.9309C6.09571 21.289 5.92183 21.6546 5.75551 22.0127C5.61944 22.2962 5.27924 22.7289 5.43044 23.0497C5.52872 23.266 6.01255 23.5421 6.21667 23.654C6.53418 23.8256 7.21457 24.0046 7.53209 23.8629C7.69085 23.7883 7.78913 23.6689 7.84961 23.5122C8.68119 21.3935 9.47498 19.0882 10.6014 17.1038C10.8055 16.7457 11.3423 16.7606 11.5086 17.1411C12.0075 18.2601 12.5367 19.3642 13.0508 20.4758C13.5573 21.5651 13.9656 22.7065 14.5023 23.7808C14.5779 23.93 14.6837 23.9972 14.7367 23.9897C14.8425 24.0046 15.0542 24.0121 15.2432 23.9449C15.7043 23.7733 16.3469 23.6018 16.7249 23.281C16.9668 23.0721 16.8912 22.6767 16.7627 22.4081C16.1428 21.0801 15.1298 19.5731 14.5477 18.2303C14.1092 17.2231 13.6783 16.2384 13.391 15.179C13.1415 14.2539 12.9601 13.3064 12.9072 12.359C12.8694 11.6502 12.8543 10.8072 13.2171 10.1656C13.391 9.85973 13.6707 9.63591 14.0336 9.60607C16.7476 9.39718 20.2478 9.03162 21.0643 8.94955H21.3062C21.6086 8.94955 21.6162 8.83019 21.6691 8.51685C21.7522 8.03939 21.8127 7.95732 21.722 7.47986C21.6842 7.29335 21.5859 7.10684 21.4272 7.00239L21.4498 6.99494Z"
          fill="currentColor"
          className={className}
        />
      </g>
    </svg>
  );
};

export default AccessIcon;
