import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { RichText, Link, EditFrame, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import CTA from '../../../Common/CTA';
import GetEditFrame from '../../../Common/EditFrame';
import DownloadIcon from '../../../../assets/icons/download.svg';
import DictionaryText from '../../../Common/DictionaryText';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const DownloadPressRelease = ({ fields, bannerFields, sitecoreContext }) => {

  const handleDownloadPDF = () => {
    const input = document.querySelectorAll('.print');
    const printableComponent = document.createElement('div');
    printableComponent.classList.add('printableComponent');
    printableComponent.style.direction = sitecoreContext.language === 'ar' ? 'rtl' : 'ltr';
    document.body.append(printableComponent);
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';
    input.forEach((item) => {
      let clonedNode = item.cloneNode(true);
      if (clonedNode.querySelector('.hidePrint')) {
        let itemsToHide = clonedNode.querySelectorAll('.hidePrint');
        [].map.call(itemsToHide, (itemToHide) => {
          itemToHide.classList.add('hidden');
        });
      }
      if (clonedNode.querySelector('img')) {
        let itemsToHide = clonedNode.querySelectorAll('img');
        [].map.call(itemsToHide, (itemToHide) => {
          // if(!itemToHide.classList.contains('printImg')){
          itemToHide.classList.add('hidden');
          // }
        });
      }
      if (clonedNode.querySelector('.showPrint')) {
        let itemsToShow = clonedNode.querySelectorAll('.showPrint');
        [].map.call(itemsToShow, (itemToShow) => {
          itemToShow.classList.remove('hidden');
        });
      }
      printableComponent.append(clonedNode);
      document.body.appendChild(printableComponent);
    });
    html2canvas(printableComponent, { useCORS: true }).then((canvas) => {
      const imgData = new Image();
      imgData.src = canvas.toDataURL('image/jpeg', 1);
      imgData.crossOrigin = 'Anonymous';
      imgData.onload = function () {
        const pdf = new jsPDF('p', 'mm', 'a4', true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = 15;
        pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio - 20);
        pdf.save('news.pdf');
      };
    });
    document.querySelector('.printableComponent').remove();
    document.body.style.overflow = 'initial';
  };
  const data = fields?.SelectedItems[0];

  const isMobile = useMediaQuery({ maxWidth: 768 });

  let datasource = data?.id;

  let editFrameButtons = GetEditFrame(bannerFields, datasource);

  return (
    <EditFrame {...editFrameButtons}>
      <div
        className="relative bg-no-repeat bg-cover banner"
        style={{
          backgroundImage: `url(${isMobile ? data?.fields?.MobileImage?.value?.src || data?.fields?.Image?.value?.src : data?.fields?.Image?.value?.src})`,
          backgroundPosition: data?.fields?.CSSBackgroundImagePosition?.fields?.Value?.value || 'center',
        }}
      >
        <div className="absolute bg-gradient-to-b from-white from-0% to-100% to-[rgba(255, 255, 255, 0)] w-full h-full top-0 left-0 z-0"></div>
        <div className="container">
          <div className={'md:pb-32 pb-20 pt-8 text-black-300 flex md:flex-row flex-col items-start md:gap-16 gap-6 h-fit m-auto w-full relative z-20'}>
            <div className="md:w-1/4 w-full"></div>
            <div className="md:w-3/4 w-full text-base">
              {sitecoreContext.pageEditing ? (
                <RichText field={data?.fields?.Title} className="text-[22px] font-semibold leading-[40px] upper-case" />
              ) : (data?.fields?.Title?.value &&
                <h2 className="text-[22px] font-semibold leading-[40px] upper-case">{data?.fields?.Title?.value}</h2>
              )}
              <div className="flex mt-8 md:items-center items-start md:flex-row flex-col md:gap-8 gap-4">
                <div className="text-center flex flex-col sm:flex-row items-center gap-8">
                  {/* <div className="w-full text-base md:font-bold font-medium h-fit main-button clip-button">
                    <button type="button" className="setAccessibility whitespace-nowrap text-center px-7 py-3.5 rounded-l rounded-r-md leading-none bg-red-500 hover:bg-red-600 duration-300 font-bold active:bg-gray-400 active:text-red-500 text-white" onClick={handleDownloadPDF}>
                      // <img src={DownloadIcon} className="inline mx-[4px]"></img>
                      <DictionaryText dictionaryKey="download-pdf" />
                    </button>
                  </div> */}
                  <div className="w-full">
                    <CTA field={data?.fields?.CTALink?.value}
                      className="mt-1 text-center py-3.5 min-w-[170px] rounded-l rounded-r-md leading-none bg-red-500 hover:bg-red-600 duration-300 font-bold active:bg-gray-400 active:text-red-500 text-white"/>
                  </div>
                  <div className="w-full min-w-[180px]">
                    <CTA
                      field={data?.fields?.Link?.value}
                      className="mt-1 text-center py-3.5 min-w-[170px] rounded-l rounded-r-md leading-none bg-red-500 hover:bg-red-600 duration-300 font-bold active:bg-gray-400 active:text-red-500 text-white"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </EditFrame>
  );
};

export default withSitecoreContext()(DownloadPressRelease);
