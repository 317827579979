import React from 'react';

const ArabicIcon = (props) => {
  const { color, className } = props;

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="15" height="25" viewBox="0 0 15 25" fill="none">
      <g clipPath="url(#clip0_13148_14224)">
        <path fill={color} className="duration-200" d="M15 19.52L14.879 23.4467C14.8185 23.4774 11.9657 24.5 7.94355 24.5C3.18548 24.5 0 22.3526 0 17.8532C0 14.898 1.90524 12.6585 4.02218 11.2985C1.84476 10.3679 0.977823 8.44546 0.947581 6.59459C0.947581 3.42458 3.33669 0.5 6.78427 0.5C8.44758 0.5 9.6371 1.34874 10.1512 1.86003L9.79839 5.30614C9.20363 4.99936 8.16532 4.48807 6.79435 4.48807C4.85887 4.48807 3.54839 5.72539 3.54839 6.69685C3.54839 7.17746 4.41532 8.81359 6.91532 8.81359C8.85081 8.81359 11.2298 8.0262 12.3286 7.6683V11.595C10.8367 12.0143 9.50605 12.4438 8.33669 12.9244C4.7379 14.3151 2.59073 15.8796 2.59073 17.9043C2.59073 18.5077 3.42742 20.5324 8.33669 20.5324C11.9657 20.5324 15 19.5405 15 19.5405V19.52Z" />
      </g>
      <defs>
        <clipPath id="clip0_13148_14224">
          <rect width="15" height="24" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
};

export default ArabicIcon;
