import React, { useRef, useState } from 'react';
import { RichText, Image } from '@sitecore-jss/sitecore-jss-react';

import video_play from '../../assets/icons/video_play.svg';
import Modal from './Modal';

import ModalYouTube from './ModalYouTube';

const ThumbMediaWithModal = (props) => {
  const { thumbnailImage, isYouTubeVideo, isActive, onClickThumb, title, video, description, showCaption } = props;

  const [showModal, setShowModal] = useState(false);
  const videoRef = useRef(null);

  const onLoaded = () => {
    videoRef?.current?.play();
  };

  const onClickThumbnail = () => {
    if (onClickThumb) {
      isActive ? setShowModal(true) : onClickThumb();
    }
  };

  return (
    <div className="container">
      <div className="cursor-pointer w-full h-full">
        <div onClick={onClickThumbnail} className="w-full group relative h-full">
          <Image field={thumbnailImage} className="w-full h-full object-cover" />
          <RichText className="text-black-300 mt-6 mb-4 text-base font-medium text-start" field={title} />
          {video && <img src={video_play} onClick={()=>setShowModal(true)} alt="video_play" className="absolute w-[90px] h-[90px] left-0 right-0 bottom-0 top-0 m-auto" />}
        </div>
        {!video ? (
          <Modal open={showModal} onCloseModal={() => setShowModal(false)}>
            <div className="w-full px-5">
              <Image field={thumbnailImage} className="md:w-[843px] md:h-[497px] object-cover" />
              <RichText className="text-white mt-6 mb-4 text-2xl text-start" field={title} />
              <RichText className="text-white text-lg text-start" field={description} />
            </div>
          </Modal>
        ) : isYouTubeVideo ? (
          <ModalYouTube
            open={showModal}
            style="light"
            onCloseModal={() => {
              setShowModal(false);
              // setPlay(false);
            }}
          >
            <iframe
              src={video}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full h-full"
            ></iframe>
          </ModalYouTube>
        ) : (
          <Modal open={showModal} onCloseModal={() => setShowModal(false)}>
            <div className="w-full md:px-0 px-5">
              <video onLoadedData={onLoaded} ref={videoRef} controls className="md:w-[95%] w-full h-full bg-gray-300">
                <source src={video} type="video/mp4"></source>
              </video>
              <RichText className="text-white mt-6 mb-4 text-2xl text-start" field={title} />
              <RichText className="text-white text-lg text-start" field={description} />
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ThumbMediaWithModal;
