import React from 'react';
import { RichText, Image, EditFrame } from '@sitecore-jss/sitecore-jss-react';
import CTA from '../../../Common/CTA';
import GetEditFrame from '../../../Common/EditFrame';

const Error404 = ({ fields,bannerFields }) => {
  const data = fields?.SelectedItems[0];
  let datasource = data?.id;

  let editFrameButtons = GetEditFrame(bannerFields, datasource);

  return (
    <EditFrame {...editFrameButtons}>
      <div className="relative banner">
        <div className={'lg:px-[269px] px-6 lg:pt-52 pt-[200px] lg:pb-36 pb-24 min-h-[550px] flex flex-col items-start justify-center py-20 m-auto w-full'}>
          <RichText field={data?.fields?.Title} className="text-6xl mt-3 w-full" />
          <RichText field={data?.fields?.Summary} className="text-6xl mt-3 w-full" />
          <Image field={data?.fields?.Icon} className=" py-[40px] rounded-2xl" />
          <RichText field={data?.fields?.Description} className="text-xl font-medium mt-3 w-full" />
          {data?.fields?.CTALink?.value?.href && (
            <div className="w-fit mt-6">
              <CTA field={data?.fields?.CTALink?.value} />
            </div>
          )}
        </div>
      </div>
    </EditFrame>
  );
};

export default Error404;
