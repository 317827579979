import React from 'react';

const MenuIcon = (props) => {
  const { color = '#010101', width = 31, height = 25, className } = props;

  return (
    <svg width={width} height={height} className={className} viewBox="0 0 31 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Layer_1" clipPath="url(#clip0_9832_12678)">
        <path
          id="Vector"
          d="M30.9567 11.8616C30.9222 11.6891 30.8394 11.5235 30.6876 11.4269C30.5289 11.3234 30.3151 11.3441 30.1357 11.3372C29.6941 11.3372 27.5276 11.3303 26.879 11.3096C25.1403 11.2682 23.4016 11.1992 21.6629 11.1647C20.7935 11.1509 6.08337 11.1233 5.15881 11.1164C4.14456 11.1095 3.1372 11.0957 2.12295 11.0957C1.99876 11.0957 1.88836 11.1095 1.79177 11.1164C1.51578 11.1371 1.35019 11.1785 1.30879 11.2406C1.22599 11.3441 1.0742 11.558 1.0328 11.7788C0.922407 12.3447 0.991404 13.1451 1.0949 13.7109C1.1639 14.0767 1.1708 14.2216 1.53648 14.2216C2.00566 14.2216 2.46794 14.2078 2.93711 14.2009C6.40765 14.1319 9.8782 14.0629 13.3487 13.9939C16.0741 13.9387 18.8064 13.8489 21.5318 13.8489C23.4223 13.8489 25.3197 13.8489 27.2102 13.8765C28.2658 13.8834 29.3146 13.8558 30.3634 13.7592H30.5841C30.8601 13.7592 30.867 13.6488 30.9153 13.3659C30.9912 12.9312 31.0464 12.3102 30.9636 11.8685L30.9567 11.8616Z"
          fill="currentColor"
        />
        <path
          id="Vector_2"
          d="M5.73937 2.9255C7.2021 2.9393 10.2587 2.9462 10.6795 2.9669C16.7582 3.20842 23.5751 2.8289 24.2512 2.78749C24.5617 2.76679 25.3897 2.72539 26.1831 2.60118L26.3694 2.57358C26.6592 2.51148 26.673 2.40797 26.7144 2.12506C26.7834 1.68344 26.7903 1.13831 26.6937 0.703586C26.6592 0.537977 26.5626 0.365469 26.4177 0.275764C26.259 0.179159 26.1969 0.165358 26.0175 0.158458C25.0447 0.213661 24.0787 0.261963 23.1059 0.303365C19.9872 0.427572 16.8548 0.255063 13.7223 0.255063C11.0314 0.213661 5.74627 -0.207261 5.53928 0.151557C5.46338 0.241262 5.32539 0.441372 5.28399 0.655284C5.18049 1.18661 5.24259 1.93185 5.33919 2.45628C5.40128 2.79439 5.40818 2.9255 5.75317 2.9324L5.73937 2.9255Z"
          fill="currentColor"
        />
        <path
          id="Vector_3"
          d="M22.8572 22.84C22.7192 22.7572 22.664 22.7434 22.5122 22.7365C22.1465 22.7365 8.02285 22.1844 4.8145 22.0533C4.642 22.0464 4.53851 22.1016 4.51091 22.143C4.44191 22.2258 4.32462 22.3983 4.29012 22.5778C4.20042 23.0332 4.37982 23.9095 4.46261 24.3649C4.51781 24.6617 4.52471 24.7721 4.82139 24.779C6.31173 24.8066 9.51318 24.9446 10.0721 24.9653C11.0173 24.9929 16.4543 25.0067 17.2891 24.9929C18.1792 24.9722 20.3802 24.9515 22.4708 24.8618H22.664L22.8227 24.8273C23.0711 24.7721 23.0849 24.6824 23.1194 24.4408C23.1746 24.0613 23.1815 23.5921 23.0987 23.2126C23.0642 23.0677 22.9883 22.9228 22.8572 22.84Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_9832_12678">
          <rect width="30.0274" height="25" fill="currentColor" transform="translate(0.972656)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MenuIcon;
