import React, { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { IoCloseSharp } from 'react-icons/io5';

const Modal = ({ open, children, onCloseModal, history = false, sitecoreContext }) => {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" onClose={onCloseModal} className={'relative z-50'}>
        <Dialog.Overlay className="z-50">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-sans-500 opacity-95" />
          </Transition.Child>

          <div className={'fixed inset-0 z-10 overflow-y-hidden py-5'} dir={sitecoreContext?.language === 'ar' ? 'rtl' : 'ltr'}>
            <div className="h-full relative">
              <div className="h-full w-full relative flex">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-90"
                >
                  <Dialog.Panel className="flex items-center flex-col justify-center m-auto bg-transparent lg:w-[900px] max-w-full h-full md:py-0 md: pt-6 pb-0">
                    <div className="w-full lg:h-fit h-full relative">
                      <IoCloseSharp
                        className={`text-white ${history ? 'md:right-3 md:-top-2 rtl:md:left-2' : 'md:right-0 md:top-0 rtl:md:left-0 rtl:md:right-auto'
                          } right-4 -top-6 w-6 h-6 shrink-0 cursor-pointer z-50 absolute rtl:left-4 rtl:right-auto`}
                        onClick={onCloseModal}
                      />
                      {children}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog.Overlay>
      </Dialog>
    </Transition.Root>
  );
};

export default withSitecoreContext()(Modal);
