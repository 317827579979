import React from 'react';
import { RichText, Image, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useMediaQuery } from 'react-responsive'
import FormatedDate from '../../../../utils/FormatedDate';
import dot from '../../../../assets/icons/dot.svg';
import locationPin from '../../../../assets/icons/location.svg';
import SideRelateNews from '../../../Common/SideRelateNews'

const MainContentOnly2 = ({ fields, params }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { sitecoreContext } = useSitecoreContext();

  return (
    <div className="container print">
      <div className="flex items-start md:flex-row flex-col gap-16 py-12 ">
        <div className="md:w-2/3 w-full leading-[32px]">
            {fields?.Image?.value?.src && <Image field={isMobile ? fields?.MobileImage?.value?.src ? fields?.MobileImage : fields?.Image : fields?.Image} className="w-full mb-12" />}
            <div className="flex gap-2 items-center mb-12">
              {sitecoreContext?.route?.fields?.Date?.value &&
                <>
                  <img src={dot} className=" rounded-full w-[8.98px] h-[8.83px] printImg" />
                  <div className="text-base text-black-300 font-light" dir="ltr">
                    <FormatedDate date={sitecoreContext?.route?.fields?.Date?.value} />
                  </div>
                </>
              }
              { sitecoreContext?.route?.fields?.Date?.value && sitecoreContext?.route?.fields?.Location?.value && <span>|</span>}
              {sitecoreContext?.route?.fields?.Location?.value &&
                <>
                  <img src={locationPin} className=" rounded-full w-[18px] h-[18px] printImg" />
                  <div className="text-base text-black-300 font-medium" dir="ltr">
                    {sitecoreContext?.route?.fields?.Location?.value}
                  </div>
                </>
              }
            </div>
            {fields?.ImageBadge?.value?.src && <Image field={fields?.ImageBadge} className="mt-6" />}
            <Image field={fields?.Icon} />
            {/* <div className="text-black-300 text-lg mb-12 mt-6 font-bold">header</div> */}
            <RichText field={fields?.Header} className="text-black-300 text-lg my-6 font-bold" />
            <RichText field={fields?.Description} className="text-black-300 text-lg mt-6" />
        </div>
        {fields?.RelatedNews?.fields && !isMobile
          ? (<div className="md:w-1/3 w-full hidePrint">
              <SideRelateNews fields={fields?.RelatedNews?.fields} />
            </div>)
          : (<></>) }
      </div>
    </div>
  );
};

export default MainContentOnly2;
