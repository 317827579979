import React, { useEffect, useState, useRef } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const Accessibility = () => {
  const { sitecoreContext } = useSitecoreContext();
  const [fontSize, setFontSize] = useState(false);
  const [contrast, setContrast] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [alignment, setAlignment] = useState(false);
  const [fontFamily, setFontFamily] = useState(false);
  const [cursor, setCursor] = useState(false);
  const [accessibilityBarActive, setAccessibilityBarActive] = useState(false);
  const FontSize = useRef({
    storage: 'fontSizeState',
    cssClass: 'fontSize',
    currentState: false,
  }).current;
  const DarkMode = useRef({
    storage: 'darkState',
    cssClass: 'darkmode',
    currentState: false,
  }).current;
  const Contrast = useRef({
    storage: 'contrastState',
    cssClass: 'contrast',
    currentState: false,
  }).current;
  const Alignment = useRef({
    storage: 'alignmentState',
    cssClass: 'alignment',
    currentState: false,
  }).current;
  const FontFamily = useRef({
    storage: 'fontFamilyState',
    cssClass: 'fontFamily',
    currentState: false,
  }).current;
  const Cursor = useRef({
    storage: 'cursorState',
    cssClass: 'cursor',
    currentState: false,
  }).current;

  useEffect(() => {
    if(localStorage.getItem('fontSize') && localStorage.getItem('fontSize') !== 'false'){
      toggleFontSize();
    }
    if(localStorage.getItem('contrast') && localStorage.getItem('contrast') !== 'false'){
      toggleContrast();
    }
    if(localStorage.getItem('darkMode') && localStorage.getItem('darkMode') !== 'false'){
      toggleDarkMode();
    }
    if(localStorage.getItem('alignment') && localStorage.getItem('alignment') !== 'false'){
      toggleAlignment();
    }
    if(localStorage.getItem('fontFamily') && localStorage.getItem('fontFamily') !== 'false'){
      toggleFontFamily();
    }
    if(localStorage.getItem('cursor') && localStorage.getItem('cursor') !== 'false'){
      toggleCursor();
    }
  }, [] );
  useEffect(() => {
    if (fontSize) {
      document.body.classList.add('fontSize');
      document.querySelector('[data-accessibility="fontSize"]').classList.add('active');
      localStorage.setItem('fontSize' , true)
    } else {
      document.body.classList.remove('fontSize');
      document.querySelector('[data-accessibility="fontSize"]').classList.remove('active');
      localStorage.setItem('fontSize', false)
    }

    if (contrast) {
      document.body.classList.add('contrast');
      document.querySelector('[data-accessibility="contrast"]').classList.add('active');
      localStorage.setItem('contrast' , true)
    } else {
      document.body.classList.remove('contrast');
      document.querySelector('[data-accessibility="contrast"]').classList.remove('active');
      localStorage.setItem('contrast' , false)
    }

    if (darkMode) {
      document.body.classList.add('dark');
      document.querySelector('[data-accessibility="darkMode"]').classList.add('active');
      localStorage.setItem('darkMode' , true)
    } else {
      document.body.classList.remove('dark');
      document.querySelector('[data-accessibility="darkMode"]').classList.remove('active');
      localStorage.setItem('darkMode' , false)
    }

    if (alignment) {
      if (sitecoreContext?.language === 'ar') {
        // const dirs = document.querySelectorAll('[dir="rtl"]');
        // dirs.forEach(dir =>{
        //   if(! dir.classList.contains('slick-slider')){
        //     dir.setAttribute('dir', 'ltr')
        //   }
        // });
        document.body.classList.add('arabicAlignment');
      } else{
        // const dirs = document.querySelectorAll('[dir="ltr"]');
        // dirs.forEach(dir =>{
        //   if(! dir.classList.contains('slick-slider')){
        //     dir.setAttribute('dir', 'rtl')
        //   }
        // });
        document.body.classList.add('englishAlignment');
      }
      document.querySelector('[data-accessibility="alignment"]').classList.add('active');
      localStorage.setItem('alignment' , true)
    } else {
      if (sitecoreContext?.language === 'ar') {
        // const dirs = document.querySelectorAll('[dir="ltr"]');
        // dirs.forEach(dir =>{
        //   if(! dir.classList.contains('slick-slider')){
        //     dir.setAttribute('dir', 'rtl')
        //   }
        // });
        document.body.classList.remove('arabicAlignment');
      } else{
        // const dirs = document.querySelectorAll('[dir="rtl"]');
        // dirs.forEach(dir =>{
        //   if(! dir.classList.contains('slick-slider')){
        //     dir.setAttribute('dir', 'ltr')
        //   }
        // });
        document.body.classList.remove('englishAlignment');
      }
      document.querySelector('[data-accessibility="alignment"]').classList.remove('active');
      localStorage.setItem('alignment' , false)
    }
    if (fontFamily) {
      document.body.classList.add('fontFamily');
      document.querySelector('[data-accessibility="fontFamily"] span').innerHTML=`${sitecoreContext?.language === 'ar' ? 'روبوتو' : 'Roboto'}`;
      document.querySelector('[data-accessibility="fontFamily"]').classList.add('active');
      localStorage.setItem('fontFamily' , true)
    } else {
      document.body.classList.remove('fontFamily');
      document.querySelector('[data-accessibility="fontFamily"] span').innerHTML= `${sitecoreContext?.language === 'ar' ? 'غرته آرابك آر + لت' : 'Greta Arabic AR+LT'}`;
      document.querySelector('[data-accessibility="fontFamily"]').classList.remove('active');
      localStorage.setItem('fontFamily' , false)
    }
    if (cursor) {
      document.body.classList.add('cursor');
      document.querySelector('[data-accessibility="cursor"]').classList.add('active');
      localStorage.setItem('cursor' , true)
    } else {
      document.body.classList.remove('cursor');
      document.querySelector('[data-accessibility="cursor"]').classList.remove('active');
      localStorage.setItem('cursor' , false)
    }
  }, [fontSize, contrast, darkMode, alignment, fontFamily, cursor]);

  const toggleFontSize = () => {
      setFontSize(prevFontSize => !prevFontSize);
      if (fontSize) setFontSize(false);
      if (fontSize) localStorage.setItem('fontSize', false);
  };

  const toggleContrast = () => {
    setContrast(prevContrast => !prevContrast);
    if (darkMode) setDarkMode(false);
    if (darkMode) localStorage.setItem('contrast', false);
  };

  const toggleDarkMode = () => {
    setDarkMode(prevDarkMode => !prevDarkMode);
    if (contrast) setContrast(false);
    if (contrast) localStorage.setItem('darkMode', false);
  };

  const toggleAlignment = () => {
    setAlignment(prevAlignment => !prevAlignment);
  };

  const toggleFontFamily = () => {
    setFontFamily(prevFontFamily => !prevFontFamily);
  };

  const toggleCursor = () => {
    setCursor(prevCursor => !prevCursor);
  };

  const toggleAccessibilities = (action) => {
    switch (action) {
      case 'contrast':
        toggleContrast();
        break;
      case 'darkMode':
        toggleDarkMode();
        break;
      case 'alignment':
        toggleAlignment();
        break;
      case 'fontFamily':
        toggleFontFamily();
        break;
      case 'cursor':
        toggleCursor();
        break;
      case 'fontSize':
        toggleFontSize();
        break;
      case 'reset':
        setContrast(false);
        setDarkMode(false);
        setAlignment(false);
        setFontFamily(false);
        setCursor(false);
        setFontSize(false);
        const active = document.querySelectorAll('.setAccessibility.active')
        active.forEach(button =>
          button.classList.remove('active')
        );
        break;
      default:
        break;
    }
    setAccessibilityBarActive(prev => !prev);
  };

  useEffect(() => {
    const btnAccessibility = document.querySelectorAll('.setAccessibility');
    btnAccessibility.forEach(button =>
      button.addEventListener('click', () =>
        toggleAccessibilities(button.dataset.accessibility)
      )
    );

    return () => {
      btnAccessibility.forEach(button =>
        button.removeEventListener('click', toggleAccessibilities)
      );
    };
  }, []);

  return null;
};

export default Accessibility;
