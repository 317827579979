import React from 'react';
import { useMediaQuery } from 'react-responsive'
import { RichText } from '@sitecore-jss/sitecore-jss-react';

import HeaderLink from '../../../Common/HeaderLink';

const HighlightsCard = ({ fields }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const handleCardClick = (link) => {
    if (link?.href) {
      if (link.linktype === 'external') {
        window.open(link.href, '_blank');
      } else {
        window.location.href = link?.href;
      }
    }
  };
  return (
    <div className="container">
      <div className="w-full md:py-28 py-16 bg-white flex md:flex-row flex-col items-center gap-6">
        {fields?.SelectedItems.map((item, index) => (
          <div
            className={`relative overflow-clip group/card flex flex-col justify-end bg-center bg-cover bg-no-repeat md:w-1/2 w-full md:h-[700px] h-[550px] ${item?.fields?.Link?.value?.href ? 'cursor-pointer' : 'cursor-default'
              }`}
            style={{ backgroundImage: `url(${isMobile ? (item?.fields?.MobileImage?.value?.src || item?.fields?.Image?.value?.src) : item?.fields?.Image?.value?.src})` }}
            key={index}
          >
            <div className="absolute top-0 left-0 h-full w-full bg-gradient-to-b from-transparent from-[47%] to-[91.53%] to-[#B58368]" />
            <div onClick={() => handleCardClick(item?.fields?.Link?.value)} className="cursor-pointer absolute -bottom-[100px] group-hover/card:bottom-0 flex items-center flex-col justify-end left-0 right-0 bg-[#b58368] opacity-0 overflow-hidden w-full h-full group-hover/card:opacity-90 transition-all duration-500 z-20" />
            <div className="z-20 translate-y-full transition-all duration-500 group-hover/card:-translate-y-0 text-white absolute px-8 w-full">
              <div className="transition-all duration-500 -translate-y-full group-hover/card:translate-y-0 pt-8 pb-8 group-hover/card:pb-4">
                <RichText field={item?.fields?.Title} className="text-[22px] leading-10 font-bold" />
                <RichText
                  field={item?.fields?.Summary}
                  className="text-xl font-medium leading-[43px] mt-4 transition-all duration-500 group-hover/card:opacity-0 group-hover/card:invisible max-h-[100px] group-hover/card:max-h-0 group-hover/card:mt-0"
                />
              </div>
              <div className="translate-y-full transition-all duration-500 group-hover/card:-translate-y-0 pb-8">
                <RichText field={item?.fields?.Description} className="text-xl font-medium leading-[43px]" />
                {item?.fields?.Link?.value?.href && <div className="mt-6">
                  <div className="w-fit">
                    <HeaderLink variant="secondary" field={item?.fields?.Link?.value} />
                  </div>
                </div>
                }
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HighlightsCard;
