import React, { useRef, useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive'
import { RichText, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Slider from 'react-slick';
import RightIconDark from '../../../../assets/icons/arrow-right-dark.svg';
import LeftIconDark from '../../../../assets/icons/arrow-left-dark.svg';
import arrowRightDisable from '../../../../assets/icons/arrow-right-disable.svg';
import arrowLeftDisable from '../../../../assets/icons/arrow-left-disable.svg';
import CarouselModal from '../../../Common/CarouselModal';
import CenteredCarousel from '../../../Common/CenteredCarousel';
import video_play from '../../../../assets/icons/video_play.svg';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Pagination, Thumbs } from 'swiper';
import 'swiper/css';

const Default = (props) => {
  const { fields, params, sitecoreContext } = props;
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const lang = sitecoreContext.language;
  const data = fields?.SelectedItems;

  const [activeItem, setActiveItem] = useState(0);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const handleSlideChange = (swiper) => {
    setActiveItem(swiper.realIndex);
  };

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '300px',
    slidesToShow: 1,
    speed: 300,
    adaptiveHeight: false,
    slidesPerView: 'auto',
    touchMove: true,
    arrows: false,
    beforeChange: handleSlideChange,
    rtl: lang === 'ar' ? true : false,

    responsive: [
      {
        breakpoint: 2000,
        settings: {
          centerPadding: '100px',
        },
      },
      {
        breakpoint: 1440,
        settings: {
          centerPadding: '100px',
        },
      },
      {
        breakpoint: 1280,
        settings: {
          centerPadding: '100px',
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: '100px',
        },
      },
      {
        breakpoint: 768,
        settings: {
          touchMove: true,
          centerPadding: '40px',
        },
      },
    ],
  };

  const [openMediaModal, setOpenMediaModal] = useState(false);

  const sliderRef = useRef(null);
  const thumbsRef = useRef(null);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const rightArrowRef = useRef(null);
  const leftArrowRef = useRef(null);

  const onNext = () => {
    sliderRef?.current?.slideNext();
  };

  const onPrev = () => {
    sliderRef?.current?.slidePrev();
  };

  const onCloseMediaModal = () => setOpenMediaModal(false);

  const onClick = (index) => {
    if (index === activeItem) {
      setOpenMediaModal(true);
      setActiveItem(index);
    } else {
      sliderRef.current.slideTo(index);
    }
  };

  const handleClickItem = () => {
    if (sliderRef.current && sliderRef.current) {
      const activeIndex = sliderRef.current.activeIndex;

      if (activeIndex === sliderRef.current.clickedIndex) {
        // Clicked on the centered item
        // handleCenterItemClick();
        setOpenMediaModal(true);
        setActiveItem(sliderRef.current.realIndex);
      } else if (activeIndex < sliderRef.current.clickedIndex) {
        // Clicked on the right item
        // handleRightItemClick();
        rightArrowRef.current.click();
      } else {
        // Clicked on the left item
        // handleLeftItemClick();
        leftArrowRef.current.click();
      }
    }
  };

  return (
    <div id="" dir={lang === 'ar' ? 'rtl' : 'ltr'} className=" w-full md:py-[120px] py-[64px] relative">
      <div className="w-full h-full" dir="ltr">
        {' '}
        {/** dir="ltr" SHOULD BE KEEPED IN THIS LINE EVEN IN ARABIC PAGE, IF NOT STYLE BROKEN */}
        <Swiper
          className="Gallery_Default_swiper_1 mx-auto w-full max-w-[1560px] centered-carousel"
          centeredSlides={true}
          slidesPerView={'auto'}
          thumbs={{ swiper: thumbsSwiper }}
          // spaceBetween={20}
          onClick={handleClickItem}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
          onSwiper={(swiper) => {
            sliderRef.current = swiper;
          }}
          loop={true}
          onSlideChange={handleSlideChange}
          modules={[Pagination, Thumbs]}
          pagination={{
            clickable: true,
          }}
          ref={sliderRef}
        >
          {data?.map((item, index) => (
            <SwiperSlide
              className="w-[80%] border-[12px] border-transparent max-w-[1225px] md:h-[690px] h-[370px] flex items-center"
              key={index}
            // onClick={() => {
            //   if ((index === activeIndex - 1) || (activeIndex === 0 && index === data?.length - 1)) {
            //     onPrev()
            //     // setOpenMediaModal(true)
            //   } else if (index === (activeIndex + 1) % data?.length) {
            //     onNext()
            //     // setOpenMediaModal(true)
            //   } else {
            //     setOpenMediaModal(true)
            //   }
            // }}
            >
              <div className="w-full transition-all h-full">
                <Image field={isMobile ? item?.fields?.MobileImage?.value?.src ? item?.fields?.MobileImage : item?.fields?.Image : item?.fields?.Image} className="w-full h-full object-cover cursor-pointer" />
                {item?.fields?.Video?.value?.href && <img src={video_play} alt="video_play" className="cursor-pointer absolute w-[90px] h-[90px] left-0 right-0 bottom-0 top-0 m-auto" />}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* <div dir="ltr" className="max-w-[1536px] mx-auto">
        <Slider dir="ltr" ref={sliderRef} {...settings} className="centered-modal-carousel w-full lg:h-[700px] h-[400px]">
          {data?.map((item, index) => (
            <div dir="ltr" className="w-full h-full cursor-pointer" onClick={() => onClick(index)} key={index}>
              <div dir="ltr" className="w-full h-full flex flex-col relative overflow-clip">
                {index !== activeItem && <div className="absolute w-full h-full z-20"></div>}
                <div style={{ backgroundImage: `url("${item?.fields?.Image?.value.src}")` }} className="relative item grow w-full h-full bg-cover bg-no-repeat bg-center">
                  {item?.fields?.Video?.value?.href && <img src={video_play} className="absolute w-[90px] h-[90px] left-0 right-0 top-0 bottom-0 m-auto" />}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div> */}
      <div className="flex lg:flex-row items-start flex-col mt-8 gap-[30px] w-[80%] max-w-[1225px] mx-auto">
        <div className="lg:w-[300px] w-full">
          <RichText className="w-full text-black-300 font-medium" field={data[activeItem]?.fields?.Title} />
        </div>
        <div className="lg:w-[calc(100%_-_300px)] w-full relative rtl:mx-[0px]">
          <Swiper dir={lang === 'ar' ? 'rtl' : 'ltr'} className="thumb-carousel" ref={thumbsRef} onSwiper={setThumbsSwiper} centeredSlides={true} slidesPerView="auto" spaceBetween={10} loop={true}>
            {data?.map((item, index) => (
              <SwiperSlide key={index} style={{ width: 98 }}>
                <div className={`cursor-pointer outline-none duration-200 p-px border ${activeItem === index ? 'border-black-300' : 'border-transparent'}`}>
                  <Image field={isMobile ? item?.fields?.MobileImage?.value?.src ? item?.fields?.MobileImage : item?.fields?.Image : item?.fields?.Image} className="h-[58px] w-full object-cover" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="flex items-center w-fit gap-0 absolute right-0 top-0 h-[101%] rtl:right-auto rtl:-left-[1px] z-50">
            <div className="ltr:bg-gradient-to-r rtl:bg-gradient-to-l w-[80px] from-transparent h-full to-white"></div>
            <div className="bg-white flex items-center gap-4 h-full">
              <div className="flex text-black-300 bg-white items-center h-full gap-4">
                <img src={LeftIconDark} ref={leftArrowRef} onClick={lang === 'ar' ? onNext : onPrev} className="cursor-pointer rtl:order-1" />
                <img src={RightIconDark} ref={rightArrowRef} onClick={lang === 'ar' ? onPrev : onNext} className="cursor-pointer" />
              </div>
              <div className="text-black-300 font-bold flex items-center h-full text-lg select-none">
                {activeItem + 1} / {data?.length}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CarouselModal open={openMediaModal} onCloseModal={onCloseMediaModal}>
        <div className="w-full bg-transparent">
          <CenteredCarousel data={data} defaultIndex={activeItem} />
        </div>
      </CarouselModal>
    </div>
  );
};

export default withSitecoreContext()(Default);

