import React from 'react';
import { useMediaQuery } from 'react-responsive'
import { RichText } from '@sitecore-jss/sitecore-jss-react';

const Interactive = ({ fields }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <div className="container">
      <div className="w-full md:pb-28 pb-16 bg-white flex md:flex-row flex-col items-center gap-6">
        {fields?.SelectedItems.map((item, index) => (
          <div
            className="group overflow-clip flex flex-col justify-end expand-card-container relative md:w-1/3 w-full md:h-[700px] h-[230px] md:hover:h-[700px] hover:h-[460px] md:bg-cover bg-cover bg-center bg-no-repeat md:hover:w-2/3 transition-all duration-500"
            key={index}
            style={{ backgroundImage: `url(${isMobile ? (item?.fields?.MobileImage?.value?.src || item?.fields?.Image?.value?.src) : item?.fields?.Image?.value?.src})` }}
          >
            <div className="absolute z-10 left-0 w-full h-full bottom-0 bg-gradient-to-t from-sans-500 to-40% opacity-90" />
            <div className="absolute flex md:p-8 p-4 flex-col justify-end z-10 left-0 w-full h-full bottom-0 bg-sans-400 opacity-0 group-hover:opacity-90 transition-all duration-500" />
            <div className="absolute translate-y-full z-10 left-0 w-full h-full transition-all duration-500">
              <div className="text-white z-20 interactive-width w-full group-hover:-translate-y-full transition-all duration-500">
                <RichText
                  field={item?.fields?.Title}
                  className="uppercase w-full mdx:w-1/2 group-hover:mdx:w-full group-hover:w-full -translate-y-full transition-all duration-500 group-hover:translate-y-0 md:px-8 px-4 py-4 md:text-2xl text-xl font-bold"
                />
                <div className="md:px-8 px-4 md:pb-8 pb-4">
                  <RichText field={item?.fields?.Description} className="leading-8 md:text-xl text-base font-light" />
                  {item?.fields?.Link?.value?.href && (
                    <a className="w-fit mt-4 h-fit block" href={item?.fields?.Link?.value?.href + (item?.fields?.Link?.value?.anchor ? `#${item?.fields?.Link?.value?.anchor}` : '')}>
                      <div className="w-full h-fit main-button clip-button">
                        <button className="px-5 py-3.5 rounded-l rounded-r-md leading-none bg-white">
                          <svg className="rtl:-scale-x-100" width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.1767 6.11962C16.1323 5.91012 16.0215 5.69341 15.8367 5.57783C15.6371 5.4478 15.3637 5.47667 15.1345 5.46945C14.5728 5.46945 11.8159 5.45497 10.9955 5.44053C8.78561 5.38996 6.50916 5.20939 5.32659 5.19494C4.03315 5.18049 2.7545 5.17324 1.46107 5.16602C1.30585 5.17324 1.16542 5.18048 1.03978 5.18771C0.692396 5.2166 0.470662 5.26714 0.426316 5.33938C0.322841 5.46219 0.130677 5.72951 0.0715483 6.01124C-0.0688818 6.71918 0.0198098 7.03704 0.145458 7.73776C0.234151 8.19286 0.241538 8.36624 0.707174 8.37347C1.29846 8.38069 1.88975 8.35177 2.48842 8.34454C6.90089 8.25785 8.99995 7.91838 11.402 7.94005C12.7398 7.9545 14.0776 7.9111 15.408 7.79552H15.6889C16.0436 7.79552 16.0436 7.65831 16.1101 7.30434C16.2062 6.76255 16.2728 6.66141 16.1693 6.11962H16.1767Z" fill="#A99C96" />
                            <path d="M22.1338 4.82024C21.3208 4.05451 19.3178 2.14016 17.3592 0.377533L17.1744 0.211398L17.0044 0.0957907C16.731 -0.0703586 16.6423 -0.00526606 16.3836 0.175331C15.9919 0.457062 16.0436 0.392037 15.7849 0.796574C15.6814 0.9555 15.6223 1.15054 15.6667 1.33837C15.711 1.53341 15.7553 1.59114 15.8884 1.7284C16.0362 1.86565 17.6844 3.43328 20.5595 6.47454C20.5595 6.47454 22.0008 8.07101 20.4339 9.00289C17.8175 10.556 16.3392 10.975 15.201 11.5601C15.0089 11.6612 14.9276 11.7913 14.9276 11.8491C14.9054 11.9791 14.8832 12.2392 14.9571 12.4559C15.1493 13.0049 15.5558 13.2578 15.9401 13.6912C16.184 13.9729 16.2653 14.0957 16.5905 13.9151C18.2461 13.0193 21.8751 11.1917 22.4886 10.8449C25.142 9.37126 23.8338 6.43116 22.1264 4.81301L22.1338 4.82024Z" fill="#A99C96" />
                          </svg>
                        </button>
                      </div>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Interactive;
