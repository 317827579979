import React, { useState, useRef } from 'react';
import { useMediaQuery } from 'react-responsive'
import { RichText, Image, Link, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Grid, Pagination } from 'swiper';
import ArrowLeftIcon from '../../../Common/icons/ArrowLeftIcon';
import ArrowRightIcon from '../../../Common/icons/ArrowRightIcon';

import CTA from '../../../Common/CTA';

const RoundedImagesCarousel = ({ fields, params, sitecoreContext }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const dark = params?.BackgroundColor === 'sans-500';
  const data = fields?.SelectedItems;

  const swiperRef = useRef(null);

  const [isEnd, setIsEnd] = useState(false);
  const [isBeginning, setIsBeginning] = useState(true);

  const [pagination, setPagination] = useState();

  const [groupedItems, setGropedItems] = useState();

  const BREAK_POINTS = {
    300: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
    768: {
      slidesPerView: 3.3,
      slidesPerGroup: 3,
    },
  };

  const toNext = () => {
    swiperRef.current.swiper.slideNext();
  };

  const toPrev = () => {
    swiperRef.current.swiper.slidePrev();
  };

  const onChangeSlider = (swiper) => {
    setPagination(swiper?.pagination?.el?.outerText); // pagination "[currentNumber/totalNumber]"
    setIsEnd(swiperRef.current.swiper.isEnd);
    setIsBeginning(swiperRef.current.swiper.isBeginning);
  };

  const updateGroupItems = (size) => {
    let subGroup = []
    if (size === '300') {
      for (let i = 0; i < data?.length; i += 4) {
        subGroup.push(data?.slice(i, i + 4));
      };
    } else {
      for (let i = 0; i < data?.length; i += 1) {
        subGroup.push(data?.slice(i, i + 1));
      };
    }
    return subGroup;
  }

  return (
    <div className="md:py-24 py-16 overflow-x-clip bg-white">
      <div className="">
        <div className="relative">
          <div className="container w-full flex justify-between">
            <div className="flex items-center gap-8 flex-wrap">
              <RichText field={fields?.Title} className={`${dark ? 'text-white' : 'text-black-300'} text-3xl font-medium`} />
              <div className="w-fit">
                <Link field={fields?.Link}>
                  <CTA>{fields?.Link?.value?.text}</CTA>
                </Link>
              </div>
            </div>
            <div className="md:flex hidden items-center gap-4">
              <div className="flex items-center gap-4">
                <ArrowLeftIcon
                  disabled={isBeginning}
                  onClick={toPrev}
                  className="rtl:rotate-180 cursor-pointer"
                  color={dark ? 'white' : '#010101'}
                />
                <ArrowRightIcon
                  disabled={isEnd}
                  onClick={toNext}
                  className="rtl:rotate-180 cursor-pointer"
                  color={dark ? 'white' : '#010101'}
                />
              </div>
              <div className={`text-[18px] font-bold ${dark ? 'text-white' : 'text-black-300'}`}>{pagination}</div>
            </div>
          </div>
          <div className="mt-10 leftContainer rtl:pl-0 rtl:pr-6 ltr:pl-6">
            <Swiper
              ref={swiperRef}
              dir={sitecoreContext?.language === 'ar' ? 'rtl' : 'ltr'}
              spaceBetween={0}
              allowTouchMove={true}
              touchMove={true}
              pagination={{
                type: 'fraction'
              }}
              breakpoints={BREAK_POINTS}
              modules={[Grid, Navigation, Pagination]}
              onInit={(swiper) => {
                const subGroup = updateGroupItems(swiper?.currentBreakpoint)
                setGropedItems(subGroup)
              }}
              onBreakpoint={(swiper) => {
                const subGroup = updateGroupItems(swiper?.currentBreakpoint)
                setGropedItems(subGroup)
              }}
              onSlideChange={onChangeSlider}
            >
              {groupedItems?.map((item, index) => (
                <SwiperSlide key={index}>{console.log('groupedItems' , groupedItems)}
                  <div className="w-full ltr:md:pr-6 ltr:pr-0 rtl:md:pl-6 rtl:pl-0 md:pb-0 pb-6">
                    <Image
                      field={isMobile ? item[0]?.fields?.MobileImage?.value?.src ? item[0]?.fields?.MobileImage : item[0]?.fields?.Image : item[0]?.fields?.Image}
                      className="w-[108px] h-[108px] border border-black-300 mb-8 object-cover object-center rounded-full"
                    />
                    <RichText field={item[0]?.fields?.Summary} className="text-black-300 text-[18px] font-bold" />
                    <RichText field={item[0]?.fields?.Title} className="mt-3 text-black-300 text-[16px] font-bold leading-[28px]" />
                    <RichText field={item[0]?.fields?.Description} className="mt-3 text-black-300 text-[16px] leading-[28px]" />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="md:hidden container flex mt-5 items-center gap-4">
            <div className="flex items-center gap-4">
              <ArrowLeftIcon
                disabled={isBeginning}
                onClick={toPrev}
                className="rtl:rotate-180 cursor-pointer"
                color={dark ? 'white' : '#010101'}
              />
              <ArrowRightIcon
                disabled={isEnd}
                onClick={toNext}
                className="rtl:rotate-180 cursor-pointer"
                color={dark ? 'white' : '#010101'}
              />
            </div>
            <div className={`text-lg font-bold ${dark ? 'text-white' : 'text-black-300'}`}>
              {pagination}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(RoundedImagesCarousel);
