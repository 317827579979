import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { Placeholder, VisitorIdentification, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
// import { NavLink } from 'react-router-dom';
// import { withTranslation } from 'react-i18next';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { ScrollSmoother } from 'gsap-trial/ScrollSmoother';
// import { ScrollSmoother } from 'gsap/all';
import deepEqual from 'deep-equal';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Parser from 'html-react-parser';
import config from './temp/config';

// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.
import './assets/app.css';
import './assets/styles.css';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-responsive-modal/styles.css';

// gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

// import logo from './assets/sc_logo.svg';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

// This is boilerplate navigation for sample purposes. Most apps should throw this away and use their own navigation implementation.
// Most apps may also wish to use GraphQL for their navigation construction; this sample does not simply to support disconnected mode.
// let Navigation = ({ t, i18n }) => (
//   <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom">
//     <h5 className="my-0 me-md-auto fw-normal">
//       <NavLink to="/" className="text-dark">
//         <img src={logo} alt="Sitecore" />
//       </NavLink>
//     </h5>
//     <nav className="my-2 my-md-0 me-md-3">
//       <a
//         className="p-2 text-dark"
//         href="https://jss.sitecore.com"
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         {t('Documentation')}
//       </a>
//       <NavLink to="/styleguide" className="p-2 text-dark">
//         {t('Styleguide')}
//       </NavLink>
//       <NavLink to="/graphql" className="p-2 text-dark">
//         {t('GraphQL')}
//       </NavLink>
//     </nav>
//   </div>
// );

// inject dictionary props (`t`) into navigation so we can translate it
// NOTE: using this is needed instead of using i18next directly to keep
// the component state updated when i18n state (e.g. current language) changes
// Navigation = withTranslation()(Navigation);

const Layout = ({ route, context }) => {
  const _title = route.displayName.replace('-', ' ');
  const _result = _title?.replace(/([A-Z])/g, ' $1');
  const finalResult = _result?.charAt(0)?.toUpperCase() + _result?.slice(1);
  const ALT_TITLE = finalResult;

  const [url, setUrl] = useState();

  const location = useLocation();
  const [full_location, setFull_location] = useState('');

  useEffect(() => {
    // Check if we're in the browser before using window
    if (typeof window !== 'undefined') {
      // Now it's safe to use the window object
      setUrl(window.location.href);
      const id = window.location.href.split('#')[1];
      setFull_location(`${window.location.protocol}//${window.location.host}`);
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView();
      }
    }
  }, []);

  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setHeight(ref.current.offsetHeight);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  // =========== OPEN THESE COMMENT WHEN PURCHASED MODULE ============
  // useLayoutEffect(() => {
  //   ScrollSmoother.create({
  //     smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
  //   });
  // }, []);
  var schema = {};

  if (route?.templateName === 'Press Release') {
    schema = {
      '@type': 'Article',
      headline: route?.fields?.Headline?.value,
      description: route?.fields?.MetaDescription?.value,
      datePublished: route?.fields?.Date?.value,
      dateModified: route?.fields?.Date?.value,
      author: {
        '@type': 'Person',
        name: 'Sheikh Mohamed Bin Zayed Al Nahyan',
      },
      publisher: {
        '@type': 'Organization',
        name: 'Sheikh Mohamed Bin Zayed Al Nahyan',
      },
      url: url,
      image: route?.fields?.FeaturedImage?.value?.src,
      sameAs: route?.placeholders['jss-footer'][0]?.fields?.SocialMediaLinks?.map((item) => item.fields.Link.value.href),
      jobTitle: 'President of the United Arab Emirates and Ruler of Abu Dhabi',
      '@context': 'https://schema.org',
    };
  } else if (route?.templateName === 'Content Page' && route.name === 'biography') {
    schema = {
      '@type': 'Person',
      author: {
        '@type': 'Person',
        name: 'Sheikh Mohamed Bin Zayed Al Nahyan',
      },
      publisher: {
        '@type': 'Organization',
        name: 'Sheikh Mohamed Bin Zayed Al Nahyan',
      },
      url: url,
      image: route?.fields?.FeaturedImage?.value?.src,
      sameAs: route?.placeholders['jss-footer'][0]?.fields?.SocialMediaLinks?.map((item) => item.fields.Link.value.href),
      jobTitle: 'President of the United Arab Emirates and Ruler of Abu Dhabi',
      '@context': 'https://schema.org',
    };
  }

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <title>{(route.fields && route.fields.Headline && route.fields.Headline.value) || `Sheikh Mohamed Bin Zayed Al Nahyan | ${ALT_TITLE}`}</title>
        <meta charset="utf-8" />
        <meta name="og:title" content={route.fields && route.fields?.MetaTitle?.value ? route.fields?.MetaTitle?.value : `Sheikh Mohamed Bin Zayed Al Nahyan | ${ALT_TITLE}`} />
        <meta name="description" content={route?.fields?.MetaDescription?.value || ALT_TITLE} />
        <meta name="og:description" content={route?.fields?.MetaDescription?.value || ALT_TITLE} />
        <meta name="image" content={route?.fields?.FeaturedImage?.value?.src} />
        <meta name="og:image" content={route?.fields?.FeaturedImage?.value?.src} />
        <meta name="type" content={route?.fields?.SeoType?.value || 'Section'} />
        <meta name="keywords" content={route?.fields?.MetaKeywords?.value || ALT_TITLE} />
        <meta name="twitter:title" content={route?.fields?.MetaTitle?.value || ALT_TITLE} />
        <meta name="twitter:description" content={route?.fields?.MetaDescription?.value || ALT_TITLE} />
        <meta name="twitter:image" content={route?.fields?.FeaturedImage?.value?.src || ALT_TITLE} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:url" content={url} />
        {route?.fields?.NoFollow?.value && <meta name="robots" content="nofollow" />}
        {route?.fields?.NoIndex?.value && <meta name="robots" content="noindex" />}
        {context?.siteSettings?.googleMeta?.value && Parser(context?.siteSettings?.googleMeta?.value)}
        {!location.pathname.includes('/en') && !location.pathname.includes('/ar') && <link rel="canonical" href={`${full_location}/ar${location.pathname}`} />}
        {location.pathname.includes('/en') && <link rel="canonical" href={full_location + location.pathname} />}
        {location.pathname.includes('/ar') && <link rel="canonical" href={full_location + location.pathname} />}
        {Object.keys(schema).length > 0 && <script type="application/ld+json">{JSON.stringify(schema)}</script>}
      </Helmet>
      {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
      <VisitorIdentification />
      {/* google analytics script */}
      {context?.siteSettings?.googleAnalyticsScript?.value && Parser(context?.siteSettings?.googleAnalyticsScript?.value)}
      {/* <Navigation /> */}

      {/* root placeholder for the app, which we add components to using route data */}
      <div id="layout-container" dir={context?.language === 'en' ? 'ltr' : 'rtl'}>
        <header>
          <Placeholder name="jss-header" rendering={route} />
        </header>
        <main style={{ paddingBottom: height }}>
          <Placeholder name="jss-main" rendering={route} />
        </main>
        <footer ref={ref}>
          <Placeholder name="jss-footer" rendering={route} />
        </footer>
      </div>
    </React.Fragment>
  );
};

// We don't want to re-render `Layout` when route is changed but layout data is not loaded
// Layout will be re-rendered only when layout data is changed
const propsAreEqual = (prevProps, nextProps) => {
  if (deepEqual(prevProps.route, nextProps.route)) return true;

  return false;
};

export default React.memo(Layout, propsAreEqual);
