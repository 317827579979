import React from 'react';
import { useMediaQuery } from 'react-responsive'
import SideRelateNews from '../../../Common/SideRelateNews'

const Side = ({ fields }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return(
    <>
      {isMobile ? (<SideRelateNews fields={fields} />):(<></>) }
    </>
  )
};

export default Side;
