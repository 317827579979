import React from 'react';
import { useMediaQuery } from 'react-responsive'
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import dot from '../../../../assets/icons/dot-dark.svg';

const ThreeColumnHover = ({ fields, params }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div className="md:py-24 py-16 bg-white">
      <div className="container">
        <RichText field={fields?.Title} className="text-black-300 text-[26px] font-medium" />
        <div className="w-full flex md:flex-row flex-col items-center gap-6 mt-14 overflow-clip">
          {fields?.SelectedItems.map((item, index) => (
            <div
              className="group overflow-clip relative md:w-1/3 w-full h-[630px] flex flex-col justify-end bg-cover bg-center bg-no-repeat transition-all duration-500"
              key={index}
              style={{ backgroundImage: `url(${isMobile ? (item?.fields?.MobileImage?.value?.src || item?.fields?.Image?.value?.src) : item?.fields?.Image?.value?.src})` }}
            >
              <div className="absolute z-50 top-2 right-2 flex items-center gap-2 bg-[#FFFFFF80] p-[9px]">
                {/* <div className="w-5 h-5 rounded-full bg-sans-300" /> */}
                <img src={dot} className="w-[9px]" />
                <RichText field={item?.fields?.PreTitle} className="text-black-300 font-medium text-lg" />
              </div>
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent from-[72%] to-[100%] to-[#B58368]" />
              <div
                className={`absolute z-10 -bottom-[200px] group-hover:bottom-0 flex items-center flex-col justify-end left-0 right-0 bg-${
                  params?.BackgroundColor ? params?.BackgroundColor : '[#B58368]'
                } opacity-0 overflow-hidden w-full h-full group-hover:opacity-90 transition-all duration-500`}
              />
              <div className="absolute z-50 translate-y-full transition-all duration-500 group-hover:-translate-y-0 text-white px-8 w-full">
                <RichText
                  field={item?.fields?.Title}
                  className="md:text-base text-sm font-medium transition-all duration-500 -translate-y-full group-hover:translate-y-0 py-8"
                />
                {/* <RichText
                  field={item?.fields?.Description}
                  className="md:text-2xl text-xl translate-y-full transition-all duration-500 group-hover:-translate-y-0 pb-8"
                /> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ThreeColumnHover;
