import React from 'react';
import { RichText, Image, Link } from '@sitecore-jss/sitecore-jss-react';
import DictionaryText from './DictionaryText';
import FormatedDate from '../../utils/FormatedDate';
import pin from '../../assets/icons/pin.svg';

const OldImageCard = (props) => {
  const { image, date, title, theme = 'light', description, readmore, link } = props;

  return (
    <a href={link} className="w-full block h-fit group">
      <div className="mxl:h-[450px] h-[380px] overflow-clip">
        <Image field={image} className="w-full h-full group-hover:scale-105 transition-all duration-500 object-cover object-center" />
      </div>
      <div>
        <div className="flex mt-8 gap-2 items-center">
          <img src={pin} className="bg-sans-100 rounded-full w-10" />
          <div className="text-base text-black-300 font-semibold" dir="ltr">
            {date && <FormatedDate date={date} />}
          </div>
        </div>
        <RichText field={title} className={`mt-2 font-bold text-base ${theme === 'light' ? 'text-dark-300' : 'text-white'}`} />
        <RichText field={description} className={`mt-2 leading-7 font-medium text-base ${theme === 'light' ? 'text-dark-500' : 'text-white'}`} />
        <div className="w-fit">
          {readmore && link && <div className={`mt-3 underline group-hover:text-red-500 duration-300 text-sm ${theme === 'light' ? 'text-dark-300' : 'text-white'}`}>
            <DictionaryText dictionaryKey="read-more" />
          </div>
          }
        </div>
      </div>
    </a>
  );
};

export default OldImageCard;
