import React, { useRef, useState } from 'react';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { useMediaQuery } from 'react-responsive'

import ThumbMedia from './ThumbMedia';
import CarouselModal from './CarouselModal';
import CenteredCarousel from './CenteredCarousel';
import ArrowLeftIcon from '../Common/icons/ArrowLeftIcon';
import ArrowRightIcon from '../Common/icons/ArrowRightIcon';
import CTA from './CTA';

const VideoCardCarousel = (props) => {
  const { className, data, NoOverlappingText, theme = 'light', children, link, sitecoreContext } = props;
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const swiperRef = useRef(null);

  const [isEnd, setIsEnd] = useState(false);
  const [isBeginning, setIsBeginning] = useState(true);

  const [activeIndex, setActiveIndex] = useState(0);
  const [openMediaModal, setOpenMediaModal] = useState(false);
  const [pagination, setPagination] = useState();

  const BREAK_POINTS = {
    // when window width is >= 320px
    300: {
      slidesPerView: 1.2,
      slidesPerGroup: 1,
    },
    // when window width is >= 768px
    768: {
      slidesPerView: 2.5,
      slidesPerGroup: 2,
    },
  };

  const toNext = () => {
    swiperRef.current.swiper.slideNext();
  };

  const toPrev = () => {
    swiperRef.current.swiper.slidePrev();
  };

  const onClickThumbnail = (index) => {
    setOpenMediaModal(true);
    setActiveIndex(index);
  };

  const onCloseMediaModal = () => setOpenMediaModal(false);

  const onChangeSlider = (swiper) => {
    setPagination(swiper?.pagination?.el?.outerText); // pagination "[currentNumber/totalNumber]"
    setIsEnd(swiperRef.current.swiper.isEnd);
    setIsBeginning(swiperRef.current.swiper.isBeginning);
  };

  return (
    <div className={`${className} relative`}>
      <div className="container">
        <div className="w-full flex justify-between">
          {children}
          <div className="md:flex hidden items-center gap-4">
            <div className="flex items-center gap-4">
              <ArrowLeftIcon
                disabled={isBeginning}
                onClick={toPrev}
                className="rtl:rotate-180 cursor-pointer"
                color={theme === 'light' ? '#010101' : 'white'}
              />
              <ArrowRightIcon
                disabled={isEnd}
                onClick={toNext}
                className="rtl:rotate-180 cursor-pointer"
                color={theme === 'light' ? '#010101' : 'white'}
              />
            </div>
            <div className={`text-base font-bold ${theme === 'light' ? 'text-black-300' : 'text-white'}`}>
              {pagination}
            </div>
            {link?.value?.href && <div className="w-fit ml-10"><CTA field={link?.value} /></div>}
          </div>
        </div>
      </div>
      <div className="mt-10 leftContainer">
        <Swiper
          ref={swiperRef}
          dir={sitecoreContext?.language === 'ar' ? 'rtl' : 'ltr'}
          spaceBetween={0}
          allowTouchMove={true}
          touchMove={true}
          pagination={{
            type: 'fraction'
          }}
          breakpoints={BREAK_POINTS}
          modules={[Navigation, Pagination]}
          onInit={(swiper) => {
            setPagination(swiper?.pagination?.el?.outerText)
          }}
          onSlideChange={onChangeSlider}
        >
          {data.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="ltr:mr-6 rtl:ml-6">
                <ThumbMedia
                  thumbnailImage={isMobile ? item.fields.MobileImage?.value.src ? item.fields.MobileImage : item.fields.Image : item.fields.Image}
                  title={item?.fields?.Title}
                  description={item?.fields?.Summary}
                  className="2xl:h-[450px] h-[380px]"
                  video={item?.fields?.Video?.value?.href}
                  onClickThumbnail={() => onClickThumbnail(index)}
                  theme={theme}
                  NoOverlappingText={NoOverlappingText}
                  defaultTitle={false}
                />
                {NoOverlappingText && (
                  <>
                    <RichText field={item?.fields?.Title} className={`${theme === 'light' ? 'text-black-300' : 'text-white'} text-base leading-6 mt-6 font-bold`} />
                    <RichText field={item?.fields?.Summary} className={`${theme === 'light' ? 'text-black-300' : 'text-white'} text-base mt-4 leading-6 font-medium`} />
                  </>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="container">
        <div className="md:hidden flex mt-5 items-center gap-4">
          <div className="flex items-center gap-4">
            <ArrowLeftIcon
              disabled={isBeginning}
              onClick={toPrev}
              className="rtl:rotate-180 cursor-pointer"
              color={theme === 'light' ? '#010101' : 'white'}
            />
            <ArrowRightIcon
              disabled={isEnd}
              onClick={toNext}
              className="rtl:rotate-180 cursor-pointer"
              color={theme === 'light' ? '#010101' : 'white'}
            />
          </div>
          <div className="custom-swiper-pagination-2" />
          <div className={`text-base font-bold ${theme === 'light' ? 'text-black-300' : 'text-white'}`}>
            {pagination}
          </div>
        </div>
      </div>
      <CarouselModal open={openMediaModal} onCloseModal={onCloseMediaModal}>
        <div className="w-full bg-transparent">
          <CenteredCarousel data={data} defaultIndex={activeIndex} />
        </div>
      </CarouselModal>
    </div>
  );
};

export default withSitecoreContext()(VideoCardCarousel);

