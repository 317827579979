import React from 'react';
import { RichText, Image } from '@sitecore-jss/sitecore-jss-react';

import video_play from '../../assets/icons/video_play.svg';

const ThumbMedia = (props) => {
  const {
    thumbnailImage,
    NoOverlappingText,
    onClickThumbnail,
    className = 'h-full',
    title,
    video,
    theme = 'light',
    description,
    showCaption,
    defaultTitle = true
  } = props;

  return (
    <div className="cursor-pointer w-full h-full">
      <div onClick={onClickThumbnail} className="relative w-full group h-full">
        <Image field={thumbnailImage} className={` w-full ${className} object-cover`} />
        {defaultTitle && (
          <RichText field={title} className="text-black-300 mb-3 text-base font-medium mt-4" />
        )}
        {!NoOverlappingText && (
          <div className="bg-gradient-to-b from-transparent to-[#000000]/70 from-[40%] to-[100%] absolute w-full h-full left-0 top-0"></div>
        )}
        {video && <img src={video_play} alt="video_play" className="absolute w-[90px] h-[90px] left-0 right-0 bottom-0 top-0 m-auto" />}
        {!NoOverlappingText && (
          <div className="absolute bottom-0 left-0 px-6">
            <RichText field={title} className="text-white mb-3 text-xl" />
            <RichText field={description} className="text-white mb-6 text-base" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ThumbMedia;
