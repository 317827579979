import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

const Centered = ({ fields, params }) => {
  const white = !params?.BackgroundColor || params?.BackgroundColor === 'white';

  return (
    <div className={`${white ? 'text-black-300' : 'text-white'} text-center mx-auto md:py-24 py-16 bg-${params?.BackgroundColor}`}>
      <div className="container">
        <div className="md:px-[70px] px-6">
          <RichText field={fields?.Title} className="font-bold text-lg leading-[32px]" />
          <RichText field={fields?.Description} className="font-semibold text-xl leading-9" />
        </div>
      </div>
    </div>
  );
};

export default Centered;
