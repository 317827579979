import React, { useState, useRef, useEffect } from 'react';
import { RichText, Image ,withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import video_play from '../../assets/icons/video_play.svg';
// import checkBox from '../../assets/icons/check-box.svg';
// import checkedBox from '../../assets/icons/checked-box.svg';
import ModalYouTube from './ModalYouTube';
import Modal from './Modal';
import FormatedDate from '../../utils/FormatedDate';
import dot from '../../assets/icons/dot.svg';
import locationPin from '../../assets/icons/location.svg';


const VideoListing = (props) => {
  const {cTALink, image, title, description, summary, video, isYoutubeVideo, onClickThumb, date ,location , sitecoreContext} = props;

  // const [isChecked, setIsChecked] = useState(false);
  const videoRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  // const handleCheckboxChange = () => {
  //   setIsChecked(!isChecked);
  // };
  const onLoaded = () => {
    videoRef?.current?.play();
  };
  const onClickThumbnail = () => {
    if (onClickThumb) {
      isActive ? setShowModal(true) : onClickThumb();
    }
  };
  return(
    <>
      <div>
        <div className="cursor-pointer w-full md:h-[316px] h-[294px]">
          <div onClick={onClickThumbnail} className="w-full group relative h-full">
            <Image field={image} className="w-full h-full object-cover" />
            {video && <img src={video_play} onClick={()=>setShowModal(true)} alt="video_play" className="absolute w-[90px] h-[90px] left-0 right-0 bottom-0 top-0 m-auto" />}
          </div>
          {!video ? (
            <Modal open={showModal} onCloseModal={() => setShowModal(false)}>
              <div className="w-full px-5">
                <Image field={image} className="md:w-[843px] md:h-[497px] object-cover" />
                <RichText className="text-white mt-6 mb-4 text-2xl text-start" field={title} />
                <RichText className="text-white text-lg text-start" field={title} />
              </div>
            </Modal>
          ) : isYoutubeVideo ? (
            // <ModalYouTube
            //   open={showModal}
            //   style="light"
            //   onCloseModal={() => {
            //     setShowModal(false);
            //   }}
            // >
            //   <iframe
            //     src={video.url}
            //     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            //     allowFullScreen
            //     className="w-full h-full"
            //   ></iframe>
            // </ModalYouTube>
            <Modal open={showModal} onCloseModal={() => setShowModal(false)}>
              <div className="container">
                <iframe
                  src={video.url}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture "
                  allowFullScreen
                  className="w-full h-[454px] max-h-[100%] pt-3"
                ></iframe>
                <RichText className="text-white mt-6 mb-4 text-2xl text-start" field={title} />
                <RichText className="text-white text-lg text-start" field={title} />
              </div>
            </Modal>
          ) : (
            <Modal open={showModal} onCloseModal={() => setShowModal(false)}>
              <div className="w-full md:px-0 px-5">
                <video onLoadedData={onLoaded} ref={videoRef} controls className="md:w-[95%] w-full h-full bg-gray-300">
                  <source src={video} type="video/mp4"></source>
                </video>
                <RichText className="text-white mt-6 mb-4 text-2xl text-start" field={title} />
                <RichText className="text-white text-lg text-start" field={title} />
              </div>
            </Modal>
          )}
        </div>
        {/* <RichText field={title} className="mb-2 mt-4 leading-7 text-black-300 font-bold text-base" /> */}
        {/* <h3 className="mb-2 mt-4 leading-7 text-black-300 font-bold text-base"> {title?.value} </h3> */}
        {props.sitecoreContext.pageEditing ? (
          <RichText field={title} className="mb-2 mt-4 leading-7 text-black-300 font-bold text-base" />
        ) : (
          <h3 className="mb-2 mt-4 leading-7 text-black-300 font-bold text-base"> {title?.value} </h3>
        )}
        <RichText field={description} className="mt-2 text-base leading-7 font-medium text-black-300" />
        <div className="flex gap-2 items-center">
          { date &&
            <div className="flex gap-2 items-center">
              <img src={dot} className=" rounded-full w-[8.98px] h-[8.83px]" />
              <div className="text-base text-black-300 font-semibold" dir="ltr">
                <FormatedDate date={date} />
              </div>
            </div>
          }
          {location &&
            <span className="mdd:inline hidden">|</span>
          }
          {location &&
            <div className="flex gap-2 items-center">
              <img src={locationPin} className=" rounded-full h-[18px]" />
              <div className="text-base text-black-300 font-medium">
                  {location}
              </div>
            </div>
          }
        </div>
        {/* <div className="flex mt-4 uppercase gap-6">
          {cTALink && (
            <div className="text-black-300 duration-200 group-hover:text-red-500 text-sm underline cursor-pointer">
              <a href={cTALink.url}> {cTALink.text}</a>
            </div>
          )}
          {allowToDownload && (
            <div>
              <label>
                <img
                  src={isChecked ? checkedBox : checkBox} // Use different image based on state
                  alt="Checkbox"
                  className="custom-checkbox inline mr-2"
                  onClick={handleCheckboxChange}
                />
                SELECT ASSETS
              </label>
            </div>
          )}
        </div> */}
      </div>
    </>
  );
};

export default withSitecoreContext()(VideoListing);
