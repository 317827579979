import React, { useState, useEffect } from 'react';
import CTA from '../../../Common/CTA';
import { useMediaQuery } from 'react-responsive';
import { RichText, Image, Link, EditFrame, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import GetEditFrame from '../../../Common/EditFrame';

const FullBleedBannerV2 = ({ fields, bannerFields }) => {
  const data = fields?.SelectedItems[0];
  const isMobile = useMediaQuery({ maxWidth: 768 });

  let datasource = data?.id;

  let editFrameButtons = GetEditFrame(bannerFields, datasource);
  const { sitecoreContext } = useSitecoreContext();
  const [hasTitle, setHasTitle] = useState(false);
  useEffect(() => {
    document.querySelector('h1') ? setHasTitle(true) : setHasTitle(false);
  }, []);

  return (
    <EditFrame {...editFrameButtons}>
      <div
        className="relative bg-no-repeat bg-cover bg-bottom banner"
        style={{
          backgroundPosition: data?.fields?.CSSBackgroundImagePosition?.fields?.Value?.value || 'center',
          backgroundImage: `url(${isMobile ? data?.fields?.MobileImage?.value?.src || data?.fields?.Image?.value?.src : data?.fields?.Image?.value?.src})`,
        }}
      >
        <div className="absolute bg-gradient-to-b from-white from-0% to-100% to-[rgba(255, 255, 255, 0)] w-full h-full top-0 left-0 z-0" />
        <div className="container">
          {fields?.SelectedItems.map((item, index) => (
            <div key={index} className="relative">
              <div className={'text-black-300 md:py-40 py-16 flex md:flex-row flex-col items-start md:gap-16 gap-6 w-full object-cover object-center md:h-[800px] h-[1000px]'}>
                <div className="md:w-2/5 w-full">
                  {sitecoreContext.pageEditing ? (
                    <RichText field={item?.fields?.Title} className="text-[22px] leading-[40px] font-semibold" />
                  ) : hasTitle ? (
                    item?.fields?.Title?.value && <h2 className="text-[22px] leading-[40px] font-semibold">{item?.fields?.Title?.value}</h2>
                  ) : (
                    item?.fields?.Title?.value && <h1 className="text-[22px] leading-[40px] font-semibold">{item?.fields?.Title?.value}</h1>
                  )}
                </div>
                <div className="md:w-3/5 w-full text-base leading-[24px]">
                  <RichText field={item?.fields?.Summary} className="font-bold mb-8" />
                  <RichText field={item?.fields?.Description} className="font-medium" />
                  {item?.fields?.Link?.value?.href && (
                    <div className="w-fit mt-6">
                      <CTA field={item?.fields?.Link?.value} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </EditFrame>
  );
};

export default FullBleedBannerV2;
