import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

const Primary = ({ fields, params }) => {
  return (
    <div className={`md:px-24 text-white text-start px-6 md:py-30 py-16 bg-${params?.BackgroundColor}`}>
      <RichText field={fields?.Title} className="md:text-5xl text-4xl" />
      <RichText field={fields?.Description} className="md:text-lg mt-14 md:w-3/5 w-full" />
    </div>
  );
};

export default Primary;
