import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../../../lib/GraphQLData';
import queryString from 'query-string';
import Pill from '../../../Common/Pill';
import noResult from '../../../../assets/icons/no-result.svg';
import tagArrowRightDark from '../../../../assets/icons/tag-arrow-right-dark.svg';
import arrowRightDark from '../../../../assets/icons/arrow-right-dark.svg';
import arrowLeftDark from '../../../../assets/icons/arrow-left-dark.svg';
import arrowLeftDisable from '../../../../assets/icons/arrow-left-disable.svg';
import arrowRightDisable from '../../../../assets/icons/arrow-right-disable.svg';
// import search from '../../../../assets/icons/search_dark.svg';
import SearchIcon from '../../../Common/icons/SearchIcon';
import loadingLottie from '../../../../assets/icons/loading.json';
import DictionaryText from '../../../Common/DictionaryText';

const gqlQuery = gqlLoader('./search.graphql');

const Search = (props) => {
  const [searchParams] = useSearchParams();
  const tags = props?.fields?.data?.Component?.pageTypeTags?.targetItem?.children;
  const q = searchParams.get('q') || '';
  const page = searchParams.get('page') || 1;
  const pageTypeTag = searchParams.get('pageTypeTag') || '';
  const formatedTags = tags?.map((item) => {
    if (item?.name === 'All') {
      return {
        id: '',
        name: '',
        displayName: item?.display_name?.value,
      };
    } else {
      return {
        id: item.id,
        name: item?.name,
        displayName: item?.display_name?.value,
      };
    }
  });
  const selectedTag = formatedTags?.find((item) => item.id === pageTypeTag);
  const originObj = [{ name: 'pagetypetag', value: selectedTag?.id.toLowerCase() }];

  const GQL = GraphQLData(gqlQuery, {
    name: 'gqlQuery',
    options: {
      variables: {
        pageSize: 6,
        skip: `${(page - 1) * 6}`,
        keyword: q,
        filters: selectedTag?.name ? originObj : [],
      },
    },
  })(ConnectedGQLResults.bind(props));

  return <GQL {...props} />;
};

const ConnectedGQLResults = (props) => {
  const { sitecoreContext } = props;
  // const { sitecoreContext } = useSitecoreContext();
  const components = sitecoreContext?.route?.placeholders['jss-main'];
  const isPageTitle = useState(components[0]?.props?.data?.Component?.title?.value === props?.data?.Component?.title?.value ? true : false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const initialPage = parseInt(searchParams.get('page') || 1);
  const initialKeyword = searchParams.get('q') || '';
  const initialPageTypeTag = searchParams.get('pageTypeTag') || '';

  const results = props?.gqlQuery?.GQLResults?.results?.items;
  const totalCount = props?.gqlQuery?.GQLResults?.results?.totalCount;
  const gqlQuery = props?.gqlQuery;
  const tags = props?.fields?.data?.Component?.pageTypeTags?.targetItem?.children;
  const formatedTags = tags?.map((item) => {
    if (item?.name === 'All') {
      return {
        id: '',
        name: '',
        displayName: item?.display_name?.value,
      };
    } else {
      return {
        id: item.id,
        name: item?.name,
        displayName: item?.display_name?.value,
      };
    }
  });

  const { error, loading, refetch } = props?.gqlQuery;

  const [page, setPage] = useState(initialPage);
  const [q, setQ] = useState(initialKeyword);
  const [selectedKeyword, setSelectedKeyword] = useState(initialKeyword);
  const initTag = tags?.find((item) => item.id === initialPageTypeTag);
  const [tag, setTag] = useState(initTag?.name || '');

  const resultsRef = useRef(null);

  useEffect(() => {
    setQ(initialKeyword);
  }, []);

  const onChange = (e) => {
    setQ(e.target.value);
  };

  const onSubmit = () => {
    if (q) {
      navigate(`?${queryString.stringify({ q, page: 1 }, { skipNull: true, skipEmptyString: true })}`);
    }
  };

  const onFilter = async (activeTag) => {
    setTag(activeTag?.name);
    navigate(`?${queryString.stringify({ q, page: 1, pageTypeTag: activeTag?.id }, { skipNull: true, skipEmptyString: true })}`);
    const pageTypeTagFormat = [{ name: 'pagetypetag', value: activeTag?.id.toLowerCase() }];
    await refetch({
      pageSize: 6,
      skip: `${(page - 1) * 6}`,
      keyword: q,
      filters: activeTag?.name ? pageTypeTagFormat : [],
    });
    refetched()
  };

  useEffect(() => {
    refetched()
  }, [results, resultsRef])

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSelectedKeyword(q);
      onSubmit();
    }
  };

  const refetched = () => {
    resultsRef && resultsRef.current && resultsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const toNext = () => {
    if (+page === Math.ceil(totalCount / 6)) return;
    const newPage = +page + 1;
    setPage(newPage);
    navigate(`?${queryString.stringify({ q, page: newPage, pageTypeTag: tag }, { skipNull: true, skipEmptyString: true })}`);
  };

  const toPrev = () => {
    if (+page === 1) return;
    const newPage = +page - 1;
    setPage(newPage);
    navigate(`?${queryString.stringify({ q, page: newPage }, { skipNull: true, skipEmptyString: true })}`);
  };

  return (
    <div className="w-full">
      <div className="md:h-[390px] h-[360px] md:py-28 py-16 bg-gray-100">
        <div className="container">
          {/* <RichText field={props?.fields?.data?.Component?.title} className="text-6xl mt-24" /> */}
          {/* {isPageTitle[0] ? (
              <h1 className="text-6xl mt-24">{props?.fields?.data?.Component?.title?.value}</h1>
            ) : (
              <div className="text-6xl mt-24">{props?.fields?.data?.Component?.title?.value}</div>
          )} */}
          {props.sitecoreContext.pageEditing ? (
            <RichText field={props?.fields?.data?.Component?.title} className="text-6xl mt-24" />
          ) : isPageTitle[0] ? (
            <h1 className="text-6xl mt-24">{props?.fields?.data?.Component?.title?.value}</h1>
          ) : (
            <h2 className="text-6xl mt-24">{props?.fields?.data?.Component?.title?.value}</h2>
          )}
          <Image field={props?.fields?.data?.Component?.icon} className=" py-[40px] rounded-2xl" />
          <div className="relative">
            <input
              type="text"
              placeholder={sitecoreContext?.language === 'ar' ? 'ابحث' : 'Search'}
              value={q}
              onChange={(e) => onChange(e)}
              onKeyPress={handleKeyPress}
              className="h-[64px] w-full px-[40px] py-[18px] text-lg focus:outline-none text-black-300"
            />
            <div className="absolute md:top-[22px] top-[24px] w-fit" onClick={onSubmit} style={sitecoreContext?.language === 'ar' ? { left: '30px' } : { right: '30px' }}>
              <SearchIcon className="w-6 h-6 stroke-black-300 cursor-pointer" />
            </div>
          </div>
        </div>
      </div>
      {initialKeyword && (
        <div className="pt-20 pb-6 md:bg-gradient-to-r bg-gray-100">
          <div className="container">
            {loading && (
              <div className="mx-auto w-fit">
                <lottie-player
                  className="shipping-routes-map"
                  src={JSON.stringify(loadingLottie)}
                  speed="1.5"
                  style={{ width: '93px', height: '90px' }}
                  loop
                  autoplay
                ></lottie-player>
              </div>
            )}
            {!loading && (
              <>
                {!results || results?.length < 1 ? (
                  <div className="flex items-center justify-center flex-col md:gap-14 gap-6">
                    <img src={noResult} className="w-[112px] h-[112px]" />
                    <div className="text-center">
                      <div className="text-black-300 text-3xl font-bold uppercase leading-[40px]">
                        <DictionaryText dictionaryKey={'zero-results-for'} /> {sitecoreContext?.language === 'en' ? <>&ldquo;</> : <>&rdquo;</>}{selectedKeyword}{sitecoreContext?.language === 'en' ? <>&rdquo;</> : <>&ldquo;</>}</div>
                      <div className="text-base font-medium text-black-300 mt-2.5"><DictionaryText dictionaryKey={'no-search-results'} /></div>
                    </div>
                  </div>
                ) : (
                  <div ref={resultsRef}>
                    <div className="flex items-center justify-between">
                      <div className="text-black-300 font-medium text-3xl">
                        {totalCount} <DictionaryText dictionaryKey={'results-for-title'} /> {sitecoreContext?.language === 'en' ? <>&ldquo;</> : <>&rdquo;</>}{selectedKeyword}{sitecoreContext?.language === 'en' ? <>&rdquo;</> : <>&ldquo;</>}
                      </div>
                    </div>
                    <div className="my-8 flex flex-wrap items-center gap-5">
                      <div className="text-sm text-black-300 font-semibold"><DictionaryText dictionaryKey={'filter'} /></div>
                      {formatedTags?.map((item, index) => (
                        <Pill label={item?.displayName} active={item?.name === tag} key={index} onClick={() => onFilter(item)} />
                      ))}
                    </div>
                    <div>
                      {results &&
                        results?.map((item, index) => (
                          <a href={item?.item?.url} key={index}>
                            <div className="border border-black-300 hover:border-gray-500 duration-200 mb-10 p-6 text-xl">
                              <RichText field={item?.item?.headline} className="text-black-300 mt-4" />
                              <RichText field={item?.item?.teaser} className="text-black-300 mt-4" />
                              <div className="mt-4 w-full flex items-center justify-between">
                                <a
                                  href={item?.item?.url}
                                  className="border-b border-transparent hover:border-black-300 duration-300 text-black-300 text-sm"
                                >
                                  <DictionaryText dictionaryKey="read-more" />
                                </a>
                                <img src={tagArrowRightDark} className="cursor-pointer text-black-300 w-6 h-6 rtl:rotate-180" />
                              </div>
                            </div>
                          </a>
                        ))}
                    </div>
                    <div className={`${totalCount <= 6 ? 'hidden' : 'flex'} mt-10 font-bold text-base justify-end gap-4`}>
                      <img src={page === 1 ? arrowLeftDisable : arrowLeftDark} alt="arrow-left" onClick={toPrev} className="cursor-pointer w-6 h-6 rtl:rotate-180" />
                      <img src={+page === Math.ceil(totalCount / 6) ? arrowRightDisable : arrowRightDark} alt="arrow-right" onClick={toNext} className="cursor-pointer w-6 h-6 rtl:rotate-180" />
                      <div className="text-black-300 rtl:order-3">
                        {page} / {Math.ceil(totalCount / 6)}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

// compose() (from @apollo/client/react/hoc) can be used when you need more than one GraphQL query
// for a single query, e.g. `compose(GraphQLData(q1), GraphQLData(q2))(component)`
export default withSitecoreContext()(Search);
