import React from 'react';

import WithFilter from './WithFilter';
import Default from './Default';

const NewsListing = ({ fields, params, rendering }) => {
  const getComponents = (variant) => {
    switch (variant) {
      case 'WithFilter':
        return <WithFilter fields={fields} params={params} rendering={rendering} />;
      default:
        return <Default fields={fields} params={params} rendering={rendering}/>;
    }
  };

  return getComponents(params?.Variant);
};

export default NewsListing;

