import React from 'react';
import { Link, RichText } from '@sitecore-jss/sitecore-jss-react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';

const SearchSnapshot = (fields) => {
  const navigate = useNavigate();

  const handleClick = (name) => {
    navigate(`?${queryString.stringify({ q: name, page: 1 }, { skipNull: true, skipEmptyString: true })}`);
  };

  return (
    <div className="lg:bg-gradient-to-r bg-gray-100">
      <div className="container">
        <div className="md:flex items-start justify-between py-24 gap-[42px]">
          <div className="md:w-1/3 w-full">
            <div className="border-t-2 border-black-300 pl-5">
              <RichText field={fields?.fields?.data?.data?.recentSearchsTitleText} className="text-black-300 mt-4 text-xl font-bold" />
              <ul>
                {fields?.fields?.data?.data?.recentSearches?.targetItem?.children.map((item, index) => (
                  <li
                    key={index}
                    className="cursor-pointer text-black-300 mt-4 duration-200 border-b border-transparent text-base font-semibold w-fit hover:border-black-300"
                    onClick={() => handleClick(item.value.value)}
                  >
                    {item.value.value}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="md:w-1/3 w-full md:mt-0 mt-10">
            <div className="border-t-2 border-black-300 pl-5">
              <RichText field={fields?.fields?.data?.data?.popularSearchsTitleText} className="text-black-300 mt-4 text-xl font-bold" />
              <ul>
                {fields?.fields?.data?.data?.popularSearches?.targetItem?.children.map((item, index) => (
                  <li
                    key={index}
                    className="cursor-pointer text-black-300 mt-4 duration-200 border-b border-transparent text-base font-semibold w-fit hover:border-black-300"
                    onClick={() => handleClick(item.value.value)}
                  >
                    {item.value.value}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="md:w-1/3 md:mt-0 mt-10">
            <div className="border-t-2 border-black-300 pl-5">
              <RichText field={fields?.fields?.data?.data?.quickLinksTitleText} className="text-black-300 mt-4 text-xl font-bold" />
              <ul>
                {fields?.fields?.data?.data?.quickLinks?.targetItems.map((item, index) => (
                  <li
                    key={index}
                    className="cursor-pointer duration-200 text-black-300 mt-4 border-b border-transparent text-base font-semibold w-fit hover:border-black-300"
                    onClick={() => handleClick(item.name)}
                  >
                    <Link field={item.link} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchSnapshot;
