import React from 'react';
import { useMediaQuery } from 'react-responsive'
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import FormatedDate from '../../../../utils/FormatedDate';

const Contextual = (sitecoreContext, params) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const route = sitecoreContext?.sitecoreContext?.route;

  return (
    <div className="relative banner">
      <div
        className={'md:px-24 px-6 md:py-32 h-[800px] flex flex-col items-start justify-end bg-no-repeat bg-cover bg-center py-20 m-auto w-full'}
        style={{
          backgroundImage: `url(${isMobile ? (route?.fields?.MobileFeaturedImage?.value?.src ||  route?.fields?.FeaturedImage?.value?.src) : route?.fields?.FeaturedImage?.value?.src})`,
        }}
      >
        <div className="md:w-3/4 w-full bottom-24 text-start text-white">
          {route?.fields?.Date?.value && route?.fields?.Date?.value !== '0001-01-01T00:00:00Z' && (
            <div className="flex items-center gap-2">
              <div className="h-5 w-5 rounded-full shrink-0 bg-sans-100" />
              <div className="md:text-xl text-base">
                <FormatedDate date={route?.fields?.Date?.value} />
              </div>
            </div>
          )}
          <RichText field={route?.fields?.Headline} className="text-3xl mt-3" />
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(Contextual);
