import React from 'react';
import { format } from 'date-fns-tz';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const FormatedDate = (props) => {
  const { date, sitecoreContext } = props;

  const getYearMonthDay = (dateValue, lang) => {
    const newDate = new Date(dateValue);

    const formatter = new Intl.DateTimeFormat(lang, { month: 'long', timeZone: 'UTC' });
    const month = formatter.format(newDate);

    const dateBody = dateValue.toString().split('T')[0]

    const year = dateBody.split('-')[0]
    const day = dateBody.split('-')[2].replace(/^0+/, '')

    return { year, month, day };
  };

  const { year, month, day } = getYearMonthDay(date, sitecoreContext?.language);

  return sitecoreContext?.language === 'ar' ?
    <div className="flex items-center gap-2" dir="ltr">
      <div>{year}</div>
      <div>{month}</div>
      <div>{day}</div>
    </div>
    :
    <>{day} {month} {year}</>
};

export default withSitecoreContext()(FormatedDate);
