import React, { useState, useRef } from 'react';
import { RichText, Image, Link } from '@sitecore-jss/sitecore-jss-react';
import Slider from 'react-slick';

import CTA from '../../../Common/CTA';
import arrowLeftDark from '../../../../assets/icons/arrow-left-dark.svg';
import arrowRightDark from '../../../../assets/icons/arrow-right-dark.svg';

const StatisticsCarousel = ({ fields, params }) => {
  const dark = params?.BackgroundColor === 'sans-500';
  const data = fields?.SelectedItems;

  const sliderRef = useRef(null);
  const [activeItem, setActiveItem] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    slidesToShow: 4,
    speed: 250,
    swipable: false,
    // swipeToSlide: true,
    slidesToScroll: 3,
    // beforeChange: (current, next) => {
    //   setActiveItem(next);
    // },
    arrows: false,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          rows: 4,
          dots: false,
          swipable: true,
        },
      },
    ],
  };

  const toNext = () => {
    const nextItem = activeItem + 3;
    sliderRef && sliderRef.current.slickGoTo(nextItem);
  };

  const toPrev = () => {
    sliderRef && sliderRef.current.slickGoTo(activeItem);
  };
  return (
    <div className={`md:py-24 py-16 overflow-x-clip ${dark ? 'bg-sans-500' : 'bg-gradient-to-b from-[#d8e3efab] to-white'}`}>
      <div className="container">
        <div className="relative">
          <div className="w-full flex justify-between">
            <div className="flex items-center gap-8 flex-wrap">
              <RichText field={fields?.Title} className={`${dark ? 'text-white' : 'text-black-300'} text-3xl font-medium`} />
              <div className="w-fit">
                <Link field={fields?.Link}>
                  <CTA>{fields?.Link?.value?.text}</CTA>
                </Link>
              </div>
            </div>
            <div className="md:flex hidden items-center gap-4">
              <div className="flex items-center gap-4">
                <img src={arrowLeftDark} alt="arrow-left" onClick={toPrev} className="cursor-pointer" />
                <img src={arrowRightDark} alt="arrow-right" onClick={toNext} className="cursor-pointer" />
              </div>
              <div className="text-lg text-darkblue-100">{activeItem + 1 + '/' + data.length}</div>
            </div>
          </div>
          <div className="mt-10 md:-mr-[500px]">
            <Slider {...settings} ref={sliderRef}>
              {data.map((item, index) => (
                <div className="md:pr-20 pr-0 md:pb-0 pb-6" key={index}>
                  <RichText
                    field={item?.fields?.Title}
                    className="font-medium text-black-300 text-[86px] object-cover object-center rounded-full"
                  />
                  <RichText field={item?.fields?.Summary} className="mt-8 text-black-300 text-lg font-medium" />
                  <RichText field={item?.fields?.Header} className="mt-3 text-black-300 text-2xl" />
                  <RichText field={item?.fields?.Description} className="mt-3 text-black-300 text-lg" />
                </div>
              ))}
            </Slider>
          </div>
          <div className="md:hidden flex mt-5 items-center gap-4">
            <div className="flex items-center gap-4">
              <img src={arrowLeftDark} alt="arrow-left" onClick={toPrev} className="cursor-pointer" />
              <img src={arrowRightDark} alt="arrow-right" onClick={toNext} className="cursor-pointer" />
            </div>
            <div className="text-lg text-darkblue-100">{activeItem + 1 + '/' + data.length}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsCarousel;
