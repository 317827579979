import React from 'react';

import WithImages from './WithImages';
import Default from './Default';

const LatestNews = ({ fields, params }) => {
  const getComponents = (variant) => {
    switch (variant) {
      case 'WithImages':
        return <WithImages fields={fields} params={params} />;
      default:
        return <Default fields={fields} params={params} />;
    }
  };

  return getComponents(params?.Variant);
};

export default LatestNews;
