import React, { useState } from 'react';
import { RichText, Image, EditFrame, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import CTA from '../../../Common/CTA';
import GetEditFrame from '../../../Common/EditFrame';

const WithIconAndLink = ({ fields, bannerFields,sitecoreContext }) => {
  const data = fields?.SelectedItems[0];
  let datasource = data?.id;

  let editFrameButtons = GetEditFrame(bannerFields, datasource);

  const components = sitecoreContext?.route?.placeholders['jss-main'];
  const isPageTitle = useState(components[0]?.fields?.SelectedItems[0].id === fields?.SelectedItems[0]?.id ? true : false);

  return (
    <EditFrame {...editFrameButtons}>
      <div className="relative banner">
        <div className={'md:px-24 px-6 md:py-32 md:min-h-[550px] min-h-[190px] flex flex-col items-start justify-center pt-[220px] pb-[70px] m-auto w-full'}>
          {sitecoreContext.pageEditing ? (
            <RichText field={data?.fields?.Title} className="text-6xl mt-3" />
          ) : (
            <div>{data?.fields?.Title?.value && isPageTitle[0] ? <h1 className="text-6xl mt-3">{data?.fields?.Title?.value}</h1> : <h2 className="text-6xl mt-3">{data?.fields?.Title?.value}</h2>}</div>
          )}
          {sitecoreContext.pageEditing ? (
            <RichText field={data?.fields?.Summary} className="text-6xl mt-3" />
          ) : !data?.fields?.Title.value && isPageTitle[0] ? (
            <div>
              <h1 className="text-6xl mt-3">{data?.fields?.Summary?.value}</h1>
            </div>
          ) : (data?.fields?.Summary?.value &&
            <div>
              <h2 className="text-6xl mt-3">{data?.fields?.Summary?.value}</h2>
            </div>
          )}

          {/* <RichText field={data?.fields?.Summary} className="text-6xl mt-3" /> */}
          <Image field={data?.fields?.Icon} className=" py-3 rounded-2xl" />
          <RichText field={data?.fields?.Description} className="text-xl font-medium" />
          {data?.fields?.CTALink?.value?.href && (
            <div className="w-fit mt-6">
              <CTA field={data?.fields?.CTALink?.value} />
            </div>
          )}
        </div>
      </div>
    </EditFrame>
  );
};

export default withSitecoreContext()(WithIconAndLink);
