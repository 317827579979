import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Text, RichText, Link, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import LogoIcon from '../../../Common/icons/LogoIcon';

const Footer = ({ fields, sitecoreContext }) => {
  const location = useLocation();

  return (
    <div className="bg-gray-400">
      <div className="container">
        <div className="flex md:flex-row flex-col md:items-center items-start md:py-[56px] lg:gap-24 gap-14 py-8">
          <NavLink to={sitecoreContext?.language === 'ar' ? 'ar/' : 'en/'}>
            <LogoIcon className="fill-black-300 md:h-[168px] md:w-[164px] h-[95px]" />
          </NavLink>
          <div className="w-full">
            <div className="md:mb-8 mb-4 flex md:flex-row flex-col font-medium items-start md:gap-8 gap-4 text-[22px] text-black-300 flex-wrap max-w-[972px]">
              {fields?.PrimaryNavigationLinks.map((item, index) => (
                <Link field={item?.fields?.Link} key={index} className={`hover:text-white duration-200 ${location.pathname === item?.fields?.Link?.value.href ? 'font-bold' : ''}`} />
              ))}
            </div>
            <hr className="border-[#8F8883] border-2 opacity-25"></hr>
            <div className="flex md:flex-row flex-col md:items-center justify-between items-start">
              <div className="md:mt-8 mt-10 flex md:items-center md:flex-row flex-col items-start md:gap-[30px] gap-5 text-[#8F8883] text-sm">
                <RichText field={fields?.CopyRightText} />
                <div className="flex items-center gap-[30px] md:flex-row flex-col">
                  {fields?.SecondaryNavigationLinks.map((item, index) => (
                    <Link field={item?.fields?.Link} key={index} className="font-medium hover:text-white duration-200" />
                  ))}
                </div>
              </div>
              <div className="flex items-center md:justify-end justify-start place-items-end gap-12 pt-8">
                {fields?.SocialMediaLinks.map((item, index) => (
                  <Link field={item?.fields?.Link} key={index} className="group relative">
                    <Image className="md:h-7 md:w-7 h-8 w-8 duration-200 opacity-100 visible group-hover:opacity-0 group-hover:invisible" field={item?.fields?.Icon} />
                    <Image className="absolute top-0 left-0 md:h-7 md:w-7 h-8 w-8 duration-200 invisible opacity-0 group-hover:opacity-100 group-hover:visible" field={item?.fields?.IconLight} />
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(Footer);
