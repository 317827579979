import React, { useEffect, useState } from 'react';
import { RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import { useMediaQuery } from 'react-responsive'
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../../../lib/GraphQLData';
import Select from '../../../Common/Select';
import CTA from '../../../Common/CTA';
import Pill from '../../../Common/Pill';
import ImageCard from '../../../Common/ImageCard';
import Button from '../../../Common/Button';
import DictionaryText from '../../../Common/DictionaryText';

const gqlQuery = gqlLoader('./initiatives.graphql');

const InitiativesAndPatronages = (props) => {
  const GQL = GraphQLData(gqlQuery, {
    name: 'gqlQuery',
    options: {
      variables: {
        datasource: props?.rendering?.dataSource,
        pageSize: ( useMediaQuery({ maxWidth: 768 }) )? 20 : 20,
        skip: 0,
        tag: '',
        language: 'en',
      },
    },
  })(ConnectedGQLResults.bind(props));

  return <GQL {...props} />;
};

const ConnectedGQLResults = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const pageLoad = useState( isMobile ? 20 : 20);
  const [pageSize, setPageSize] = useState( isMobile ? 20 : 20);
  const [tag, setTag] = useState('All');
  const [buttonText, setButtonText] = useState('Load More');
  const [value, setValue] = useState('');
  const results = props?.gqlQuery?.GQLResults?.results?.items;
  const totalCount = props?.gqlQuery.GQLResults?.results?.totalCount;
  const tags = props?.fields?.data?.Component?.contentTags?.targetItem?.children;
  const title = props?.fields?.data?.Component?.title;
  const formatedTags = tags?.map((item) => ({
    id: item?.id,
    displayName: item?.display_name.value,
    label: item?.name,
  }));
  const { error, loading, refetch } = props?.gqlQuery;

  useEffect(() => {
    results?.length < totalCount ? setButtonText('load-more') : setButtonText('load-less');
  }, [results]);

  const onLoadMore = () => {
    if (results?.length < totalCount) {
      const newPageSize = parseInt(pageSize) + parseInt(pageLoad);
      setPageSize(newPageSize);

      if(formatedTags){
        const selectedTag = formatedTags.find((item) => item.label === tag);
        refetch({ pageSize: newPageSize, skip: 0, tag: tag === 'All' ? '' : selectedTag.id.toLowerCase() });
      }else{
        refetch({ pageSize: newPageSize, skip: 0, tag: '' });
      }

      // refetch({ pageSize: newPageSize, skip: 0, tag: tag === 'All' ? '' : selectedTag.id.toLowerCase() });
    } else {
      if(formatedTags){
        const selectedTag = formatedTags.find((item) => item.label === tag);
        refetch({ pageSize: parseInt(pageLoad), skip: 0, tag: tag === 'All' ? '' : selectedTag.id.toLowerCase() });
      }else{
        refetch({ pageSize: parseInt(pageLoad), skip: 0, tag: ''});
      }

    }
  };

  const onFilter = (label) => {
    setPageSize(pageLoad);
    if (label === tag) {
      const all = formatedTags.find((item) => item.label === 'All');
      setTag(all?.displayName);
      setValue({ id: '', label: all?.displayName });
      refetch({ pageSize: parseInt(pageLoad), skip: 0, tag: '' });
    } else {
      setTag(label);
      const selectedTag = formatedTags.find((item) => item.label === label);
      setValue(selectedTag);
      label === 'All' ? refetch({ pageSize: parseInt(pageLoad), skip: 0, tag: '' }) : refetch({ pageSize: parseInt(pageLoad), skip: 0, tag: selectedTag.id.toLowerCase() });
    }
  };

  const onChange = (id) => {
    const newValue = formatedTags.find((item) => item.id === id);
    setValue(newValue);
    setTag(newValue.label);
    newValue.label === 'All' ? refetch({ pageSize: parseInt(pageLoad), skip: 0, tag: '' }) : refetch({ pageSize: parseInt(pageLoad), skip: 0, tag: newValue.id.toLowerCase() });
  };

  return (
    <div className="relative md:py-20 py-16">
      <div className="container">
        <RichText field={title} className="text-black-300 font-semibold leading-[32px] text-[26px]" />
        {/* {props?.fields?.data?.Component?.showFiltersDropdown?.value === '1' && (
          <div className="lg:mt-0 mt-6">
            <Select options={formatedTags} value={value} onChange={onChange} />
          </div>
        )} */}

        <div className="mt-6 lg:flex lg:justify-betwen items-center">
          <div className="w-full py-4 flex items-center gap-5 overflow-auto scrollbar-hide flex-wrap">
            {tags?.map((item, index) => (
              <Pill label={item?.display_name?.value} active={item?.name === tag} onClick={() => onFilter(item?.name)} key={index}>
                <Image field={item?.name === tag ? item?.icon : item?.darkicon} className="opacity-100 inline-block group-hover:opacity-0 group-hover:hidden"/>
                <Image field={item?.icon} className="hidden opacity-0 group-hover:opacity-100 group-hover:inline-block"/>
              </Pill>
            ))}
          </div>
        </div>
        <div className="grid md:grid-cols-4 grid-cols-1 gap-6 mt-4">
          {results?.map((item, index) => (
            <ImageCard
              key={index}
              link={item?.item?.link?.url}
              date={item?.item?.date?.value}
              image={isMobile ? item?.item?.mobileImage?.value?.src ? item?.item?.mobileImage : item?.item?.image : item?.item?.image}
              title={item?.item?.title}
              description={item?.item?.teaser}
              readmore
            />
          ))}
        </div>
        {console.log('total count ' ,totalCount + totalCount > 20 )}
        {totalCount >  parseInt(pageLoad) && (
          <div className="mt-8 w-fit mx-auto" onClick={onLoadMore}>
            {/* <CTA >{buttonText}</CTA> */}
            <Button>
              <DictionaryText dictionaryKey={buttonText} />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

// compose() (from @apollo/client/react/hoc) can be used when you need more than one GraphQL query
// for a single query, e.g. `compose(GraphQLData(q1), GraphQLData(q2))(component)`
export default InitiativesAndPatronages;
