import React from 'react';

const LinkButton = (props) => {
  const { field, variant = 'primary' } = props;

  return (
    <a href={field?.href} className="group w-fit relative">
      {variant === 'primary' ? (
        <>
          <div className="text-black-300 group-active:text-red-500 group-hover:text-red-500 text-base font-bold pb-[18px] leading-none duration-200">{field?.text}</div>
          <div className="absolute w-full h-[6px] rounded-r-md overflow-clip rounded-l-lg group-hover:bg-red-500 bg-black-300 bottom-0 group-active:bottom-[10px] duration-200 link-underline">
            <div className="relative w-full h-full">
              <div className="w-full translate-x-0 h-full bg-black-300 group-hover:-translate-x-full group-active:-translate-x-full duration-300"></div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="text-white group-active:text-red-500 group-hover:text-red-500 text-base font-bold pb-[18px] leading-none">{field?.text}</div>
          <div className="absolute w-full h-[6px] rounded-r-md overflow-clip rounded-l-lg group-hover:bg-red-500 bg-white bottom-0 group-active:bottom-[10px] duration-200 link-underline">
            <div className="relative w-full h-full">
              <div className="w-full translate-x-0 h-full bg-white group-hover:-translate-x-full group-active:-translate-x-full duration-300"></div>
            </div>
          </div>
        </>
      )}
    </a>
  );
};

export default LinkButton;
