import React, { useState } from 'react';
import { RichText, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import arrowLeftDark from '../../../../assets/icons/arrow-left-dark.svg';
import arrowRightDark from '../../../../assets/icons/arrow-right-dark.svg';
import { useNavigate } from 'react-router-dom';

const WithBackButton = ({ fields,sitecoreContext }) => {
  const navigate = useNavigate();

  const components = sitecoreContext?.route?.placeholders['jss-main']
  const isPageTitle = useState(components[0]?.fields?.Headline?.value === fields?.Headline?.value ? true : false);

  return (
    <div className="container print">
        <div className="flex items-start md:flex-row flex-col gap-16 py-12 hidePrint">
            <div className="bg-white w-full md:w-2/3 w-full leading-[32px]">
                <div className="pt-[180px] max-w-[950px] w-full bottom-10 lg:bottom-20 text-start">
                    <div className="flex items-center gap-2 cursor-pointer" onClick={() => navigate(-1) }>
                        {sitecoreContext?.language === 'ar' ? (
                            <>
                                <img src={arrowRightDark} alt="arrow-right-dark" className="mr-[15px]"/>
                                <div className="text-black-300 text-lg font-bold">
                                    <span>رجوع</span>
                                </div>
                            </>
                        ):(
                            <>
                                <img src={arrowLeftDark} alt="arrow-left-dark" className="mr-[15px]"/>
                                <div className="text-black-300 text-lg font-bold">
                                    <span>Back</span>
                                </div>
                            </>
                        )}
                    </div>
                    {/* <RichText field={fields?.Headline} className="mt-8 font-medium text-3xl leading-[40px] text-black-300" /> */}
                    {/* {isPageTitle[0] ? (
                        <h1 className="mt-8 font-medium text-3xl leading-[40px] text-black-300">{fields?.Headline?.value}</h1>
                        ) : (
                        <div className="mt-8 font-medium text-3xl leading-[40px] text-black-300">{fields?.Headline?.value}</div>
                    )} */}
                    {sitecoreContext.pageEditing ? (
                        <RichText field={fields?.Headline} className="mt-8 font-medium text-3xl leading-[40px] text-black-300" />
                        ) : isPageTitle[0] ? (
                            <h1 className="mt-8 font-medium text-3xl leading-[40px] text-black-300">{fields?.Headline?.value}</h1>
                        ) : (
                            <h2 className="mt-8 font-medium text-3xl leading-[40px] text-black-300">{fields?.Headline?.value}</h2>
                    )}
                </div>
            </div>
            <div className="md:w-1/3 w-full md:block hidden"></div>
        </div>
        <div className="flex items-start md:flex-row flex-col gap-16 pb-12  showPrint hidden">
            <div className="bg-white w-full md:w-2/3 w-full leading-[32px]">
                <div className="max-w-[950px] w-full bottom-10 lg:bottom-20 text-start">
                    <RichText field={fields?.Headline} className="font-medium text-3xl leading-[40px] text-black-300" />
                </div>
            </div>
        </div>
    </div>
  );
};

export default withSitecoreContext()(WithBackButton);
