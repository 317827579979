import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ArabicIcon from '../Common/icons/ArabicIcon';
import EnglishIcon from '../Common/icons/EnglishIcon';

const LanguageSwitcher = (props) => {
  const { sitecoreContext } = props;
  const link = `${sitecoreContext.language === 'en' ? '/ar' : '/en'}${sitecoreContext.itemPath}`;

  const getClassName = () => {
    return props?.mode?.HeaderInDarkColor === '1' || props?.dark ? 'fill-black-300 hover:fill-red-500' : 'fill-white hover:fill-[#C4C7C9]';
  };

  if (typeof document !== 'undefined') {
    document.documentElement.lang = sitecoreContext?.language;
  }
  return (
    <a href={link}>
      {sitecoreContext.language === 'en' ? (
        <ArabicIcon className={getClassName() + ' 2xl:h-[26px] xl:h-[26px] lg:h-[21px] h-[18px]'} />
      ) : (
        <EnglishIcon className={getClassName() + ' 2xl:h-[26px] xl:h-[26px] lg:h-[21px] h-[18px]'} />
      )}
    </a>
  );
};

export default withSitecoreContext()(LanguageSwitcher);
