import React, { useRef, useState } from 'react';
import { EditFrame, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive';
import CTA from '../../../Common/CTA';
import dotWhite from '../../../../assets/icons/dot-white.svg';
import FormatedDate from '../../../../utils/FormatedDate';
import GetEditFrame from '../../../Common/EditFrame';
import Parser from 'html-react-parser';

const HeroBanner = ({ fields, sitecoreContext, bannerFields }) => {
  const settings = {
    fade: true,
    autoplay: true,
    autoplaySpeed: 7000,
    pauseOnHover: false,
    speed: 1000,
    cssEase: 'linear',
    // rtl: sitecoreContext?.language === 'ar' ? true : false,
  };

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const sliderRef = useRef(null);

  const [activeItem, setActiveItem] = useState(0);
  const components = sitecoreContext?.route?.placeholders['jss-main'];
  const isPageTitle = useState(components[0]?.fields?.SelectedItems[0]?.id === fields?.SelectedItems[0]?.id ? true : false);

  const handleSlideChange = (current, next) => {
    setActiveItem(next);
  };

  const formatedNumber = (number) => {
    return number < 10 ? '0' + number : '' + number;
  };

  let editFrameFields = ['Image', 'Title', 'Link', 'CTALink', 'MobileImage', 'CSSBackgroundImagePosition'];

  return (
    <div className="relative w-full banner">
      <Slider className="hero-banner" {...settings} arrows={false} animationHandler="fade" ref={sliderRef} beforeChange={handleSlideChange}>
        {fields?.SelectedItems.map((item, index) => (
          <div key={index} className="main_block" dir={sitecoreContext?.language === 'ar' ? 'rtl' : 'ltr'}>
            <EditFrame {...GetEditFrame(bannerFields, item.id)}>
              <div className="absolute w-full h-full top-0 left-0 bg-gradient-to-t from-[#01131d99] to-50%"></div>
              <div className="absolute w-full h-full top-0 left-0 bg-gradient-to-b from-[#01131d99] to-20%"></div>
              <div
                className={
                  (sitecoreContext.itemPath === '/' ? 'h-[100vh] ' : 'h-[80vh] ') +
                  'relative z-0 left-0 w-full object-cover object-top bg-no-repeat bg-cover flex items-end lg:pb-24 pb-10 after:absolute after:top-0 after:bottom-0 after:right-0 after:left-0 after:bg-gradient-to-t after:from-[#01131d99] after:to-50%'
                }
                style={{
                  backgroundImage: `url(${isMobile ? item?.fields?.MobileImage?.value?.src || item?.fields?.Image?.value?.src : item?.fields?.Image?.value?.src})`,
                  backgroundPosition: item?.fields?.CSSBackgroundImagePosition?.fields?.Value?.value || 'center',
                }}
              >
                <div className="container z-10">
                  <div className="lg:flex w-full align-revrse">
                    <div className="max-w-[950px] w-full text-start text-white">
                      {item?.fields?.Date?.value && item?.fields?.Date?.value !== '0001-01-01T00:00:00Z' && (
                        <div className="flex items-center gap-2">
                          <img src={dotWhite} alt="dot-date" />
                          <div className="text-white text-base font-medium" dir="ltr">
                            <FormatedDate date={item?.fields?.Date?.value} />
                          </div>
                        </div>
                      )}
                      {sitecoreContext.pageEditing ? (
                        <RichText field={item?.fields?.Title} className="font-medium text-white text-4xl" />
                      ) : (
                        <div>
                          {item?.fields?.Title.value && isPageTitle[0] && index === 0 ? (
                            <h1 className="font-medium text-white text-4xl">{Parser(item?.fields?.Title?.value)}</h1>
                          ) : (item?.fields?.Title?.value &&
                            <h2 className="font-medium text-white text-4xl">{Parser(item?.fields?.Title?.value)}</h2>
                          )}
                        </div>
                      )}
                      {sitecoreContext.pageEditing ? (
                        <RichText field={item?.fields?.Summary} className="font-medium md:leading-[40px] leading-[30px] text-xl mt-3" />
                      ) : !item?.fields?.Title.value && isPageTitle[0] && index === 0 ? (
                        <div>
                          <h1 className="font-medium md:leading-[40px] leading-[30px] text-xl mt-3">{Parser(item?.fields?.Summary?.value)}</h1>
                        </div>
                      ) : (item?.fields?.Summary?.value &&
                        <div>
                          <p className="font-medium md:leading-[40px] leading-[30px] text-xl mt-3">{Parser(item?.fields?.Summary?.value)}</p>
                        </div>
                      )}

                      {item?.fields?.Link?.value?.href && (
                        <div className="mt-3 w-fit">
                          <CTA field={item?.fields?.Link?.value} />
                        </div>
                      )}
                    </div>
                    {fields?.SelectedItems?.length > 1 && (
                      <div className="lg:w-1/3 w-full flex items-end">
                        <div className="w-fit ltr:ml-auto rtl:mr-auto">
                          <div className="flex text-base items-center gap-6 z-10 md:w-[288px] w-[200px]">
                            <div className="text-white">{formatedNumber(activeItem + 1)}</div>
                            <div className=" border-b-2 border-white h-0 grow"></div>
                            <div className="text-white">{formatedNumber(fields?.SelectedItems?.length)}</div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </EditFrame>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default withSitecoreContext()(HeroBanner);
