import React, { useState, useEffect, useRef } from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import minus from '../../assets/icons/minus.svg';
import plus from '../../assets/icons/plus.svg';

const Accordion = (props) => {
  const { open, title, order, index, onClick, children, isNormalFaq } = props;

  const descRef = useRef(null);

  const [descHeight, setDescHeight] = useState();

  useEffect(() => {
    if (descRef) {
      setDescHeight(descRef?.current?.scrollHeight);
    }
  }, [descRef]);

  return (
    <div
      onClick={() => onClick(index)}
      className={`cursor-pointer relative z-10 ${order === 'last' ? '' : 'border-b'} border-black-300/50 py-6 transition-all`}
    >
      <div className="flex items-center justify-between gap-4 w-full">
        <RichText field={title} className={'select-none text-base leading-[28px] font-bold text-black-300'} />
        <img className="" src={open ? minus : plus} />
      </div>
      <div className={`relative overflow-hidden transition-all duration-200 ${open ? 'mt-5 opacity-100' : 'mt-0 opacity-0 h-0'}`}>{children}</div>
    </div>
  );
};

export default Accordion;
