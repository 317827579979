import React from 'react';

import Default from './Default';
import WithBackButton from './WithBackButton';

const ContextualBanner = ({ fields, params }) => {
  const getComponents = (variant) => {
    switch (variant) {
      case 'WithBackButton':
        return <WithBackButton fields={fields}/>;
      default:
        return <Default fields={fields}/>;
    }
  };

  return getComponents(params?.Variant);
};

export default ContextualBanner;
