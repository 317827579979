import React from 'react';

const ArrowLeftIcon = (props) => {
  const { className, disabled, onClick, color } = props;

  return (
    <svg className={className} onClick={disabled ? null : onClick} width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Layer_1" clipPath="url(#clip0_10325_7615)">
        <path className="duration-200" fill={disabled ? '#DDD7D3' : color} id="Vector" d="M3.23094 12.83C4.33094 13.89 7.04094 16.54 9.69094 18.98L9.94094 19.21L10.1709 19.37C10.5409 19.6 10.6609 19.51 11.0109 19.26C11.5409 18.87 11.4709 18.96 11.8209 18.4C11.9609 18.18 12.0409 17.91 11.9809 17.65C11.9209 17.38 11.8609 17.3 11.6809 17.11C11.4809 16.92 9.25094 14.75 5.36094 10.54C5.36094 10.54 3.41094 8.32998 5.53094 7.03998C9.07094 4.88998 11.0709 4.30998 12.6109 3.49998C12.8709 3.35999 12.9809 3.17999 12.9809 3.09999C13.0109 2.91998 13.0409 2.55998 12.9409 2.25998C12.6809 1.49998 12.1309 1.14998 11.6109 0.549984C11.2809 0.159984 11.1709 -0.0100156 10.7309 0.239984C8.49094 1.47998 3.58094 4.00998 2.75094 4.48998C-0.839057 6.52998 0.930943 10.6 3.24094 12.84L3.23094 12.83Z" />
      </g>
      <defs>
        <clipPath id="clip0_10325_7615">
          <rect width="12.31" height="19.39" fill="white" transform="translate(13 19.5) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>

  )
};

export default ArrowLeftIcon;
