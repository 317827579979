import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import dot from '../../assets/icons/dot.svg';
import DictionaryText from './DictionaryText';
import FormatedDate from '../../utils/FormatedDate';

const NewsCard = (props) => {
  const { date, title, desc, link , sitecoreContext} = props;

  return (
    <a href={link}>
      <div className="group">
        <div className="flex gap-2 items-center">
          <img src={dot} className=" rounded-full w-[8.98px] h-[8.83px]" />
          <div className="text-base text-black-300 font-semibold" dir="ltr">
            <FormatedDate date={date} />
          </div>
        </div>
        {/* <RichText field={title} className="uppercase mt-2 leading-7 text-black-300 font-bold text-base" /> */}
        {sitecoreContext.pageEditing ? (
          <RichText field={title} className="uppercase mt-2 leading-7 text-black-300 font-bold text-base" />
        ) : (
          <h3 className="uppercase mt-2 leading-7 text-black-300 font-bold text-base"> {title?.value} </h3>
        )}
        <RichText field={desc} className="mt-2 text-base leading-7 text-black-300" />
        <div className="w-fit mt-4">
          <div className="text-black-300 duration-200 group-hover:text-red-500 uppercase text-sm mt-4 underline cursor-pointer">
            <DictionaryText dictionaryKey="read-more" />
          </div>
        </div>
      </div>
    </a>
  );
};

export default withSitecoreContext()(NewsCard);
