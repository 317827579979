import React from 'react';

const MinusIcon = (props) => {
  const { className, color } = props;

  return (
    <svg width="18" height="18" className={className} viewBox="0 0 18 4" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M2 2H16" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
};

export default MinusIcon;
