import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import Media from '../../../Common/Media';
import ThumbMedia from '../../../Common/ThumbMedia';
import ThumbMediaWithModal from '../../../Common/ThumbMediaWithModal';

const TwoColumnMedia = ({ fields }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <div className="container">
      <div className={'flex md:flex-row flex-col gap-6 md:py-24 py-[72px] bg-white'}>
        {fields.SelectedItems.map((item, index) => (
          <div className="md:w-1/2 w-full" key={index}>
            <ThumbMediaWithModal
              thumbnailImage={isMobile ? item?.fields?.MobileImage?.value?.src ? item?.fields?.MobileImage : item?.fields?.Image : item?.fields?.Image}
              title={item?.fields?.Title}
              // onClickThumbnail={() => onClickThumbnail(index)}
              isYouTubeVideo={item?.fields?.IsYoutubeVideo?.value}
              description={item?.fields?.Description}
              video={item?.fields?.Video?.value?.href}
              showCaption={true}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TwoColumnMedia;
