import React, { useRef, useState } from 'react';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Grid, Pagination } from 'swiper';
import NewsCard from '../../../Common/NewsCard';
import CTA from '../../../Common/CTA';
import ArrowLeftIcon from '../../../Common/icons/ArrowLeftIcon';
import ArrowRightIcon from '../../../Common/icons/ArrowRightIcon';

const Default = ({ fields, sitecoreContext }) => {
  const results = fields?.RelatedNews;

  const swiperRef = useRef(null);

  const [pagination, setPagination] = useState('');
  const [isEnd, setIsEnd] = useState(false);
  const [isBeginning, setIsBeginning] = useState(true);

  const [groupedItems, setGropedItems] = useState();

  const BREAK_POINTS = {
    300: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
    768: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
  };

  const toNext = () => {
    swiperRef.current.swiper.slideNext();
  };

  const toPrev = () => {
    swiperRef.current.swiper.slidePrev();
  };

  const onChangeSlider = (swiper) => {
    setPagination(swiper?.pagination?.el?.outerText);
    setIsEnd(swiperRef.current.swiper.isEnd);
    setIsBeginning(swiperRef.current.swiper.isBeginning);
  };

  const updateGroupItems = (size) => {
    let subGroup = []
    if (size === '300') {
      for (let i = 0; i < results?.length; i += 3) {
        subGroup.push(results?.slice(i, i + 3));
      };
    } else {
      for (let i = 0; i < results?.length; i += 1) {
        subGroup.push(results?.slice(i, i + 1));
      };
    }
    return subGroup;
  }

  return (
    <div className="py-24 bg-gray-300">
      <div className="container">
        <div className="w-full md:flex justify-between md:flex-row flex-col gap-6">
          <RichText field={fields?.Title} className="uppercase text-black-300 text-[26px] font-semibold" />
          <div className="flex items-center gap-14">
            <div className="md:flex hidden items-center gap-4">
              <div className="flex items-center gap-4">
                <ArrowLeftIcon
                  disabled={isBeginning}
                  onClick={toPrev}
                  className="rtl:rotate-180 cursor-pointer"
                  color="#010101"
                />
                <ArrowRightIcon
                  disabled={isEnd}
                  onClick={toNext}
                  className="rtl:rotate-180 cursor-pointer"
                  color="#010101"
                />
              </div>
              <div className="text-lg font-bold text-black-300">{pagination}</div>
            </div>
            <div className="w-fit md:mt-0 mt-6">{fields?.Link?.value?.href && <CTA field={fields?.Link?.value}></CTA>}</div>
          </div>
        </div>
        <div className="mt-8">
          <Swiper
            ref={swiperRef}
            dir={sitecoreContext?.language === 'ar' ? 'rtl' : 'ltr'}
            spaceBetween={0}
            allowTouchMove={true}
            touchMove={true}
            breakpoints={BREAK_POINTS}
            pagination={{
              type: 'fraction'
            }}
            modules={[Grid, Navigation, Pagination]}
            onInit={(swiper) => {
              const subGroup = updateGroupItems(swiper?.currentBreakpoint)
              setGropedItems(subGroup)
            }}
            onBreakpoint={(swiper) => {
              const subGroup = updateGroupItems(swiper?.currentBreakpoint)
              setGropedItems(subGroup)
            }}
            onSlideChange={onChangeSlider}
          >
            {groupedItems?.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="w-full ltr:md:pr-6 ltr:pr-0 rtl:md:pl-6 rtl:pl-0 mb-10">
                  <NewsCard
                    key={index}
                    date={item?.fields?.Date?.value}
                    title={item?.fields?.Headline}
                    desc={item?.fields?.Teaser}
                    link={item?.url}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="md:hidden flex mt-5 items-center gap-4">
          <div className="flex items-center gap-4">
            <ArrowLeftIcon
              disabled={isBeginning}
              onClick={toPrev}
              className="rtl:rotate-180 cursor-pointer"
              color="#010101"
            />
            <ArrowRightIcon
              disabled={isEnd}
              onClick={toNext}
              className="rtl:rotate-180 cursor-pointer"
              color="#010101"
            />
          </div>
          <div className="text-lg font-bold text-black-300">{pagination}</div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(Default);
