import React from 'react';
import { RichText, Image, Link } from '@sitecore-jss/sitecore-jss-react';

const Quotes = (props) => {
  const {OpenQuoteIcon, CloseQuoteIcon, ContentTags, Title, Description} = props;
  console.log('props', props)

  return (
    <div>
      <div className="container">
        <div className="lg:my-12 my-10 flex lg:flex-row flex-col lg:items-start items-center gap-14">
          <div className={'text-black-300 text-start w-full'}>
            <div className="relative">
              <Image field={OpenQuoteIcon} className="mb-4" />
              <RichText field={Description} className="text-base font-medium leading-[28px]" />
              <div className="flex justify-end my-4">
                <Image field={CloseQuoteIcon} className="flex justify-end" />
                <RichText field={Title} className="text-base font-bold leading-[28px] ml-3 rtl:mr-3" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quotes;

