import React from 'react';
import { RichText, Image } from '@sitecore-jss/sitecore-jss-react';

const TimelineCard = (props) => {
  const { image, index, title, desc, year, total } = props;

  return (
    <div
      className={`relative flex ${
        index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
      } py-6 flex-col-reverse md:items-center items-stretch md:gap-32 gap-6 md:px-[40px] lg:px-24 px-0`}
    >
      <div
        className={`md:block hidden absolute ltr:md:left-0 ltr:left-[27px] ltr:md:right-0  rtl:md:right-0 rtl:right-[27px] rtl:md:left-0 mx-auto ${
          index === total ? 'h-[50%] top-0' : 'h-full'
        } w-px border-[3.5px] border-red-500`}
      >
        <div className="relative h-full flex justify-center items-center">
          <div
            className={`absolute ${
              index === total ? 'bottom-[-1px]' : 'top-0 bottom-0'
            } my-auto bg-white w-14 h-14 text-center text-base font-semibold flex justify-center items-center flex-row border border-white text-red-500`}
          >
            {year}
          </div>
        </div>
      </div>
      <div className="text-black-300 md:w-1/2 w-full md:p-8 p-0 relative">
        <div className="text-xl leading-9 font-bold md:pr-0 ltr:pr-16 rtl:pl-16">{title}</div>
        <RichText field={desc} className="text-base mt-4 md:pr-0 ltr:pr-16 rtl:pl-16 font-medium leading-6" />
        <div
          className={`md:hidden ${
            index === total ? 'hidden h-[75%]' : 'block'
          } -top-12 absolute ltr:md:left-0 rtl:md:right-0 ltr:right-[20px] ltr:md:right-0 rtl:left-[20px] rtl:md:left-0 mx-auto h-[calc(100%_+_72px)] z-10 w-px md:border-[3.5px] border-[3px] border-red-500`}
        ></div>
      </div>
      <div className="md:w-1/2 w-full relative">
        <Image field={image} className="relative w-full object-cover object-center" />
        <div
          className={`md:hidden ${
            index === total ? 'h-[75%]' : 'h-full'
          } block -top-6 absolute ltr:md:left-0 rtl:md:right-0 ltr:right-[20px] ltr:md:right-0 rtl:left-[20px] rtl:md:left-0 mx-auto w-px md:border-[3.5px] border-[3px] border-red-500`}
        >
          <div className="relative h-full flex justify-center items-center">
            <div
              className={`absolute ${
                index === total ? 'bottom-[-1px]' : '-bottom-[100px]'
              } bg-white w-14 z-20 h-14 text-center flex text-base font-semibold justify-center items-center flex-row border border-white text-red-500`}
            >
              {year}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimelineCard;
