import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { RichText, Link, EditFrame, FieldEditButton, SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import GetEditFrame from '../../../Common/EditFrame';
import CTA from '../../../Common/CTA';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const WithLink = ({ fields, bannerFields,sitecoreContext }) => {
  const data = fields?.SelectedItems[0];
  const isMobile = useMediaQuery({ maxWidth: 768 });

  let datasource = data?.id;

  let editFrameButtons = GetEditFrame(bannerFields, datasource);

  const [hasTitle, setHasTitle] = useState(false);
  useEffect(() => {
    document.querySelector('h1') ? setHasTitle(true) : setHasTitle(false);
  }, []);

  return (
    <EditFrame {...editFrameButtons}>
      <div
        className="relative bg-no-repeat bg-cover min-h-[538px] banner"
        style={{
          backgroundImage: `url(${isMobile ? data?.fields?.MobileImage?.value?.src || data?.fields?.Image?.value?.src : data?.fields?.Image?.value?.src})`,
          backgroundPosition: data?.fields?.CSSBackgroundImagePosition?.fields?.Value?.value || 'center 25%',
        }}
      >
        <div className="absolute md:bg-gradient-to-r bg-gradient-to-t rtl:md:bg-gradient-to-l from-[#B9ABA0] from-20% to-50% to-[#B9ABA000] w-full h-full top-0 left-0 z-0"></div>
        <div className="container">
          {/* <div className={'md:py-44 py-20 text-black-300 flex md:flex-row flex-col items-start md:gap-16 gap-6 h-fit m-auto w-full relative z-50'}> */}
          <div className={'md:py-44 py-16 absolute z-30 bottom-0 max-w-[85%]'}>
            {/* <div className="md:w-1/4 w-full"></div> */}
            <div className="w-full text-white max-w-[455px]">
              {sitecoreContext.pageEditing ? (
                <RichText field={data?.fields?.Title} className="font-medium lg:text-2xl text-xl text-white lg:leading-[33px] leading-[30px]" />
              ) : hasTitle ? (data?.fields?.Title?.value &&
                <h2 className="font-medium lg:text-2xl text-xl text-white lg:leading-[33px] leading-[30px]">{data?.fields?.Title?.value}</h2>
              ) : (data?.fields?.Title?.value &&
                <h1 className="font-medium lg:text-2xl text-xl text-white lg:leading-[33px] leading-[30px]">{data?.fields?.Title?.value}</h1>
              )}
              {/* <RichText field={data?.fields?.Title} className="font-medium lg:text-2xl text-xl text-white lg:leading-[33px] leading-[30px]" /> */}
              <div className="flex mt-8 md:items-center items-start md:flex-row flex-col md:gap-8 gap-4">
                {data?.fields?.Link?.value?.href && (
                  <div className="w-fit">
                    <CTA field={data?.fields?.Link?.value} />
                  </div>
                )}
                {data?.fields?.CTALink?.value?.href && (
                  <div className="w-fit">
                    <CTA field={data?.fields?.CTALink?.value} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </EditFrame>
  );
};

export default withSitecoreContext()(WithLink);
