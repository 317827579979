import React from 'react';

import FullBleedBannerV1 from './FullBleedBannerV1';
import FullBleedBannerV2 from './FullBleedBannerV2';
import InPageImageTextBanner from './InPageImageTextBanner';
import Default from './Default';
import Contextual from './Contextual';
import WithLink from './WithLink';
import WithIconAndLink from './WithIconAndLink';
import Error404 from './Error404'
import DownloadPressRelease from './DownloadPressRelease'

const Banner = ({ fields, params }) => {
  let editFrameFields = ['Image', 'Title', 'Link', 'CTALink', 'MobileImage', 'CSSBackgroundImagePosition'];
  const getComponents = (variant) => {
    switch (variant) {
      case 'Default':
        return <Default fields={fields} params={params} bannerFields={editFrameFields}/>;
      case 'FullBleedBannerV1':
        return <FullBleedBannerV1 fields={fields} bannerFields={editFrameFields}/>;
      case 'FullBleedBannerV2':
        return <FullBleedBannerV2 fields={fields} bannerFields={editFrameFields}/>;
      case 'InPageImageTextBanner':
        return <InPageImageTextBanner fields={fields} bannerFields={editFrameFields}/>;
      case 'Context Content':
        return <Contextual params={params} />;
      case 'WithLink':
        return <WithLink fields={fields} bannerFields={editFrameFields}/>;
      case 'WithIconAndLink':
        return <WithIconAndLink fields={fields} bannerFields={editFrameFields}/>;
      case 'Error404':
        return <Error404 fields={fields} bannerFields={editFrameFields}/>;
      case 'DownloadPressRelease':
        return <DownloadPressRelease fields={fields} bannerFields={editFrameFields}/>;
      default:
        return <Default fields={fields} bannerFields={editFrameFields}/>;
    }
  };

  return getComponents(params?.Variant);
};

export default Banner;
