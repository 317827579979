import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Link, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import LanguageSwitcher from '../../../Common/LanguageSwitcher';
import TextAlign from '../../../../assets/icons/text-align.svg';
import Cursor from '../../../../assets/icons/cursor.svg';
import Contrast from '../../../../assets/icons/contrast.svg';
import ColorTheme from '../../../../assets/icons/color-theme.svg';
import FontFamily from '../../../../assets/icons/font-family.svg';
import FontSize from '../../../../assets/icons/font-size.svg';
import VerticalSeparator from '../../../../assets/icons/vertical-separator.svg';
import Close from '../../../../assets/icons/close.svg';
import UnderlineIcon from '../../../../assets/icons/header-underline.svg';
import SearchIcon from '../../../Common/icons/SearchIcon';
// import UnderlineIcon from '../../../Common/icons/UnderlineIcon';
import MenuIcon from '../../../Common/icons/MenuIcon';
import CloseIcon from '../../../Common/icons/CloseIcon';
import UseScrollBlock from '../../../Common/UseScrollBlock';
import LogoIcon from '../../../Common/icons/LogoIcon';
import AccessIcon from '../../../Common/icons/AccessIcon'
import Accessibility from '../../../Common/Accessibility';
import { useMediaQuery } from 'react-responsive'
import Reset from'../../../../assets/icons/reset.svg';
import HeaderLink from '../../../Common/HeaderLink';

const Header = ({ fields, params, sitecoreContext }) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const location = useLocation();
  const navigate = useNavigate();

  const [opened, setOpened] = useState(false);
  const [shown, setShown] = useState(false);
  const [lockScroll, unlockScroll] = UseScrollBlock();
  const openMenu = () => setOpened((state) => !state);
  const showAccessibility = () => setShown((state) => !state);
  const [position, setPosition] = useState(0)
  const [visible, setVisible] = useState(true)
  useEffect(() => {
    document.addEventListener('mousedown',(function(event) {
      if(!document.getElementById('accessibilityMenu').contains(event.target)){
        document.querySelector('#accessibilityMenu').classList.remove('h-fit');
        document.querySelector('#accessibilityMenu').classList.add('h-0');
        setShown(false)
      }
    }));
  })
  useEffect(() => {
    const handleScroll = () => {
      let moving = window.pageYOffset
      setVisible(position > moving);
      setPosition(moving)
    };
    window.addEventListener('scroll', handleScroll);
    return (() => {
      window.removeEventListener('scroll', handleScroll);
    })
  })

  const cls = position === 0 ? '' : visible ? 'visible-a' : 'hidden-a';

  useEffect(() => {
    if (opened) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [opened]);

  const openAccCloseNav = () => {
    setOpened(false);
    showAccessibility();
  }

  return (
    <div className="relative">
      <div id="myNav" className={`relative z-50 overlay bg-gray-100 ${opened ? 'lg:h-fit h-full' : 'h-0'}`}>
        <div className="container">
          <div className="lg:py-20 py-14 md">
            <div className="relative z-50 flex flex-col overflow-x-clip justify-between overflow-y-auto">
              <div className="flex lg:items-center items-start relative z-20 justify-between uppercase">
                <div className="flex flex-col lg:flex-row lg:items-center items-start relative z-20 w-full ">
                  <NavLink to={`${sitecoreContext.language}/`} className="lg:mb-0 mb-[56px]">
                    {/* <Image field={fields?.LogoDark} className="text-black-300 w-fit 2xl:h-[95px] xl:h-[80px] lg:h-[60px] h-14 font-extrabold" /> */}
                    <LogoIcon className="fill-[#8F8883] lg:h-[90px] h-[82px] lg:m-auto w-auto" />
                  </NavLink>
                  <img className="pl-[60px] rtl:pr-[60px] opacity-20 lg:block hidden" src={VerticalSeparator} />
                    {isMobile ? (
                      <>
                        <div className="relative h-fit text-start lg:pl-[60px] pl-0 flex flex-col 2xl:text-[36px] xl:text-[32px] lg:text-2xl sm:text-2xl text-[22px] 2xl:leading-[53px] xl:leading-[43px] lg:leading-[38px] leading-[22px] 2xl:gap-[44px] xl:gap-6 lg:gap-6 sm:gap-7 overflow-clip text-black-300">
                          {fields?.SecondaryNavigationItems.map((item, index) => (
                              <div className="group w-full pr-2 items-center gap-3 mb-[22px]" key={index}>
                                <Link
                                  field={item?.fields?.Link}
                                  className={`${item?.fields?.Link?.value?.href === location.pathname ? 'font-bold text-black-300 text-2xl' : 'text-2xl'} group-hover:text-black-300 text-[#8F8883] group-hover:font-bold group-hover:text-2xl transition-all mb-[22px] font-medium`}
                                />
                              <img src={UnderlineIcon} className={`${item?.fields?.Link?.value?.href === location.pathname ? 'block' : 'hidden'}  group-hover:hidden h-[6px] lg:my-[10px] mt-[10px]`} />
                              </div>
                          ))}
                        </div>
                      </>
                    ):(
                      <>
                        <div className="relative h-fit text-start lg:pl-[60px] pl-0 flex flex-row 2xl:text-[36px] xl:text-[32px] lg:text-2xl sm:text-2xl text-[22px] 2xl:leading-[53px] xl:leading-[43px] lg:leading-[38px] leading-[22px] 2xl:gap-[44px] xl:gap-6 lg:gap-6 sm:gap-7 overflow-clip text-black-300">
                          <div className="flex flex-col">
                            {fields?.SecondaryNavigationItems.slice(0, 4).map((item, index) => (
                              <div className="group w-full pr-2 items-center gap-3" key={index}>
                                <Link
                                  field={item?.fields?.Link}
                                  className={`${item?.fields?.Link?.value?.href === location.pathname ? 'font-bold text-black-300 text-xl' : 'text-xl'} group-hover:text-black-300 text-[#8F8883] group-hover:font-bold group-hover:text-xl transition-all`}
                                />
                                <Link
                                  field={item?.fields?.Link}
                                  className="font-bold text-black-300 text-xl opacity-0 mt-[-30px] block left-0 w-max"
                                />
                              <img src={UnderlineIcon} className={`${item?.fields?.Link?.value?.href === location.pathname ? 'visible' : 'invisible'}  group-hover:visible h-[6px]`} />
                              </div>
                            ))}
                          </div>
                          <div className="flex flex-col">
                            {fields?.SecondaryNavigationItems.slice(4, 7).map((item, index) => (
                              <div className="group w-full pr-2 items-center gap-3" key={index+4}>
                                <Link
                                  field={item?.fields?.Link}
                                  className={`${item?.fields?.Link?.value?.href === location.pathname ? 'font-bold text-black-300 text-xl' : 'text-xl'} group-hover:text-black-300 text-[#8F8883] group-hover:font-bold group-hover:text-xl transition-all`}
                                />
                                <Link
                                  field={item?.fields?.Link}
                                  className="font-bold text-black-300 text-xl opacity-0 mt-[-30px] block left-0 w-max"
                                />
                              <img src={UnderlineIcon} className={`${item?.fields?.Link?.value?.href === location.pathname ? 'visible' : 'invisible'}  group-hover:visible h-[6px]`} />
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                  <div className="relative lg:absolute right-0 rtl:right-auto rtl:left-0 lg:flex-row flex-col lg:items-end items-start h-fit z-20 justify-between flex pt-10 pb-10 lg:self-end self-start">
                    <div className="flex items-center h-fit gap-8 lg:py-4 py-0">
                      {fields?.SocialMediaLinks.map((item, index) => (
                        <Link field={item?.fields?.Link} key={index} className="shrink-0 group relative">
                          <Image className="h-[28px] group-hover:opacity-0 opacity-100 duration-500" field={item?.fields?.Icon} />
                          <Image className="absolute left-0 top-0 h-[28px] group-hover:opacity-100 opacity-0 duration-500" field={item?.fields?.IconLight} />
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex items-end self-start flex-col lg:gap-6 gap-4 absolute top-0 right-0 rtl:right-auto rtl:left-0 z-50">
                  <div className="flex items-center 2xl:gap-8 lg:gap-[32px] gap-5">
                    <NavLink to={`${sitecoreContext.language}/search`} onClick={() => setOpened(false)}>
                      <SearchIcon className={`${location.pathname.includes('search') ? 'stroke-red-500' : 'stroke-black-300'} hover:stroke-red-500 duration-300 2xl:h-[26px] xl:h-[26px] lg:h-[21px] h-[18px]`} />
                    </NavLink>
                    {/* <NavLink to={`${sitecoreContext.language}/`} onClick={() => setOpened(false)}>
                    <HomeIcon className={`${location.pathname === '/' ? 'stroke-red-500' : 'stroke-black-300 ' + 'hover:stroke-red-500 duration-300'} 2xl:h-[26px] xl:h-[26px] lg:h-[21px] h-[18px]`} />
                  </NavLink> */}
                    <LanguageSwitcher dark={true} />
                    <button className="flex" onClick={openAccCloseNav}>
                      <AccessIcon
                        className="text-black-500 group-hover:text-red-500 duration-300 2xl:h-[26px] xl:h-[26px] lg:h-[21px] h-[18px]"
                      />
                    </button>
                    <button onClick={openMenu}>
                      <CloseIcon className="hover:fill-red-500 fill-black-300 duration-300 2xl:h-[26px] xl:h-[26px] lg:h-[21px] h-[18px]" />
                    </button>
                  </div>
                  <img src={UnderlineIcon} className="w-[100%]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={` bg-black-300 opacity-40 z-40 overlay ${opened ? 'h-full' : 'h-0'}`} onClick={() => setOpened(false)}>
      </div>
      <div id="accessibilityMenu" className={`relative z-50 accessibility bg-gray-100 right-[32px] rtl:left-[32px] ${shown ? 'h-fit' : 'h-0'}`}>
        <div className="bg-sans-500 h-[69px] p-6 flex justify-between" >
          <p className="text-white uppercase font-bold text-sm pt-[6px]">
            {sitecoreContext?.language === 'ar' ? 'قائمة الوصول' : 'Accessibility Menu'}
          </p>
          <Accessibility></Accessibility>
          <button className="h-[27px]" onClick={showAccessibility}>
            <img className="h-[27px]" src={Close} />
          </button>
        </div>
        <div className="container">
          <div className="py-6">
            <div className="relative z-50 flex flex-col overflow-x-clip justify-between overflow-y-auto">
              <div className="accessibility-tool">
                <div className="accessibility-tool__wrap">
                  <div className="text">
                    <h6 className="font-semibold text-xl mb-[8px]">{sitecoreContext?.language === 'ar' ? 'النص' : 'Text'}</h6>
                    <div className="flex flex-wrap">
                      <div className="setAccessibility py-[10px] px-[8px] hover:bg-white hover:cursor-pointer mt-2"  data-accessibility="fontSize">
                        <img src={FontSize} className="inline mx-[8px]"></img>
                        <span className="text-sm font-medium">{sitecoreContext?.language === 'ar' ? 'حجم الخط' : 'Font Size'}</span>
                      </div>
                      <div className="setAccessibility py-[10px] px-[8px] hover:bg-white hover:cursor-pointer mt-2" data-accessibility="fontFamily">
                        <img src={FontFamily} className="inline mx-[8px]"></img>
                        <span className="text-sm font-medium">{sitecoreContext?.language === 'ar' ? 'غرته آرابك آر + لت' : 'Greta Arabic AR+LT'}</span>
                      </div>
                      <div className="setAccessibility py-[10px] px-[8px] hover:bg-white hover:cursor-pointer mt-2"  data-accessibility="alignment">
                        <img src={TextAlign} className="inline mx-[8px]"></img>
                        <span className="text-sm font-medium">{sitecoreContext?.language === 'ar' ? 'محاذاة النص' : 'Text Align'}</span>
                      </div>
                    </div>
                  </div>
                  <hr className="m-[16px]"/>
                  <div className="visualassistance">
                    <h6 className="font-semibold text-xl mb-[8px]">{sitecoreContext?.language === 'ar' ? 'المساعدة البصرية' : 'Visual Assistance'}</h6>
                    <div className="grid grid-cols-2">
                      <div className="setAccessibility py-[10px] px-[8px] hover:bg-white hover:cursor-pointer mt-2" data-accessibility="contrast">
                        <img src={Contrast} className=" inline mx-[8px]"></img>
                        <span className="text-sm font-medium">{sitecoreContext?.language === 'ar' ? 'التباين' : 'Contrast'}</span>
                      </div>
                      <div className="setAccessibility py-[10px] px-[8px] hover:bg-white hover:cursor-pointer mt-2"  data-accessibility="cursor">
                        <img src={Cursor} className=" inline mx-[8px]"></img>
                        <span className="text-sm font-medium">{sitecoreContext?.language === 'ar' ? 'المؤشر' : 'Cursor'}</span>
                      </div>
                    </div>
                  </div>
                  <hr className="m-[16px]"/>
                  <div className="colour">
                    <h6 className="font-semibold text-xl mb-[8px]">{sitecoreContext?.language === 'ar' ? 'اللون' : 'Colour'}</h6>
                    <div className="grid grid-cols-2">
                      <div className="setAccessibility py-[10px] px-[8px] hover:bg-white hover:cursor-pointer mt-2" data-accessibility="darkMode">
                        <img src={ColorTheme} className="inline mx-[8px]"></img>
                        <span className="text-sm font-medium">{sitecoreContext?.language === 'ar' ? 'سمة اللون' : 'Color Theme'}</span>
                      </div>
                    </div>
                  </div>
                  <hr className="m-[16px]"/>
                  <div className="text-center">
                    <button type="button" className="setAccessibility mt-1 w-[163px] text-center px-7 py-3.5 rounded-l rounded-r-md leading-none bg-red-500 hover:bg-red-600 duration-300 font-bold active:bg-gray-400 active:text-red-500 text-white" data-accessibility="reset">
                      <img src={Reset} className="inline mx-[4px]"></img>
                      <span className="mx-[7px]" >{sitecoreContext?.language === 'ar' ? 'إعادة تعيين' : 'Reset'}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={` bg-black-300 opacity-40 z-40 overlay ${shown ? 'h-full' : 'h-0'}`} onClick={() => setShown(false)}>
      </div> */}
      <div className={`fixed ${params?.HeaderInDarkColor === '1' ? 'bg-white' : 'bg-gradient-to-b from-[#00000080] from-0% to-transparent'} z-[35] top-0 w-full ${opened ? '' : cls}`}>
        <div className="container">
          <div className="py-6 flex items-center justify-between">
            <NavLink to={`${sitecoreContext.language}/`} onClick={() => setOpened(false)}>
              {/* <Image field={params?.HeaderInDarkColor === '1' ? fields?.LogoDark : fields?.Logo} className="text-white w-fit 2xl:h-[95px] xl:h-[80px] lg:h-[60px] h-14 font-extrabold" /> */}
              <LogoIcon className={`h-[96px] ${params?.HeaderInDarkColor === '1' ? 'fill-black-300' : 'fill-white'}`} />
            </NavLink>
            <div className="flex items-center text-base lg:justify-between justify-end grow">
              <div className="lg:flex hidden items-center flex-wrap grow justify-evenly">
                {fields?.PrimaryNavigationItems.map((item, index) => (
                  <HeaderLink
                    variant={params?.HeaderInDarkColor === '1' ? 'primary' : 'secondary'}
                    field={item?.fields?.Link?.value}
                    active={location.pathname.includes(item?.fields?.Link?.value?.href)}
                    key={index}
                  />
                ))}
              </div>
              <div className="flex items-center 2xl:gap-8 lg:gap-[32px] gap-5">
                <NavLink to={`${sitecoreContext.language}/search`} onClick={() => setOpened(false)} className="group transition-all duration-500 invisible md:visible">
                  <SearchIcon
                    width={32}
                    height={32}
                    className={`${location.pathname.includes('search')
                      ? 'stroke-black-300'
                      : params?.HeaderInDarkColor === '1'
                        ? 'stroke-black-300 group-hover:stroke-red-500 duration-300'
                        : 'stroke-white group-hover:stroke-[#C4C7C9] duration-300'
                      } duration-300 2xl:h-[26px] xl:h-[26px] lg:h-[21px] h-[18px]`}
                  />
                </NavLink>
                {/* <NavLink to={`${sitecoreContext.language}/`} className="group">
                  <HomeIcon
                    className={`${
                      sitecoreContext?.itemPath === '/ar' || sitecoreContext?.itemPath === '/en' || sitecoreContext?.itemPath === '/'
                        ? 'stroke-black-300'
                        : params?.HeaderInDarkColor === '1'
                          ? 'stroke-black-300 hover:stroke-red-500 duration-300'
                          : 'stroke-white hover:stroke-[#C4C7C9] duration-300'
                    } 2xl:h-[26px] xl:h-[26px] lg:h-[21px] h-[18px]`}
                  />
                </NavLink> */}
                <div className="invisible md:visible">
                  <LanguageSwitcher mode={params} />
                </div>
                <button className="flex invisible md:visible" onClick={showAccessibility}>
                  <AccessIcon
                    className={params?.HeaderInDarkColor === '1' ? 'text-black-500  group-hover:fill-red-500' : 'text-white' + ' group-hover:text-[#C4C7C9] duration-300 2xl:h-[26px] xl:h-[26px] lg:h-[21px] h-[18px]'}
                  />
                </button>
                <button onClick={openMenu} className="group transition-all duration-500">
                  <MenuIcon width={25} height={25} className={params?.HeaderInDarkColor === '1' ? 'text-black-300 group-hover:text-red-500 duration-300' : 'text-white group-hover:text-[#C4C7C9] duration-300 2xl:h-[26px] xl:h-[26px] lg:h-[21px] h-[18px]'} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(Header);

