import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { RichText, Image, Link, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import dot from '../../../../assets/icons/dot.svg';
import CTA from '../../../Common/CTA';

const TwoColumnsWithHeadingAndImage = ({ fields, params }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const dark = params?.BackgroundColor && params?.BackgroundColor !== 'white';
  const { sitecoreContext } = useSitecoreContext();

  return (
    <div className={`${params?.BackgroundColor ? 'bg-' + params?.BackgroundColor : 'bg-white'} ${dark ? 'text-white' : 'text-black-300'} w-full py-20`}>
      <div className="container">
        <img src={dot} className="w-[9px] inline mr-[9.5px] rtl:ml-[9.5px] rtl:mr-0" />
        {sitecoreContext.pageEditing ? (
          <RichText field={fields?.Title} className="text-[22px] font-semibold leading-10 text-4xl inline" />
        ) : (
          fields?.Title?.value && <h2 className="text-[22px] font-semibold leading-10 text-4xl inline">{fields?.Title?.value}</h2>
        )}
        <div className="relative mt-8 flex items-start md:flex-row flex-col">
          <div className="text-start lg:w-7/12 w-full">
            {fields?.Header && <RichText field={fields?.Header} className="text-lg font-bold mb-6" />}
            <RichText field={fields?.Description} className="text-lg leading-8" />
            <div className="w-fit mt-6">
              <CTA field={fields?.Link?.value} />
            </div>
          </div>
          <div className="md:w-1/12"></div>
          <div className="md:w-4/12 w-full md:mt-0 mt-5 ">
            <Image field={isMobile ? (fields?.MobileImage?.value?.src ? fields?.MobileImage : fields?.Image) : fields?.Image} className="object-cover" />
            <RichText className="mt-4 text-base leading-6 font-medium" field={fields?.ImageCaption} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoColumnsWithHeadingAndImage;
