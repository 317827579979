import React from 'react';

const EnglishIcon = (props) => {
  const { color, className } = props;

  return (
    <svg className={`${className} duration-200`} xmlns="http://www.w3.org/2000/svg" width="33" height="20" viewBox="0 0 33 20" fill="none">
      <path d="M16.2975 19.5V0.5H19.5036L28.995 13.1775H29.0585V0.5H32.5186V19.5H29.2807L19.7893 6.49657H19.7258V19.5H16.2975Z" fill={color} />
      <path d="M0.519043 19.5V0.5H12.4547V3.46569H4.20133V8.38679H10.9945V11.2547H4.20133V16.5343H12.6769V19.5H0.519043Z" fill={color} />
    </svg>
  )
};

export default EnglishIcon;
