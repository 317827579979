import React from 'react';
import { useMediaQuery } from 'react-responsive'
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';

const ImageInCircle = ({ fields, params }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div className={`bg-${params?.BackgroundColor}`}>
      <div className="container">
        <div
          className={`flex items-start ${
            fields?.ImagePosition?.value ? (fields?.ImagePosition?.value === 'Right' ? 'md:flex-row-reverse' : 'md:flex-row') : 'md:flex-row'
          } flex-col gap-16 md:py-24 py-14`}
        >
          <div className="md:w-1/3 w-full">
            <Image field={isMobile ? fields?.MobileImage?.value?.src ? fields?.MobileImage : fields?.Image : fields?.Image} className="rounded-full w-[140px] h-[140px]" />
            <RichText field={fields?.Title} className="md:w-1/2 mt-4 text-black-300 text-[26px] font-semibold leading-[45px]" />
          </div>
          <div className="md:w-2/3 w-full">
            <RichText field={fields.Header} className="text-black-300 text-2xl leading-[43px] font-semibold" />
            <RichText field={fields.Description} className="text-black-300 text-lg leading-[32px] mt-6" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageInCircle;
