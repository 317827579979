import React, { useState } from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { useMediaQuery } from 'react-responsive'
import CenteredCarousel from '../../../Common/CenteredCarousel';
import CarouselModal from '../../../Common/CarouselModal';
import ThumbMedia from '../../../Common/ThumbMedia';

const ThreeColumnMedia = ({ fields, params }) => {
  const items = fields?.SelectedItems;
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [openMediaModal, setOpenMediaModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const onClickThumbnail = (index) => {
    setOpenMediaModal(true);
    setActiveIndex(index);
  };

  const onCloseMediaModal = () => {
    setOpenMediaModal(false);
  };

  return (
    <div className="md:px-24 px-6 md:py-24 py-16 flex items-center gap-6 md:flex-row flex-col">
      {items.map((item, index) => (
        <div key={index} className="md:w-1/3 h-[268px] w-full">
          <ThumbMedia
            onClickThumbnail={() => onClickThumbnail(index)}
            thumbnailImage={isMobile ? item.fields.MobileImage?.value?.src ? item.fields.MobileImage : item.fields.Image : item.fields.Image}
            video={item?.fields?.Video.value.href}
            title={item?.fields?.Title}
            description={item?.fields?.Description}
            showCaption={true}
            NoOverlappingText={true}
          />
          <RichText field={item?.fields?.Summary} className="text-lg mt-4 text-black-300" />
        </div>
      ))}
      <CarouselModal open={openMediaModal} onCloseModal={onCloseMediaModal}>
        {/* {video && isYoutubeVideo && (
          <div className="w-full">
            <iframe
              src={video}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full h-[500px] rounded-2xl"
            />
            <RichText className="text-white mt-6 mb-4 text-2xl text-start" field={title} />
            <RichText className="text-white text-lg text-start" field={description} />
          </div>
        )}
        {video && !isYoutubeVideo && (
          <div className="w-full h-[510px]">
            <video onLoadedData={onLoaded} ref={videoRef} controls className="w-full h-full rounded-2xl bg-gray-300">
              <source src={video} type="video/mp4"></source>
            </video>
            <RichText className="text-white mt-6 mb-4 text-2xl text-start" field={title} />
            <RichText className="text-white text-lg text-start" field={description} />
          </div>
        )}
        {!video && (
          <div className="w-full">
            <Image className="w-full h-[510px] object-cover rounded-2xl" field={thumbnailImage} />
            <RichText className="text-white mt-6 mb-4 text-2xl text-start" field={title} />
            <RichText className="text-white text-lg text-start" field={description} />
          </div>
        )} */}
        <div className="w-full bg-transparent">
          <CenteredCarousel data={items} defaultIndex={activeIndex} />
        </div>
      </CarouselModal>
    </div>
  );
};

export default ThreeColumnMedia;
