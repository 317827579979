/**
 * Returns an object representing an edit frame.
 *
 * @param {Array} fields - The fields to be edited.
 * @param {string} datasource - The datasource to be used.
 * @returns {Object} The edit frame object.
 */
function getEditFrame(fields, datasource) {
  if (!Array.isArray(fields)) {
    throw new Error('fields must be an array');
  }

  if (datasource && typeof datasource !== 'string') {
    throw new Error('datasource must be a string');
  }

  const editFrameButtons = [
    {
      header: 'FieldEditButton',
      icon: '/~/icon/Office/16x16/pencil.png',
      fields,
      tooltip: 'Edit fields that cannot be edited in the Experience Editor directly',
    },
  ];

  return {
    dataSource: datasource
      ? {
          itemId: datasource,
        }
      : undefined,
    buttons: editFrameButtons,
    title: 'Edit more',
    tooltip: 'Perform editing anywhere while not tied to a rendering, placeholder or field',
    cssClass: 'jss-edit-frame',
    parameters: {},
  };
}

export default getEditFrame;
