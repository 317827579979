import React from 'react';
import { RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import dot from '../../assets/icons/dot.svg';
import locationPin from '../../assets/icons/location.svg';
import DictionaryText from './DictionaryText';
import FormatedDate from '../../utils/FormatedDate';

const NewsCardWithImage = (props) => {
  const { date, location, title, desc, link, image , sitecoreContext } = props;

  return (
    <a href={link}>
      <div className="w-full block h-fit group">
        <div className="lg:h-[294px] h-[316px] overflow-clip">
          <img src={image} className="mb-8 w-full h-full group-hover:scale-105 transition-all duration-500 object-cover object-center" />
        </div>
        {/* <h3 className="uppercase mt-8 leading-7 text-black-300 font-semibold text-2xl">{title?.value}</h3> */}
        {sitecoreContext.pageEditing ? (
          <RichText field={title} className="uppercase mt-8 leading-7 text-black-300 font-semibold text-2xl" />
        ) : (
          <h3 className="uppercase mt-8 leading-7 text-black-300 font-semibold text-2xl">{title?.value}</h3>
        )}
        {/* { desc && <RichText field={desc} className="mt-2 text-base leading-7 text-black-300" />} */}
        <div className="gap-2 items-center mt-2 mdd:flex block">
        {date &&
          <div className="flex gap-2 items-center">
            <img src={dot} className=" rounded-full w-[8.98px] h-[8.83px]" />
            <div className="text-base text-black-300 font-light">
              <FormatedDate date={date} />
            </div>
          </div>
        }
        { date && location && <span className="mdd:inline hidden">|</span>}
        {location &&
          <div className="flex gap-2 items-center">
            <img src={locationPin} className=" rounded-full h-[18px]" />
            <div className="text-base text-black-300 font-medium">
                {location}
            </div>
          </div>
        }
        </div>
        <div className="w-fit mt-4">
          <div className="text-black-300 duration-200 group-hover:text-red-500 uppercase text-sm mt-4 underline cursor-pointer">
            <DictionaryText dictionaryKey="read-more" />
          </div>
        </div>
      </div>
    </a>
  );
};

export default withSitecoreContext()(NewsCardWithImage);
