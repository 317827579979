import React, { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import { RichText, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import RightIconLight from '../../../../assets/icons/arrow-right-light-svg.svg';
import LeftIconLight from '../../../../assets/icons/arrow-left-light-svg.svg';
import CarouselModal from '../../../Common/CarouselModal';
import CenteredCarousel from '../../../Common/CenteredCarousel';
import video_play from '../../../../assets/icons/video_play.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Thumbs } from 'swiper';
import 'swiper/css';
import CTA from '../../../Common/CTA';

const FullWidthNoThumbnail2 = (props) => {
  const { fields, params, sitecoreContext } = props;
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const lang = sitecoreContext.language;
  const data = fields?.SelectedItems?.length > 0 ? fields?.SelectedItems : null;

  const [activeItem, setActiveItem] = useState(0);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const handleSlideChange = (swiper) => {
    setActiveItem(swiper.realIndex);
  };

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '300px',
    slidesToShow: 1,
    speed: 300,
    adaptiveHeight: false,
    slidesPerView: 'auto',
    touchMove: true,
    arrows: false,
    beforeChange: handleSlideChange,
    rtl: lang === 'ar' ? true : false,

    responsive: [
      {
        breakpoint: 2000,
        settings: {
          centerPadding: '100px',
        },
      },
      {
        breakpoint: 1440,
        settings: {
          centerPadding: '100px',
        },
      },
      {
        breakpoint: 1280,
        settings: {
          centerPadding: '100px',
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: '100px',
        },
      },
      {
        breakpoint: 768,
        settings: {
          touchMove: true,
          centerPadding: '40px',
        },
      },
    ],
  };

  const [openMediaModal, setOpenMediaModal] = useState(false);

  const sliderRef = useRef(null);
  const thumbsRef = useRef(null);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const rightArrowRef = useRef(null);
  const leftArrowRef = useRef(null);

  const onNext = () => {
    sliderRef?.current?.slideNext();
  };

  const onPrev = () => {
    sliderRef?.current?.slidePrev();
  };

  const onCloseMediaModal = () => setOpenMediaModal(false);

  const onClick = (index) => {
    if (index === activeItem) {
      setOpenMediaModal(true);
      setActiveItem(index);
    } else {
      sliderRef.current.slideTo(index);
    }
  };

  const handleClickItem = () => {
    if (sliderRef.current && sliderRef.current) {
      const activeIndex = sliderRef.current.activeIndex;

      if (activeIndex === sliderRef.current.clickedIndex) {
        // Clicked on the centered item
        setOpenMediaModal(true);
        setActiveItem(sliderRef.current.realIndex);
      } else if (activeIndex < sliderRef.current.clickedIndex) {
        // Clicked on the right item
        rightArrowRef.current.click();
      } else {
        // Clicked on the left item
        leftArrowRef.current.click();
      }
    }
  };

  return (
    <div id="" dir={lang === 'ar' ? 'rtl' : 'ltr'} className={`print w-full relative bg-${params?.BackgroundColor} ${data? 'md:py-[120px] py-[64px]' : ''}`}>
      <div className="w-full h-full hidePrint" dir="ltr">
        {' '}
        {/** dir="ltr" SHOULD BE KEEPED IN THIS LINE EVEN IN ARABIC PAGE, IF NOT STYLE BROKEN */}
        {(fields?.Title?.value || fields?.Link?.href) && <div className="w-full md:flex justify-between md:flex-row flex-col gap-6 container mb-[60px] rtl:direction-rtl">
          {/* <RichText field={fields?.Title} className="uppercase text-black-300 text-[30px] font-semibold" /> */}
          {props.sitecoreContext.pageEditing ? (
            <RichText field={fields?.Title} className="uppercase text-black-300 text-[30px] font-semibold" />
          ) : (
            <h2 className="uppercase text-black-300 text-[30px] font-semibold" >{fields?.Title?.value}</h2>
          )}
          <div className="w-fit md:mt-0 mt-6">{fields?.Link?.value?.href && <CTA field={fields?.Link?.value}></CTA>}</div>
        </div>}
        <Swiper
          className="Gallery_Default_swiper_2 mx-auto w-full max-w-[1560px] centered-carousel"
          centeredSlides={true}
          slidesPerView={'auto'}
          thumbs={{ swiper: thumbsSwiper }}
          onClick={handleClickItem}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
          onSwiper={(swiper) => {
            sliderRef.current = swiper;
          }}
          loop={true}
          onSlideChange={handleSlideChange}
          modules={[Pagination, Thumbs]}
          pagination={{
            clickable: true,
          }}
          ref={sliderRef}
        >
          {data?.map((item, index) => (
            <SwiperSlide
              className="w-[80%] border-[12px] border-transparent max-w-[1225px] md:h-[612px] h-[216px] flex items-center"
              key={index}
            >
              <div className="w-full transition-all h-full img-container">
                <Image field={isMobile ? item?.fields?.MobileImage?.value?.src ? item?.fields?.MobileImage : item?.fields?.Image : item?.fields?.Image} className="w-full h-full object-cover cursor-pointer" />
                {item?.fields?.Video?.value?.href && <img src={video_play} alt="video_play" className="cursor-pointer absolute w-[90px] h-[90px] left-0 right-0 bottom-0 top-0 m-auto" />}
              </div>
            </SwiperSlide>
          ))}
        <div className="flex text-white justify-between absolute z-10 sm:top-[52%] md:top-[48%] top-[46%] md:left-[5%] left-[1%] md:right-[5%] right-[1%] mx-auto max-w-[1350px]">
          <img src={LeftIconLight} ref={leftArrowRef} onClick={onPrev} className="cursor-pointer" />
          <img src={RightIconLight} ref={rightArrowRef} onClick={onNext} className="cursor-pointer" />
        </div>
        </Swiper>
      </div>
      <div className="flex items-start flex-col mt-8 gap-[30px] w-[80%] max-w-[1225px] mx-auto px-[55px] hidePrint">
        <div className="w-full">
          <RichText className="w-full text-black-300 font-medium" field={data ? data[activeItem]?.fields?.Title : undefined} />
        </div>
        <div className={`text-black-300 font-bold flex items-center h-full text-lg select-none ${data ? '' : 'show-only-expEditor'}`}>
            {activeItem + 1} / {data?.length}
        </div>
      </div>
      <CarouselModal open={openMediaModal} onCloseModal={onCloseMediaModal}>
        <div className="w-full bg-transparent hidePrint">
          <CenteredCarousel data={data} defaultIndex={activeItem} />
        </div>
      </CarouselModal>
      <div className="container grid grid-cols-1 md:grid-cols-2 gap-8 showPrint hidden">
          {
          data?.map((item, index) => (
            <div className="" key={index}>
              <img src={isMobile ? item?.fields?.MobileImage?.value?.src ? item?.fields?.MobileImage?.value?.src : item?.fields?.Image?.value?.src : item?.fields?.Image?.value?.src} className="w-full h-full object-cover cursor-pointer" />
              {/* <RichText className="w-full text-black-300 font-medium" field={item?.fields?.Title} /> */}
            </div>
          ))
          }
      </div>
    </div>
  );
};

export default withSitecoreContext()(FullWidthNoThumbnail2);

