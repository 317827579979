import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { RichText, Image,withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../../../lib/GraphQLData';
import { isMobile } from 'react-device-detect';
import Pill from '../../../Common/Pill';
import DictionaryText from '../../../Common/DictionaryText';
import Button from '../../../Common/Button';
import SearchIcon from '../../../Common/icons/SearchIcon';
import DatePicker from 'react-datepicker';
import calender from '../../../../assets/icons/calender.svg';
import arrowDownDark from '../../../../assets/icons/arrow-down-dark.svg';
import arrowUpDark from '../../../../assets/icons/arrow-up-dark.svg';
import NewsCardWithImage from '../../../Common/NewsCardWithImage';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import { enUS, ar } from 'date-fns/locale';
import Reset from'../../../../assets/icons/reset.svg';

registerLocale('ar', ar);
registerLocale('enUS', enUS);

const gqlQuery = gqlLoader('./newslisting.graphql');

const WithFilter = (props) => {
  const [searchParams] = useSearchParams();
  const q = searchParams.get('q') || '';
  const dateStart = searchParams.get('dateFrom') || '';
  const dateEnd = searchParams.get('dateTo') || '';
  const tag = searchParams.get('tag') || '';
  const GQL = GraphQLData(gqlQuery, {
    name: 'gqlQuery',
    options: {
      variables: {
        datasource: props?.rendering?.dataSource,
        pageSize: isMobile ? 6 : 9,
        itemPerPage: isMobile ? 6 : 9,
        skip: '0',
        tag: tag,
        language: 'en',
        dateFrom: dateStart,
        dateTo: dateEnd,
        keyword: q,
      },
    },
  })(ConnectedGQLResults.bind(props));

  return <GQL {...props} />;
};

const ConnectedGQLResults = (props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { sitecoreContext } = props;
  const [pageSize, setPageSize] = useState(isMobile ? 6 : 9);
  const [itemPerPage] = useState(isMobile ? 6 : 9);
  const [tag, setTag] = useState('All');
  const [buttonText, setButtonText] = useState('load-more');
  const [value, setValue] = useState('');
  const [selectedKeyword, setSelectedKeyword] = useState(initialKeyword);
  const selectedDateStart = searchParams.get('dateFrom') || '';
  const selectedDateEnd = searchParams.get('dateTo') || '';
  const selectedTag = searchParams.get('tag') || '';
  const [dateStart, setDateStart] = useState(selectedDateStart);
  const [dateEnd, setDateEnd] = useState(selectedDateEnd);
  const [open, setOpen] = useState(false);
  const initialKeyword = searchParams.get('q') || '';
  const [q, setQ] = useState(initialKeyword);
  function onChangeHandler(value) {
      setDateStart(value[0]);
      setDateEnd(value[1]);
  }
  const onOpen = () => {
    setOpen((state) => !state);
  };
  const dateFormate = (date)=>{
    if(date){
      let day = date.getDate();
      let month = date.getMonth()+1;
      let year = date.getFullYear();
      if (day < 10) {
        day = '0' + day;
      }
      if (month < 10) {
        month = `0${month}`;
      }
      let format1 = year + '-' + month + '-' + day;
      return(format1)
    }
  }
  const closeAndNavigate = () =>{
    setOpen((state) => !state);
    onFilter(tag ? tag : 'All', dateStart ? dateFormate(dateStart) : null, dateEnd ? dateFormate(dateEnd) : null, q ? q : null)
    var newurl = `?${queryString.stringify({ dateFrom:dateFormate(dateStart) , dateTo:dateFormate(dateEnd), tag: tag, q, page: 1}, { skipNull: true, skipEmptyString: true })}`;
    window.history.pushState({path:newurl},'',newurl);
    // navigate(`?${queryString.stringify({ dateFrom:dateFormate(dateStart) , dateTo:dateFormate(dateEnd), tag: tag, q, page: 1}, { skipNull: true, skipEmptyString: true })}`);
  }
  const results = props?.gqlQuery?.GQLResults?.results?.items;
  const totalCount = props?.gqlQuery.GQLResults?.results?.totalCount;
  const tags = props?.fields?.data?.Component?.contentTags?.targetItem?.children;
  const formatedTags = tags?.map((item) => ({
    id: item.id,
    label: item.name,
    displayName: item?.display_name?.value,
  }));
  const title = props?.fields?.data?.Component?.title;
  const { error, loading, refetch } = props.gqlQuery;

  const components = sitecoreContext?.route?.placeholders['jss-main'];
  const isPageTitle = useState(components[0]?.fields?.data?.Component?.title === title ? true : false);

  useEffect(() => {
    results?.length < totalCount ? setButtonText('load-more') : setButtonText('load-less');
  }, [results]);
  useEffect(() => {
    setQ(initialKeyword);
  }, []);

  const onSearchChange = (e) => {
    setQ(e.target.value);
  };

  const onSubmit = () => {
    const startDate = selectedDateStart? selectedDateStart: dateFormate(dateStart)
    const endDate = selectedDateEnd? selectedDateEnd: dateFormate(dateEnd)
    onFilter(tag ? tag : 'All', startDate, endDate, q)
    var newurl = `?${queryString.stringify({ dateFrom:startDate , dateTo:endDate, tag: tag, q, page: 1}, { skipNull: true, skipEmptyString: true })}`;
    window.history.pushState({path:newurl},'',newurl);
    // if (q) {
      // tag ? navigate(`?${queryString.stringify({ q, page: 1 , tag: tag}, { skipNull: true, skipEmptyString: true })}`):navigate(`?${queryString.stringify({ q, page: 1}, { skipNull: true, skipEmptyString: true })}`);
    // }
  };

  const onTagChange = (recentTag) => {
    const startDate = selectedDateStart? selectedDateStart: dateFormate(dateStart)
    const endDate = selectedDateEnd? selectedDateEnd: dateFormate(dateEnd)
    onFilter(recentTag, startDate, endDate, q ? q : null)
    var newurl = `?${queryString.stringify({ dateFrom: startDate , dateTo: endDate, tag: recentTag, q, page: 1}, { skipNull: true, skipEmptyString: true })}`;
    window.history.pushState({path:newurl},'',newurl);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSelectedKeyword(q);
      onSubmit();
    }
  };

  const onLoadMore = () => {
      const selectedTag = formatedTags.find((item) => item.label === tag);
    if (results?.length < totalCount) {
      const newPageSize = pageSize + itemPerPage;
      setPageSize(newPageSize);
      refetch({ pageSize: newPageSize, skip: '0', tag: tag === 'All' ? '' : selectedTag?.id.toLowerCase()});
    } else {
      refetch({ pageSize: itemPerPage, skip: '0', tag: tag === 'All' ? '' : selectedTag?.id.toLowerCase()});
    }
  };

  useEffect(() => {
    const startDate = selectedDateStart? selectedDateStart: dateFormate(dateStart)
    const endDate = selectedDateEnd? selectedDateEnd: dateFormate(dateEnd)
    onFilter(selectedTag ? selectedTag : 'All', startDate, endDate, q)
  }, []);

  const onFilter = (label, dateStart, dateEnd, q) => {
    // if (label === tag) {
    //   const all = formatedTags.find((item) => item.label === 'All');
    //   setTag(all?.label);
    //   setValue({ id: '', label: all?.displayName });
    //   refetch({ pageSize: itemPerPage, skip: '0', tag: '', dateFrom: dateStart, dateTo: dateEnd, keyword: q });
    // } else {
      setTag(label);
      const selectedTag = formatedTags.find((item) => item.label === label);
      setValue(selectedTag);
      label === 'All' ? refetch({ pageSize: itemPerPage, skip: '0', tag: '', dateFrom: dateStart, dateTo: dateEnd, keyword: q  }) : refetch({ pageSize: itemPerPage, skip: '0', tag: selectedTag?.id.toLowerCase(), dateFrom: dateStart, dateTo: dateEnd, keyword: q  });
    // }
  };

  const datePickerRef = useRef(null);

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const reset = () => {
    if (datePickerRef.current) {
      datePickerRef.current.clear();
      datePickerRef.current.setOpen(false);
      onFilter(tag ? tag : 'All', '', '', q)
      var newurl = `?${queryString.stringify({ tag: tag, q, page: 1}, { skipNull: true, skipEmptyString: true })}`;
      window.history.pushState({path:newurl},'',newurl);
    }
  };

  return (
    <div className="relative bg-[#F1F0EF] lg:py-60 py-48">
      <div className="container">
        {/* <RichText field={title} className="text-black-300 font-medium text-[26px]" /> */}
        {/* {isPageTitle[0] ? (
              <h1 className="text-black-300 font-medium text-[26px]">{title?.value}</h1>
            ) : (
              <RichText field={title} className="text-black-300 font-medium text-[26px]" />
          )} */}
        {props.sitecoreContext.pageEditing ? (
            <RichText field={title} className="text-black-300 font-medium text-[26px]" />
          ) : isPageTitle[0] ? (
            <h1 className="text-black-300 font-medium text-[26px]">{title?.value}</h1>
          ) : (
            <h2 className="text-black-300 font-medium text-[26px]">{title?.value}</h2>
          )}
        <Image field={props?.fields?.data?.Component?.icon} className=" py-[24px] rounded-2xl" />
        <div className="mt-6 md:flex gap-5 w-[100%]">
          <div className="md:w-[50%] w-[100%] relative md:mb-0 mb-5">
            <input
              type="text"
              placeholder={sitecoreContext?.language === 'ar' ? 'ابحث' : 'Search'}
              value={q}
              onChange={(e) => onSearchChange(e)}
              onKeyPress={handleKeyPress}
              className="h-[64px] w-full px-[40px] py-[18px] text-lg focus:outline-none text-black-300 rounded-md"
            />
            <div className="absolute md:top-[18px] top-[20px] w-fit" onClick={onSubmit} style={sitecoreContext?.language === 'ar' ? { left: '24px' } : { right: '24px' }}>
              <SearchIcon className="w-6 h-6 stroke-black-300 cursor-pointer " />
            </div>
          </div>
          <div className="relative">
            <DatePicker
              id="dateStartEnd"
              selectsRange={true}
              startDate={dateStart}
              endDate={dateEnd}
              onChange={onChangeHandler}
              dateFormat="yyyy-MM-dd"
              className={'form-control form-control-sm h-[64px] w-full px-[60px] py-[18px] text-lg focus:outline-none text-black-300 rounded-md'}
              showDisabledMonthNavigation
              locale={sitecoreContext?.language === 'ar' ? 'ar' : 'enUS'}
              placeholderText={sitecoreContext?.language === 'ar' ? 'من - إلى' : 'From - To'}
              onCalendarOpen={onOpen}
              onCalendarClose={closeAndNavigate}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              ref={datePickerRef}
            >
              <div className="text-center">
                <button type="button" className="my-2 w-[50%] text-center px-4 py-2 rounded-l rounded-r-md leading-none bg-red-500 hover:bg-red-600 duration-300 font-bold active:bg-gray-400 active:text-red-500 text-white" onClick={reset}>
                  <img src={Reset} className="inline mx-[4px]"></img>
                  <span className="mx-[7px]" >{sitecoreContext?.language === 'ar' ? 'إعادة تعيين' : 'Reset'}</span>
                </button>
              </div>
            </DatePicker>
            <div className="absolute md:top-[22px] top-[24px] w-fit" style={sitecoreContext?.language === 'ar' ? { right: '24px' } : { left: '24px' }}>
              <img src={calender} alt="calender" className="w-5 h-5 cursor-pointer" />
            </div>
            { !open &&
              <div className="absolute md:top-[22px] top-[24px] w-fit" style={sitecoreContext?.language === 'ar' ? { left: '24px' } : { right: '24px' }} onClick={handleIconClick}>
                <img src={arrowDownDark} alt="arrowDownDark" className="w-5 h-5 cursor-pointer"/>
              </div>
            }
          { open &&
            <div className="absolute md:top-[22px] top-[24px] w-fit" style={sitecoreContext?.language === 'ar' ? { left: '24px' } : { right: '24px' }}>
              <img src={arrowUpDark} alt="arrowUpDark" className="w-5 h-5 cursor-pointer" />
            </div>
          }
          </div>
        </div>
        <div className="mt-6 md:flex justify-betwen items-center ">
          <div className="w-full flex">
            <div className="py-4 w-full flex items-center gap-5 overflow-auto overflow-x-auto scrollbar-hide">
              {tags?.map((item, index) => (
                <Pill label={item?.display_name?.value} active={item?.name?.toLowerCase() === tag?.toLowerCase()} onClick={() => onTagChange(item?.name)} key={index} />
              ))}
            </div>
          </div>
          {/* {props?.fields?.data?.Component?.showFiltersDropdown?.value === '1' && (
            <div className="md:mt-0 mt-6">
              <Select options={formatedTags} value={value} onChange={onChange} />
            </div>
          )} */}
        </div>
        <div className="grid md:grid-cols-3 grid-cols-1 gap-6 mt-6">
          {results?.map((item, index) => (
             <NewsCardWithImage
                key={index}
                date={item?.item?.date?.value}
                location={item?.item?.location?.value}
                image={item?.item?.featuredImage?.src}
                title={item?.item?.headline}
                desc={item?.item?.teaser}
                link={item?.item?.url}
                readmore
              />
          ))}
        </div>
        {totalCount > 9 && (
          <div className="mt-8 w-fit mx-auto" onClick={onLoadMore}>
            <Button><DictionaryText dictionaryKey={buttonText} /></Button>
          </div>
        )}
      </div>
    </div>
  );
};

// compose() (from @apollo/client/react/hoc) can be used when you need more than one GraphQL query
// for a single query, e.g. `compose(GraphQLData(q1), GraphQLData(q2))(component)`
export default withSitecoreContext()(WithFilter);
