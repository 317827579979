import React from 'react';

const CTA = (props) => {
  const { field, notLink = false } = props;

  return (
    field?.href && (
      <div className="w-full text-base md:font-bold font-medium h-fit main-button clip-button">
        {notLink ? (
          <button className="px-7 py-3.5 rounded-l rounded-r-md leading-none bg-red-500 hover:bg-red-600 duration-300 font-bold active:bg-gray-400 active:text-red-500 text-white">
            {field?.text}
          </button>
        ) : (
          <a href={field?.href}>
            <button className="px-7 py-3.5 rounded-l rounded-r-md leading-none bg-red-500 hover:bg-red-600 duration-300 font-bold active:bg-gray-400 active:text-red-500 text-white">
              {field?.text}
            </button>
          </a>
        )}
      </div>
    )
  );
};

export default CTA;
