
export const getYearMonth = (date) => {
  const newDate = new Date(date);
  const month = newDate.toLocaleString('default', { month: 'short' });
  const year = newDate.getFullYear().toString();

  return month + ' ' + year;
};

export const getYear = (date) => {
  const newDate = new Date(date);
  const year = newDate.getFullYear().toString();

  return year;
};
