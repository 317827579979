import React, { useState } from 'react';
import { RichText, Image , withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import FormatedDate from '../../../../utils/FormatedDate';
import dotDark from '../../../../assets/icons/dot-dark.svg';
import dotLight from '../../../../assets/icons/dot_date.svg';
import { useMediaQuery } from 'react-responsive'

const Default = ({ fields , sitecoreContext }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const components = sitecoreContext?.route?.placeholders['jss-main']
  const isPageTitle = useState(components[0]?.fields?.Headline?.value === fields?.Headline?.value ? true : false);

  return (
    <div>
      {fields?.LargeFeaturedImage?.value?.src ? (
        <div className="relative">
          <div className="absolute w-full h-full top-0 left-0 bg-gradient-to-t from-[#01131d99] to-50%"></div>
          <div
            className="z-0 left-0 w-full h-[800px] object-cover object-top bg-no-repeat bg-center bg-cover"
            style={{
              backgroundImage: `url(${isMobile ? fields?.LargeFeaturedImage?.value?.src : fields?.LargeFeaturedImage?.value?.src})`,
              backgroundPosition: fields?.CSSBackgroundImagePosition?.fields?.Value?.value || 'center'
            }}
          ></div>
          <div className="absolute z-0 w-full bottom-10 text-start">
            <div className="container">
              <div className="max-w-[1280px]">
                <div className="flex items-center gap-2">
                  <img src={dotLight} alt="dot-light" />
                  <div className="text-white text-lg font-medium" dir="ltr">
                    <FormatedDate date={fields?.Date?.value} />
                  </div>
                </div>
                <RichText field={fields.Headline} className="text-3xl leading-[40px] text-white" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container bg-white w-full">
          <div className="pt-[180px] pb-24 max-w-[950px] w-full bottom-10 lg:bottom-20 text-start">
            {fields?.Date?.value && (
              <div className="flex items-center gap-2">
                <img src={dotDark} alt="dot-dark" />
                <div className="text-black-300 text-lg font-medium">
                  <FormatedDate date={fields?.Date?.value} />
                </div>
              </div>
            )}
            {/* {isPageTitle[0] ? (
              <h1 className="mt-3 font-medium text-3xl leading-[40px] text-black-300">{fields?.Headline?.value}</h1>
            ) : (
              <div className="mt-3 font-medium text-3xl leading-[40px] text-black-300">{fields?.Headline?.value}</div>
            )} */}
            {sitecoreContext.pageEditing ? (
             <RichText field={fields?.Headline} className="mt-3 font-medium text-3xl leading-[40px] text-black-300" />
            ) : isPageTitle[0] ? (
              <h1 className="mt-3 font-medium text-3xl leading-[40px] text-black-300">{fields?.Headline?.value}</h1>
            ) : (
              <h2 className="mt-3 font-medium text-3xl leading-[40px] text-black-300">{fields?.Headline?.value}</h2>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default  withSitecoreContext()(Default);
