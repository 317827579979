import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { RichText, Image, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const PrimaryBlockWithDynamicBackground = ({ fields, params }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { sitecoreContext } = useSitecoreContext();
  const formatedQuote = fields?.Title.value;
  const value = {
    value: formatedQuote,
  };

  return (
    <div className={`bg-${params?.BackgroundColor}`}>
      <div className="container">
        <div
          className={
            fields?.ImagePosition?.value === 'Right'
              ? 'lg:py-28 py-16 flex lg:flex-row-reverse flex-col-reverse lg:items-start items-center gap-14 place-content-evenly'
              : 'lg:py-28 py-16 flex lg:flex-row flex-col-reverse lg:items-start items-center gap-14 place-content-evenly'
          }
        >
          <Image field={isMobile && fields.MobileImage?.value?.src ? fields.MobileImage : fields.Image} className="h-[525px] lg:max-w-[50%] object-cover object-center aspect-square" />
          <div className={'grow text-black-300 text-start lg:max-w-[525px] lg:w-[50%]'}>
            {sitecoreContext.pageEditing ? (
              <RichText field={fields?.Name} className="text-[30px] font-bold leading-[28px] my-6 " />
            ) : (
              <h2 className="text-[30px] font-bold leading-[28px] my-6 ">{fields?.Name?.value}</h2>
            )}
            <RichText field={fields?.Description} className="text-base font-medium leading-[28px] mt-6 mb-12" />
            <div className="relative">
              <Image field={fields?.Icon} className="mb-4" />
              <RichText field={fields?.Title} className="text-base font-medium leading-[28px]" />
              <div className="flex justify-end my-4">
                <Image field={fields?.CloseQuoteIcon} className="flex justify-end" />
              </div>
            </div>
            <RichText field={fields?.AdditionalText} className="text-base font-bold leading-[28px] mt-4" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrimaryBlockWithDynamicBackground;
