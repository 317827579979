import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive'
import { RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri';

import ImageCard from './ImageCard';

const ImageCardCarousel = (props) => {
  const { className, data, theme = 'light', children } = props;
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const sliderRef = useRef(null);
  const [activeItem, setActiveItem] = useState(0);
  const [page, setPage] = useState(1);
  const [mobilePage, setMobilePage] = useState(1);

  const getArrowStyle = (theme, disabled) => {
    if (theme === 'light') {
      return disabled ? 'text-darkblue-100' : 'text-black-300';
    } else {
      return disabled ? 'text-sans-100' : 'text-white';
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    touchMove: false,
    autoplay: false,
    slidesToShow: 2.5,
    speed: 250,
    swipeToSlide: false,
    slidesToScroll: 2,
    arrows: false,
    beforeChange: (current, next) => {
      setActiveItem(next);
    },
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          swipeToSlide: true,
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const toNext = () => {
    if (page < Math.ceil(data?.length / 2)) {
      sliderRef && sliderRef.current.slickNext();
      setPage(page + 1);
    }
  };

  const toPrev = () => {
    if (page === 1) return;
    sliderRef && sliderRef.current.slickPrev();
    setPage(page - 1);
  };

  const toMobileNext = () => {
    if (mobilePage < data?.length) {
      sliderRef && sliderRef.current.slickNext();
      setMobilePage(mobilePage + 1);
    }
  };

  const toMobilePrev = () => {
    if (mobilePage === 1) return;
    sliderRef && sliderRef.current.slickPrev();
    setMobilePage(mobilePage - 1);
  };

  return (
    <div className={`${className} relative`}>
      <div className="w-full flex justify-between">
        {children}
        <div className="md:flex hidden items-center gap-4">
          <div className="flex items-center gap-4">
            <RiArrowLeftLine onClick={toPrev} className={`${getArrowStyle(theme, activeItem === 0)} cursor-pointer w-6 h-6`} />
            <RiArrowRightLine onClick={toNext} className={`${getArrowStyle(theme, page >= Math.ceil(data?.length / 2))} cursor-pointer w-6 h-6`} />
          </div>
          <div className={`text-lg ${theme === 'light' ? 'text-darkblue-100' : 'text-sans-100'}`}>{page + '/' + Math.ceil(data?.length / 2)}</div>
        </div>
      </div>
      <div className="mt-10">
        <Slider {...settings} ref={sliderRef}>
          {data?.map((item, index) => (
            <div className="px-3" key={index}>
              <ImageCard image={isMobile ? item.fields.MobileImage?.value.src ? item.fields.MobileImage : item.fields.Image : item.fields.Image} theme={theme} title={item.fields.Title} description={item.fields.Description} />
            </div>
          ))}
        </Slider>
      </div>
      <div className="md:hidden flex mt-5 items-center gap-4">
        <div className="flex items-center gap-4">
          <RiArrowLeftLine onClick={toMobilePrev} className={`${getArrowStyle(theme, activeItem === 0)} cursor-pointer w-6 h-6`} />
          <RiArrowRightLine onClick={toMobileNext} className={`${getArrowStyle(theme, mobilePage >= data?.length)} cursor-pointer w-6 h-6`} />
        </div>
        <div className={`text-lg ${theme === 'light' ? 'text-darkblue-100' : 'text-white'}`}>{mobilePage + '/' + data?.length}</div>
      </div>
    </div>
  );
};

export default ImageCardCarousel;
