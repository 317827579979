import React from 'react';

import Default from './Default';
import Side from './Side';
import WithImages from './WithImages';

const RelatedNews = ({ fields, params }) => {
  const getComponents = (variant) => {
    switch (variant) {
      case 'Default':
        return <Default fields={fields} params={params} />;
      case 'Side':
        return <Side fields={fields} params={params} />;
      case 'WithImages':
        return <WithImages fields={fields} params={params} />;
      default:
        break;
    }
  };

  return getComponents(params?.Variant);
};

export default RelatedNews;
