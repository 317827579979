import React from 'react';

import Default from './Default';
import MediaCarousel from './MediaCarousel';
import TwoColumnMedia from './TwoColumnMedia';
import FullWidthNoThumbnail from './FullWidthNoThumbnail';
import MediaList from './MediaList';
import ThreeColumnMedia from './ThreeColumnMedia';
import MediaGrid from './MediaGrid';
import FullWidthNoThumbnail2 from './FullWidthNoThumbnail2';

const Gallery = ({ fields, params }) => {
  const getComponents = (variant) => {
    switch (variant) {
      case 'Default':
        return <Default fields={fields} params={params} />;
      case 'MediaCarousel':
        return <MediaCarousel fields={fields} params={params} />;
      case '2ColumnMedia':
        return <TwoColumnMedia fields={fields} params={params} />;
      case 'FullWidthNoThumbnail':
        return <FullWidthNoThumbnail fields={fields} params={params} />;
      case 'MediaList':
        return <MediaList fields={fields} params={params} />;
      case '3ColumnMedia':
        return <ThreeColumnMedia fields={fields} params={params} />;
      case 'MediaGrid':
        return <MediaGrid fields={fields} params={params} />;
      case 'FullWidthNoThumbnail2':
        return <FullWidthNoThumbnail2 fields={fields} params={params} />;
      default:
        break;
    }
  };

  return getComponents(params?.Variant);
};

export default Gallery;
