import React from 'react';

import Secondary from './Secondary';
import Primary from './Primary';
import PrimaryBlockWithDynamicBackground from './PrimaryBlockWithDynamicBackground';

const Testimonial = ({ fields, params }) => {
  const getComponents = (variant) => {
    switch (variant) {
      case 'Primary':
        return <Primary fields={fields} params={params} />;
      case 'Secondary':
        return <Secondary fields={fields} params={params} />;
      case 'PrimaryBlockWithDynamicBackground':
        return <PrimaryBlockWithDynamicBackground fields={fields} params={params} />;
      default:
      // return <Default fields={fields} />;
    }
  };

  return getComponents(params?.Variant);
};

export default Testimonial;
